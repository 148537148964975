import { Applier, Match } from "src/app/model";
import { Constants } from "../services/constants.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

function isEmpty(str?: string | null) {
    return !str || str.length === 0;
}

function formatDate(date: Date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero-indexed
    let year = date.getFullYear().toString().substr(-2); // Get last two digits of the year

    var dayString = day < 10 ? "0" + day : day; // Add leading zero if necessary
    var monthString = month < 10 ? "0" + month : month; // Add leading zero if necessary

    return dayString + "." + monthString + "." + year;
}

type MatchProperties = {
    careTypeName?: string;
    positionName?: string;
    shiftName?: string;
    workingTypeName?: string;
    positionIntersection: boolean;
    careTypeIntersection: boolean;
    shiftIntersection: boolean;
    workingTypeIntersection: boolean;
};

export function getShiftHoursNumberFromString(shiftHours: string): number {
    return parseInt(shiftHours.match(/\d+/)![0], 10);
}

function getMatchProperties(
    match: Match,
    applier: Applier,
    constants: Constants
): MatchProperties {
    var careTypeName: string = "";
    var positionName: string = "";
    var shiftName: string = "";
    var workingTypeName: string = "";
    var positionIntersection: boolean = false;
    var careTypeIntersection: boolean = false;
    var shiftIntersection: boolean = false;
    var workingTypeIntersection: boolean = false;
    if (
        match.job.position.length > 0 &&
        match.job.position[0] in constants.positions
    ) {
        var index = match.job.position[0];
        positionName = constants.positions[index];

        positionIntersection =
            applier.positionType.filter((value) =>
                match.job.position.includes(value)
            ).length > 0;
    } else {
        positionName = "";
    }

    // Care Type
    if (
        match.job.careType.length > 0 &&
        match.job.careType[0] in constants.extendedCareType
    ) {
        careTypeName = constants.extendedCareType[match.job.careType[0]].label;
        careTypeIntersection =
            applier.careType.filter((value) =>
                match.job.careType.includes(value)
            ).length > 0;
    } else {
        careTypeName = "";
    }

    // Shift Hours
    if (
        match.job.shift.length > 0 &&
        match.job.shift[0] in constants.shiftHours
    ) {
        const shiftIntersectionList = applier.shiftHour.filter((value) =>
            match.job.shift.includes(value)
        );
        shiftIntersection = shiftIntersectionList.length > 0;

        if (shiftIntersection) {
            var helper = shiftIntersectionList
                .map((val: number) => constants.shiftHours[val])
                .sort(
                    (a, b) =>
                        getShiftHoursNumberFromString(a) -
                        getShiftHoursNumberFromString(b)
                )
                .map((val) => getShiftHoursNumberFromString(val))
                .join(", ");
            shiftName = `${helper} Stunden Dienste`;
        } else {
            var helper = match.job.shift
                .map((val: number) => constants.shiftHours[val])
                .sort(
                    (a, b) =>
                        getShiftHoursNumberFromString(a) -
                        getShiftHoursNumberFromString(b)
                )
                .map((val) => getShiftHoursNumberFromString(val))
                .join(", ");
            shiftName = `${helper} Stunden Dienste`;
        }
    } else {
        shiftName = "";
    }

    // Working Type
    if (
        match.job.workingType.length > 0 &&
        match.job.workingType[0] in constants.workingType
    ) {
        const workingTypeIntersectionList = applier.workingType.filter(
            (value) => match.job.workingType.includes(value)
        );
        workingTypeIntersection = workingTypeIntersectionList.length > 0;

        if (workingTypeIntersection) {
            workingTypeName = workingTypeIntersectionList
                .map((val: number) => constants.workingType[val])
                .join(", ");
        } else {
            workingTypeName = match.job.workingType
                .map((val: number) => constants.workingType[val])
                .join(", ");
        }
    } else {
        workingTypeName = "";
    }

    return {
        careTypeName,
        positionName,
        shiftName,
        workingTypeName,
        positionIntersection,
        careTypeIntersection,
        shiftIntersection,
        workingTypeIntersection,
    };
}

function clamp(
    value: number,
    oldMin: number,
    oldMax: number,
    newMin: number,
    newMax: number
): number {
    var ratio = value / (oldMax - oldMin);
    return newMin + ratio * (newMax - newMin);
}

function roundNumber(value: number, digits: number): number {
    const multiplier = Math.pow(10, digits);
    return Math.round(value * multiplier) / multiplier;
}

function onlyUnique(value: any, index: any, array: any) {
    return array.indexOf(value) === index;
}

enum CompanyBenefitsType {
    Vacation,
    Tarif,
    OvertimeRegulation,
    CompanySize,
    Ownership,
}

class CompanyBenefitOption {
    constructor(public type: CompanyBenefitsType, public importance: number) {}
}

const CompanyBenefitsOptions = {
    Vacation: new CompanyBenefitOption(CompanyBenefitsType.Vacation, 1),
    Tarif: new CompanyBenefitOption(CompanyBenefitsType.Tarif, 3),
    OvertimeRegulation: new CompanyBenefitOption(
        CompanyBenefitsType.OvertimeRegulation,
        2
    ),
    CompanySize: new CompanyBenefitOption(CompanyBenefitsType.CompanySize, 4),
    Ownership: new CompanyBenefitOption(CompanyBenefitsType.Ownership, 5),
};

class CompanyBenefit {
    constructor(public benefit: CompanyBenefitOption, public value: string) {}
}

function getCompanyBenefits(
    match: Match,
    applier: Applier,
    constants: Constants
) {
    var companyBenefits: CompanyBenefit[] = [];

    if (match?.company?.companySize) {
        let companySizeValue: string;

        if (
            match?.company?.companySize &&
            match.company.companySize in constants.companySize
        ) {
            companySizeValue =
                constants.companySize[
                    match.company
                        .companySize as keyof typeof constants.companySize
                ];
        } else {
            companySizeValue = match?.company?.companySize!;
        }
        companyBenefits.push(
            new CompanyBenefit(
                CompanyBenefitsOptions.CompanySize,
                companySizeValue
            )
        );
    }

    // Ownership
    if (match?.company?.ownership) {
        let ownershipValue: string;

        if (
            match?.company?.ownership &&
            match.company.ownership in constants.ownership
        ) {
            ownershipValue =
                constants.ownership[
                    match.company.ownership as keyof typeof constants.ownership
                ];
            companyBenefits.push(
                new CompanyBenefit(
                    CompanyBenefitsOptions.Ownership,
                    ownershipValue
                )
            );
        }
    }

    // Vacation
    if (match?.company?.daysOfVacation) {
        companyBenefits.push(
            new CompanyBenefit(
                CompanyBenefitsOptions.Vacation,
                `${match?.company.daysOfVacation} Tage Urlaub`
            )
        );
    }

    // Sort by importance
    companyBenefits.sort((a, b) => a.benefit.importance - b.benefit.importance);

    return companyBenefits;
}
function delay(milliseconds: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}

function cleanHtml({
    domSanitizer,
    html,
}: {
    domSanitizer: DomSanitizer;
    html: string;
}): SafeHtml {
    html = html.replace(/&#160;/g, " ");
    return domSanitizer.bypassSecurityTrustHtml(html);
}

const frameWidth = 600;

function getAvailableSize(): {
    usesFrame: boolean;
    relevantWidth: number;
    relevantHeight: number;
} {
    const usesFrame = window.innerWidth > frameWidth;

    if (usesFrame) {
        // get the frame element
        const frame = document.querySelector(".frame") as HTMLElement;
        if (!frame) {
            throw new Error("Frame not found");
        }
        // get the frame width and heightq
        const rect = frame.getBoundingClientRect();
        var relevantWidth = rect.width;
        var relevantHeight = rect.height;
    } else {
        var relevantWidth = window.innerWidth;
        var relevantHeight = window.innerHeight;
    }

    return { usesFrame, relevantWidth, relevantHeight };
}

export {
    MatchProperties,
    getMatchProperties,
    formatDate,
    clamp,
    roundNumber,
    onlyUnique,
    CompanyBenefit,
    getCompanyBenefits,
    delay,
    cleanHtml,
    getAvailableSize,
    frameWidth,
};
