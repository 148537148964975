import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ActivatedRoute } from "@angular/router";
import { Applier, Match } from "src/app/model";
import { getDummyApplier } from "src/app/model/applier";
import { JobService } from "src/shared/services/job.service";
import { MatchDetailsComponent } from "../../match/match-details/match-details.component";
import { JobNotfoundComponent } from "../job-notfound/job-notfound.component";
import { Subscription } from "rxjs";

@Component({
    selector: "app-job-preview",
    standalone: true,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatchDetailsComponent,
        JobNotfoundComponent,
    ],
    templateUrl: "./job-preview.component.html",
    styleUrl: "./job-preview.component.scss",
})
export class JobPreviewComponent {
    jobExtId: string | null = null;

    match: Match | null = null;
    applier: Applier | null = null;

    isLoading: boolean = true;

    subscription!: Subscription;

    constructor(private jobService: JobService, private route: ActivatedRoute) {

        this.jobExtId = this.route.snapshot.params["jobExtId"];
        this.jobService.getJob(this.jobExtId);

        this.subscription = this.jobService.matchObject$.subscribe((match) => {
            if (match) {
                this.match = match;
                this.applier = getDummyApplier(match.job.educationType);
            }
            if (match !== undefined) {
                this.isLoading = false;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
