<mat-card class="match-applied-card" matRipple (click)="handleClick()">
    <div class="column" style="gap: 10px">
        <div class="row" style="gap: 12px; align-items: center">
            <div class="company-icon">
                <img
                    style="width: 100%; object-fit: cover"
                    src="{{
                        match.company.logo ??
                            'https://res.cloudinary.com/paulwvnjohi/image/upload/v1679492526/placeholder_sk48uq.png'
                    }}"
                />
            </div>

            <div class="column" style="gap: 4px; flex-grow: 1">
                <p class="company-name">{{ match.company.displayName }}</p>
                <p class="property">{{ positionName }}</p>
                <p class="property">
                    {{ match.job.postcode }} {{ match.job.city }}
                </p>
            </div>
        </div>
        <app-match-applied-card-status [currentStageString]="match.stage" />
    </div>
</mat-card>
