<p>Mögliche Dienstzeiten:</p>
<section class="shift-hours-wrapper">
    <div *ngFor="let shiftHour of shiftHours" class="shift-hour">
        {{ shiftHour }}
    </div>
</section>

<section *ngIf="hasShiftCards" style="margin-top: 16px">
    <mat-divider />
    <div style="display: flex; flex-direction: column; margin-top: 12px">
        <p>Schichtmodell:</p>
        <section class="shift-cards-wrapper">
            <div *ngFor="let shiftCard of shiftCardsArray" class="shift-card">
                <img [src]="shiftCard.value[0].imagePath" class="shift-card-image" />
                <p>
                    {{ shiftCard.value[0].shiftType }}
                </p>
                <div *ngFor="let workingHour of shiftCard.value">
                    <p *ngIf="workingHour.workingHour.startTime && workingHour.workingHour.endTime ">
                        {{ workingHour.workingHour.startTime }} bis
                        {{ workingHour.workingHour.endTime }}
                    </p>
                </div>
            </div>

            <div *ngFor="let shiftCardOther of shiftCardsOther">
                {{ shiftCardOther }}
            </div>
        </section>
    </div>
</section>

<section *ngIf="shiftOther" style="margin-top: 16px">
    <div class="column">
        <p style="font-size: 16px; font-weight: 600 !important">Hinweise:</p>
        <div [innerHTML]="shiftOther"></div>
    </div>
</section>