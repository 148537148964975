<div style="margin-top: 8px">
    @if (needsRegistration) {
    <p-steps
        [model]="steps"
        [(activeIndex)]="activeIndex"
        [readonly]="true"
        styleClass="steps"
        class="stepper"
    />
    } @if (needsRegistration && activeIndex == 0) {
    <app-registration-dialog
        source="application"
        (finishRegistration)="handleUserRegistration($event)"
    />
    } @if ( activeIndex == 1 || !needsRegistration ) {

    <app-match-confirmation-dialog
        [match]="match"
        (closeDialog)="close()"
        [afterRegistration]="needsRegistration"
    />
    }
</div>
