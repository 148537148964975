function e(e, t) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), n.push.apply(n, r);
  }
  return n;
}
function t(t) {
  for (var n = 1; n < arguments.length; n++) {
    var r = null != arguments[n] ? arguments[n] : {};
    n % 2 ? e(Object(r), !0).forEach(function (e) {
      s(t, e, r[e]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : e(Object(r)).forEach(function (e) {
      Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
    });
  }
  return t;
}
function n(e) {
  return n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, n(e);
}
function r(e, t) {
  if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
}
function i(e, t) {
  for (var n = 0; n < t.length; n++) {
    var r = t[n];
    r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
  }
}
function o(e, t, n) {
  return t && i(e.prototype, t), n && i(e, n), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
function s(e, t, n) {
  return t in e ? Object.defineProperty(e, t, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = n, e;
}
function a(e, t) {
  if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
  e.prototype = Object.create(t && t.prototype, {
    constructor: {
      value: e,
      writable: !0,
      configurable: !0
    }
  }), Object.defineProperty(e, "prototype", {
    writable: !1
  }), t && l(e, t);
}
function u(e) {
  return u = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
    return e.__proto__ || Object.getPrototypeOf(e);
  }, u(e);
}
function l(e, t) {
  return l = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
    return e.__proto__ = t, e;
  }, l(e, t);
}
function c(e, t) {
  if (null == e) return {};
  var n,
    r,
    i = function (e, t) {
      if (null == e) return {};
      var n,
        r,
        i = {},
        o = Object.keys(e);
      for (r = 0; r < o.length; r++) n = o[r], t.indexOf(n) >= 0 || (i[n] = e[n]);
      return i;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    for (r = 0; r < o.length; r++) n = o[r], t.indexOf(n) >= 0 || Object.prototype.propertyIsEnumerable.call(e, n) && (i[n] = e[n]);
  }
  return i;
}
function d(e, t) {
  if (t && ("object" == typeof t || "function" == typeof t)) return t;
  if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
  return function (e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }(e);
}
function h(e) {
  var t = function () {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var n,
      r = u(e);
    if (t) {
      var i = u(this).constructor;
      n = Reflect.construct(r, arguments, i);
    } else n = r.apply(this, arguments);
    return d(this, n);
  };
}
function f(e, t) {
  return function (e) {
    if (Array.isArray(e)) return e;
  }(e) || function (e, t) {
    var n = null == e ? null : "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
    if (null == n) return;
    var r,
      i,
      o = [],
      s = !0,
      a = !1;
    try {
      for (n = n.call(e); !(s = (r = n.next()).done) && (o.push(r.value), !t || o.length !== t); s = !0);
    } catch (e) {
      a = !0, i = e;
    } finally {
      try {
        s || null == n.return || n.return();
      } finally {
        if (a) throw i;
      }
    }
    return o;
  }(e, t) || _(e, t) || function () {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }();
}
function p(e) {
  return function (e) {
    if (Array.isArray(e)) return g(e);
  }(e) || function (e) {
    if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
  }(e) || _(e) || function () {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }();
}
function _(e, t) {
  if (e) {
    if ("string" == typeof e) return g(e, t);
    var n = Object.prototype.toString.call(e).slice(8, -1);
    return "Object" === n && e.constructor && (n = e.constructor.name), "Map" === n || "Set" === n ? Array.from(e) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? g(e, t) : void 0;
  }
}
function g(e, t) {
  (null == t || t > e.length) && (t = e.length);
  for (var n = 0, r = new Array(t); n < t; n++) r[n] = e[n];
  return r;
}
function v(e, t) {
  var n = "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
  if (!n) {
    if (Array.isArray(e) || (n = _(e)) || t && e && "number" == typeof e.length) {
      n && (e = n);
      var r = 0,
        i = function () {};
      return {
        s: i,
        n: function () {
          return r >= e.length ? {
            done: !0
          } : {
            done: !1,
            value: e[r++]
          };
        },
        e: function (e) {
          throw e;
        },
        f: i
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var o,
    s = !0,
    a = !1;
  return {
    s: function () {
      n = n.call(e);
    },
    n: function () {
      var e = n.next();
      return s = e.done, e;
    },
    e: function (e) {
      a = !0, o = e;
    },
    f: function () {
      try {
        s || null == n.return || n.return();
      } finally {
        if (a) throw o;
      }
    }
  };
}
var m = {
    DEBUG: !1,
    LIB_VERSION: "1.106.2"
  },
  y = Array.isArray,
  b = Object.prototype,
  k = b.hasOwnProperty,
  w = b.toString,
  S = y || function (e) {
    return "[object Array]" === w.call(e);
  },
  F = function (e) {
    return "[object Uint8Array]" === w.call(e);
  },
  P = function (e) {
    return "function" == typeof e;
  },
  R = function (e) {
    return e === Object(e) && !S(e);
  },
  I = function (e) {
    return void 0 === e;
  },
  E = function (e) {
    return "[object String]" == w.call(e);
  },
  x = function (e) {
    return null === e;
  },
  C = function (e) {
    return "[object Number]" == w.call(e);
  },
  O = function (e) {
    return "[object Boolean]" === w.call(e);
  },
  T = function (e) {
    return e instanceof FormData;
  },
  $ = "undefined" != typeof window ? window : void 0,
  A = "undefined" != typeof globalThis ? globalThis : $,
  M = Array.prototype,
  q = M.forEach,
  D = M.indexOf,
  L = null == A ? void 0 : A.navigator,
  B = null == A ? void 0 : A.document,
  N = null == A ? void 0 : A.location,
  j = null == A ? void 0 : A.fetch,
  H = null == A ? void 0 : A.XMLHttpRequest,
  U = null == L ? void 0 : L.userAgent,
  W = null != $ ? $ : {},
  V = "[PostHog.js]",
  z = {
    _log: function (e) {
      if ($ && (m.DEBUG || W.POSTHOG_DEBUG) && !I($.console) && $.console) {
        for (var t = ("__rrweb_original__" in $.console[e]) ? $.console[e].__rrweb_original__ : $.console[e], n = arguments.length, r = new Array(n > 1 ? n - 1 : 0), i = 1; i < n; i++) r[i - 1] = arguments[i];
        t.apply(void 0, [V].concat(r));
      }
    },
    info: function () {
      for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
      z._log.apply(z, ["log"].concat(t));
    },
    warn: function () {
      for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
      z._log.apply(z, ["warn"].concat(t));
    },
    error: function () {
      for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
      z._log.apply(z, ["error"].concat(t));
    },
    critical: function () {
      for (var e, t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
      (e = console).error.apply(e, [V].concat(n));
    },
    uninitializedWarning: function (e) {
      z.error("You must initialize PostHog before calling ".concat(e));
    }
  },
  G = {},
  Q = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  };
function Y(e, t, n) {
  if (S(e)) if (q && e.forEach === q) e.forEach(t, n);else if ("length" in e && e.length === +e.length) for (var r = 0, i = e.length; r < i; r++) if (r in e && t.call(n, e[r], r) === G) return;
}
function J(e, t, n) {
  if (!x(e) && !I(e)) {
    if (S(e)) return Y(e, t, n);
    if (T(e)) {
      var r,
        i = v(e.entries());
      try {
        for (i.s(); !(r = i.n()).done;) {
          var o = r.value;
          if (t.call(n, o[1], o[0]) === G) return;
        }
      } catch (e) {
        i.e(e);
      } finally {
        i.f();
      }
    } else for (var s in e) if (k.call(e, s) && t.call(n, e[s], s) === G) return;
  }
}
var K = function (e) {
  for (var t = arguments.length, n = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) n[r - 1] = arguments[r];
  return Y(n, function (t) {
    for (var n in t) void 0 !== t[n] && (e[n] = t[n]);
  }), e;
};
function X(e, t) {
  return -1 !== e.indexOf(t);
}
function Z(e) {
  for (var t = Object.keys(e), n = t.length, r = new Array(n); n--;) r[n] = [t[n], e[t[n]]];
  return r;
}
var ee = function () {
    return Date.now = Date.now || function () {
      return +new Date();
    }, Date.now();
  },
  te = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  ne = function (e) {
    return function () {
      try {
        for (var t = arguments.length, n = new Array(t), r = 0; r < t; r++) n[r] = arguments[r];
        return e.apply(this, n);
      } catch (e) {
        z.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), z.critical(e);
      }
    };
  },
  re = function (e) {
    var t = {};
    return J(e, function (e, n) {
      E(e) && e.length > 0 && (t[n] = e);
    }), t;
  };
function ie(e, t) {
  return n = e, r = function (e) {
    return E(e) && !x(t) ? e.slice(0, t) : e;
  }, i = new Set(), function e(t, n) {
    return t !== Object(t) ? r ? r(t, n) : t : i.has(t) ? void 0 : (i.add(t), S(t) ? (o = [], Y(t, function (t) {
      o.push(e(t));
    })) : (o = {}, J(t, function (t, n) {
      i.has(t) || (o[n] = e(t, n));
    })), o);
    var o;
  }(n);
  var n, r, i;
}
function oe(e) {
  var t,
    n,
    r,
    i,
    o,
    s = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    a = 0,
    u = 0,
    l = "",
    c = [];
  if (!e) return e;
  e = se(e);
  do {
    t = (o = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, n = o >> 12 & 63, r = o >> 6 & 63, i = 63 & o, c[u++] = s.charAt(t) + s.charAt(n) + s.charAt(r) + s.charAt(i);
  } while (a < e.length);
  switch (l = c.join(""), e.length % 3) {
    case 1:
      l = l.slice(0, -2) + "==";
      break;
    case 2:
      l = l.slice(0, -1) + "=";
  }
  return l;
}
var se = function (e) {
    var t,
      n,
      r,
      i,
      o = "";
    for (t = n = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, i = 0; i < r; i++) {
      var s = e.charCodeAt(i),
        a = null;
      s < 128 ? n++ : a = s > 127 && s < 2048 ? String.fromCharCode(s >> 6 | 192, 63 & s | 128) : String.fromCharCode(s >> 12 | 224, s >> 6 & 63 | 128, 63 & s | 128), x(a) || (n > t && (o += e.substring(t, n)), o += a, t = n = i + 1);
    }
    return n > t && (o += e.substring(t, e.length)), o;
  },
  ae = function () {
    function e(t) {
      return t && (t.preventDefault = e.preventDefault, t.stopPropagation = e.stopPropagation), t;
    }
    return e.preventDefault = function () {
      this.returnValue = !1;
    }, e.stopPropagation = function () {
      this.cancelBubble = !0;
    }, function (t, n, r, i, o) {
      if (t) {
        if (t.addEventListener && !i) t.addEventListener(n, r, !!o);else {
          var s = "on" + n,
            a = t[s];
          t[s] = function (t, n, r) {
            return function (i) {
              if (i = i || e(null == $ ? void 0 : $.event)) {
                var o,
                  s = !0;
                P(r) && (o = r(i));
                var a = n.call(t, i);
                return !1 !== o && !1 !== a || (s = !1), s;
              }
            };
          }(t, r, a);
        }
      } else z.error("No valid element provided to register_event");
    };
  }();
function ue(e, t) {
  var n = function () {
    if (!B) return t("document not found");
    var n = B.createElement("script");
    n.type = "text/javascript", n.src = e, n.onload = function (e) {
      return t(void 0, e);
    }, n.onerror = function (e) {
      return t(e);
    };
    var r,
      i = B.querySelectorAll("body > script");
    i.length > 0 ? null === (r = i[0].parentNode) || void 0 === r || r.insertBefore(n, i[0]) : B.body.appendChild(n);
  };
  null != B && B.body ? n() : null == B || B.addEventListener("DOMContentLoaded", n);
}
function le(e) {
  return e ? Q(e).split(/\s+/) : [];
}
function ce(e) {
  var t = "";
  switch (n(e.className)) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = ("baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return le(t);
}
function de(e) {
  var t = "";
  return ye(e) && !be(e) && e.childNodes && e.childNodes.length && J(e.childNodes, function (e) {
    pe(e) && e.textContent && (t += Q(e.textContent).split(/(\s+)/).filter(ke).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255));
  }), Q(t);
}
function he(e) {
  return !!e && 1 === e.nodeType;
}
function fe(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function pe(e) {
  return !!e && 3 === e.nodeType;
}
function _e(e) {
  return !!e && 11 === e.nodeType;
}
var ge = ["a", "button", "form", "input", "select", "textarea", "label"];
function ve(e) {
  var t = e.parentNode;
  return !(!t || !he(t)) && t;
}
function me(e, t) {
  var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
  if (!$ || !e || fe(e, "html") || !he(e)) return !1;
  if (null != r && r.url_allowlist) {
    var i = $.location.href,
      o = r.url_allowlist;
    if (o && !o.some(function (e) {
      return i.match(e);
    })) return !1;
  }
  if (null != r && r.dom_event_allowlist) {
    var s = r.dom_event_allowlist;
    if (s && !s.some(function (e) {
      return t.type === e;
    })) return !1;
  }
  for (var a = !1, u = [e], l = !0, c = e; c.parentNode && !fe(c, "body");) if (_e(c.parentNode)) u.push(c.parentNode.host), c = c.parentNode.host;else {
    if (!(l = ve(c))) break;
    if (ge.indexOf(l.tagName.toLowerCase()) > -1) a = !0;else {
      var d = $.getComputedStyle(l);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    u.push(l), c = l;
  }
  if (!function (e, t) {
    var r = null == t ? void 0 : t.element_allowlist;
    if (I(r)) return !0;
    var i,
      o = v(e);
    try {
      var s = function () {
        var e = i.value;
        if (r.some(function (t) {
          return e.tagName.toLowerCase() === t;
        })) return {
          v: !0
        };
      };
      for (o.s(); !(i = o.n()).done;) {
        var a = s();
        if ("object" === n(a)) return a.v;
      }
    } catch (e) {
      o.e(e);
    } finally {
      o.f();
    }
    return !1;
  }(u, r)) return !1;
  if (!function (e, t) {
    var r = null == t ? void 0 : t.css_selector_allowlist;
    if (I(r)) return !0;
    var i,
      o = v(e);
    try {
      var s = function () {
        var e = i.value;
        if (r.some(function (t) {
          return e.matches(t);
        })) return {
          v: !0
        };
      };
      for (o.s(); !(i = o.n()).done;) {
        var a = s();
        if ("object" === n(a)) return a.v;
      }
    } catch (e) {
      o.e(e);
    } finally {
      o.f();
    }
    return !1;
  }(u, r)) return !1;
  var h = $.getComputedStyle(e);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === t.type) return !0;
  var f = e.tagName.toLowerCase();
  switch (f) {
    case "html":
      return !1;
    case "form":
      return "submit" === t.type;
    case "input":
    case "select":
    case "textarea":
      return "change" === t.type || "click" === t.type;
    default:
      return a ? "click" === t.type : "click" === t.type && (ge.indexOf(f) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function ye(e) {
  for (var t = e; t.parentNode && !fe(t, "body"); t = t.parentNode) {
    var n = ce(t);
    if (X(n, "ph-sensitive") || X(n, "ph-no-capture")) return !1;
  }
  if (X(ce(e), "ph-include")) return !0;
  var r = e.type || "";
  if (E(r)) switch (r.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var i = e.name || e.id || "";
  if (E(i)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(i.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function be(e) {
  return !!(fe(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || fe(e, "select") || fe(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
function ke(e) {
  if (x(e) || I(e)) return !1;
  if (E(e)) {
    e = Q(e);
    if (/^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/.test((e || "").replace(/[- ]/g, ""))) return !1;
    if (/(^\d{3}-?\d{2}-?\d{4}$)/.test(e)) return !1;
  }
  return !0;
}
function we(e) {
  var t = de(e);
  return ke(t = "".concat(t, " ").concat(Se(e)).trim()) ? t : "";
}
function Se(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && J(e.childNodes, function (e) {
    var n;
    if (e && "span" === (null === (n = e.tagName) || void 0 === n ? void 0 : n.toLowerCase())) try {
      var r = de(e);
      t = "".concat(t, " ").concat(r).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(Se(e)).trim());
    } catch (e) {
      z.error(e);
    }
  }), t;
}
function Fe(e) {
  return function (e) {
    var n = e.map(function (e) {
      var n,
        r,
        i = "";
      if (e.tag_name && (i += e.tag_name), e.attr_class) {
        e.attr_class.sort();
        var o,
          s = v(e.attr_class);
        try {
          for (s.s(); !(o = s.n()).done;) {
            var a = o.value;
            i += ".".concat(a.replace(/"/g, ""));
          }
        } catch (e) {
          s.e(e);
        } finally {
          s.f();
        }
      }
      var u = t(t(t(t({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (n = e.nth_child) && void 0 !== n ? n : 0,
          "nth-of-type": null !== (r = e.nth_of_type) && void 0 !== r ? r : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        l = {};
      return Z(u).sort(function (e, t) {
        var n = f(e, 1)[0],
          r = f(t, 1)[0];
        return n.localeCompare(r);
      }).forEach(function (e) {
        var t = f(e, 2),
          n = t[0],
          r = t[1];
        return l[Pe(n.toString())] = Pe(r.toString());
      }), i += ":", i += Z(u).map(function (e) {
        var t = f(e, 2),
          n = t[0],
          r = t[1];
        return "".concat(n, '="').concat(r, '"');
      }).join("");
    });
    return n.join(";");
  }(function (e) {
    return e.map(function (e) {
      var t,
        n,
        r = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (n = e.attr__href) || void 0 === n ? void 0 : n.slice(0, 2048),
          attr_class: Re(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return Z(e).filter(function (e) {
        return 0 === f(e, 1)[0].indexOf("attr__");
      }).forEach(function (e) {
        var t = f(e, 2),
          n = t[0],
          i = t[1];
        return r.attributes[n] = i;
      }), r;
    });
  }(e));
}
function Pe(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function Re(e) {
  var t = e.attr__class;
  return t ? S(t) ? t : le(t) : void 0;
}
var Ie = function () {
    function e(t) {
      r(this, e), this.clicks = [], this.enabled = t;
    }
    return o(e, [{
      key: "isRageClick",
      value: function (e, t, n) {
        if (!this.enabled) return !1;
        var r = this.clicks[this.clicks.length - 1];
        if (r && Math.abs(e - r.x) + Math.abs(t - r.y) < 30 && n - r.timestamp < 1e3) {
          if (this.clicks.push({
            x: e,
            y: t,
            timestamp: n
          }), 3 === this.clicks.length) return !0;
        } else this.clicks = [{
          x: e,
          y: t,
          timestamp: n
        }];
        return !1;
      }
    }]), e;
  }(),
  Ee = "$people_distinct_id",
  xe = "__alias",
  Ce = "__timers",
  Oe = "$autocapture_disabled_server_side",
  Te = "$session_recording_enabled_server_side",
  $e = "$console_log_recording_enabled_server_side",
  Ae = "$session_recording_recorder_version_server_side",
  Me = "$session_recording_network_payload_capture",
  qe = "$session_recording_canvas_recording",
  De = "$sesid",
  Le = "$session_is_sampled",
  Be = "$enabled_feature_flags",
  Ne = "$early_access_features",
  je = "$stored_person_properties",
  He = "$stored_group_properties",
  Ue = "$surveys",
  We = "$flag_call_reported",
  Ve = "$user_state",
  ze = "$posthog_quota_limited",
  Ge = "$client_session_props",
  Qe = [Ee, xe, "__cmpns", Ce, Te, De, Be, Ve, ze, Ne, He, je, Ue, We, Ge];
function Ye(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
var Je = {
  _initializedTokens: [],
  _isDisabledServerSide: null,
  _isAutocaptureEnabled: !1,
  _setIsAutocaptureEnabled: function (e) {
    var t,
      n = x(this._isDisabledServerSide) ? !(null === (t = e.persistence) || void 0 === t || !t.props[Oe]) : this._isDisabledServerSide,
      r = !!e.config.autocapture;
    this._isAutocaptureEnabled = r && !n;
  },
  _previousElementSibling: function (e) {
    if (e.previousElementSibling) return e.previousElementSibling;
    var t = e;
    do {
      t = t.previousSibling;
    } while (t && !he(t));
    return t;
  },
  _getAugmentPropertiesFromElement: function (e) {
    if (!ye(e)) return {};
    var t = {};
    return J(e.attributes, function (e) {
      if (0 === e.name.indexOf("data-ph-capture-attribute")) {
        var n = e.name.replace("data-ph-capture-attribute-", ""),
          r = e.value;
        n && r && ke(r) && (t[n] = r);
      }
    }), t;
  },
  _getPropertiesFromElement: function (e, t, n) {
    var r,
      i = e.tagName.toLowerCase(),
      o = {
        tag_name: i
      };
    ge.indexOf(i) > -1 && !n && ("a" === i.toLowerCase() || "button" === i.toLowerCase() ? o.$el_text = Ye(1024, we(e)) : o.$el_text = Ye(1024, de(e)));
    var s = ce(e);
    s.length > 0 && (o.classes = s.filter(function (e) {
      return "" !== e;
    }));
    var a = null === (r = this.config) || void 0 === r ? void 0 : r.element_attribute_ignorelist;
    J(e.attributes, function (n) {
      var r;
      if ((!be(e) || -1 !== ["name", "id", "class"].indexOf(n.name)) && (null == a || !a.includes(n.name)) && !t && ke(n.value) && (r = n.name, !E(r) || "_ngcontent" !== r.substring(0, 10) && "_nghost" !== r.substring(0, 7))) {
        var i = n.value;
        "class" === n.name && (i = le(i).join(" ")), o["attr__" + n.name] = Ye(1024, i);
      }
    });
    for (var u = 1, l = 1, c = e; c = this._previousElementSibling(c);) u++, c.tagName === e.tagName && l++;
    return o.nth_child = u, o.nth_of_type = l, o;
  },
  _getDefaultProperties: function (e) {
    return {
      $event_type: e,
      $ce_version: 1
    };
  },
  _extractCustomPropertyValue: function (e) {
    var t = [];
    return J(null == B ? void 0 : B.querySelectorAll(e.css_selector), function (e) {
      var n;
      ["input", "select"].indexOf(e.tagName.toLowerCase()) > -1 ? n = e.value : e.textContent && (n = e.textContent), ke(n) && t.push(n);
    }), t.join(", ");
  },
  _getCustomProperties: function (e) {
    var t = this,
      n = {};
    return J(this._customProperties, function (r) {
      J(r.event_selectors, function (i) {
        J(null == B ? void 0 : B.querySelectorAll(i), function (i) {
          X(e, i) && ye(i) && (n[r.name] = t._extractCustomPropertyValue(r));
        });
      });
    }), n;
  },
  _getEventTarget: function (e) {
    return I(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
    var t;
  },
  _captureEvent: function (e, t) {
    var n,
      r = this,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "$autocapture",
      o = this._getEventTarget(e);
    (pe(o) && (o = o.parentNode || null), "$autocapture" === i && "click" === e.type && e instanceof MouseEvent) && null !== (n = this.rageclicks) && void 0 !== n && n.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, t, "$rageclick");
    if (o && me(o, e, this.config)) {
      for (var s, a, u = [o], l = o; l.parentNode && !fe(l, "body");) _e(l.parentNode) ? (u.push(l.parentNode.host), l = l.parentNode.host) : (u.push(l.parentNode), l = l.parentNode);
      var c,
        d = [],
        h = {},
        f = !1;
      if (J(u, function (e) {
        var n = ye(e);
        "a" === e.tagName.toLowerCase() && (c = e.getAttribute("href"), c = n && ke(c) && c), X(ce(e), "ph-no-capture") && (f = !0), d.push(r._getPropertiesFromElement(e, t.config.mask_all_element_attributes, t.config.mask_all_text));
        var i = r._getAugmentPropertiesFromElement(e);
        K(h, i);
      }), t.config.mask_all_text || ("a" === o.tagName.toLowerCase() || "button" === o.tagName.toLowerCase() ? d[0].$el_text = we(o) : d[0].$el_text = de(o)), c && (d[0].attr__href = c), f) return !1;
      var p = K(this._getDefaultProperties(e.type), t.elementsChainAsString ? {
        $elements_chain: Fe(d)
      } : {
        $elements: d
      }, null !== (s = d[0]) && void 0 !== s && s.$el_text ? {
        $el_text: null === (a = d[0]) || void 0 === a ? void 0 : a.$el_text
      } : {}, this._getCustomProperties(u), h);
      return t.capture(i, p), !0;
    }
  },
  _navigate: function (e) {
    $ && ($.location.href = e);
  },
  _addDomEventHandlers: function (e) {
    var t = this;
    if ($ && B) {
      var n = function (n) {
        n = n || (null == $ ? void 0 : $.event), t._captureEvent(n, e);
      };
      ae(B, "submit", n, !1, !0), ae(B, "change", n, !1, !0), ae(B, "click", n, !1, !0);
    }
  },
  _customProperties: [],
  rageclicks: null,
  config: void 0,
  init: function (e) {
    var t;
    O(e.__autocapture) || (this.config = e.__autocapture), null !== (t = this.config) && void 0 !== t && t.url_allowlist && (this.config.url_allowlist = this.config.url_allowlist.map(function (e) {
      return new RegExp(e);
    })), this.rageclicks = new Ie(e.config.rageclick);
  },
  afterDecideResponse: function (e, t) {
    var n = t.config.token;
    this._initializedTokens.indexOf(n) > -1 ? z.info('autocapture already initialized for token "' + n + '"') : (t.persistence && t.persistence.register(s({}, Oe, !!e.autocapture_opt_out)), this._isDisabledServerSide = !!e.autocapture_opt_out, this._setIsAutocaptureEnabled(t), this._initializedTokens.push(n), e && e.config && e.config.enable_collect_everything && this._isAutocaptureEnabled ? (e.custom_properties && (this._customProperties = e.custom_properties), this._addDomEventHandlers(t)) : t.__autocapture = !1);
  },
  enabledForProject: function (e, t, n) {
    if (!e) return !0;
    t = I(t) ? 10 : t, n = I(n) ? 10 : n;
    for (var r = 0, i = 0; i < e.length; i++) r += e.charCodeAt(i);
    return r % t < n;
  },
  isBrowserSupported: function () {
    return P(null == B ? void 0 : B.querySelectorAll);
  }
};
!function (e) {
  for (var t in e) P(e[t]) && (e[t] = e[t].bind(e));
}(Je), function (e) {
  for (var t in e) P(e[t]) && (e[t] = ne(e[t]));
}(Je);
var Ke = "$active_feature_flags",
  Xe = "$override_feature_flags",
  Ze = "$feature_flag_payloads",
  et = function (e) {
    var t,
      n = {},
      r = v(Z(e || {}));
    try {
      for (r.s(); !(t = r.n()).done;) {
        var i = f(t.value, 2),
          o = i[0],
          s = i[1];
        s && (n[o] = s);
      }
    } catch (e) {
      r.e(e);
    } finally {
      r.f();
    }
    return n;
  },
  tt = function () {
    function e(t) {
      r(this, e), this.instance = t, this._override_warning = !1, this.featureFlagEventHandlers = [], this.reloadFeatureFlagsQueued = !1, this.reloadFeatureFlagsInAction = !1;
    }
    return o(e, [{
      key: "getFlags",
      value: function () {
        return Object.keys(this.getFlagVariants());
      }
    }, {
      key: "getFlagVariants",
      value: function () {
        var e = this.instance.get_property(Be),
          t = this.instance.get_property(Xe);
        if (!t) return e || {};
        for (var n = K({}, e), r = Object.keys(t), i = 0; i < r.length; i++) !1 === t[r[i]] ? delete n[r[i]] : n[r[i]] = t[r[i]];
        return this._override_warning || (z.warn(" Overriding feature flags!", {
          enabledFlags: e,
          overriddenFlags: t,
          finalFlags: n
        }), this._override_warning = !0), n;
      }
    }, {
      key: "getFlagPayloads",
      value: function () {
        return this.instance.get_property(Ze) || {};
      }
    }, {
      key: "reloadFeatureFlags",
      value: function () {
        this.reloadFeatureFlagsQueued || (this.reloadFeatureFlagsQueued = !0, this._startReloadTimer());
      }
    }, {
      key: "setAnonymousDistinctId",
      value: function (e) {
        this.$anon_distinct_id = e;
      }
    }, {
      key: "setReloadingPaused",
      value: function (e) {
        this.reloadFeatureFlagsInAction = e;
      }
    }, {
      key: "resetRequestQueue",
      value: function () {
        this.reloadFeatureFlagsQueued = !1;
      }
    }, {
      key: "_startReloadTimer",
      value: function () {
        var e = this;
        this.reloadFeatureFlagsQueued && !this.reloadFeatureFlagsInAction && setTimeout(function () {
          !e.reloadFeatureFlagsInAction && e.reloadFeatureFlagsQueued && (e.reloadFeatureFlagsQueued = !1, e._reloadFeatureFlagsRequest());
        }, 5);
      }
    }, {
      key: "_reloadFeatureFlagsRequest",
      value: function () {
        var e = this;
        if (!this.instance.config.advanced_disable_feature_flags) {
          this.setReloadingPaused(!0);
          var t = this.instance.config.token,
            n = this.instance.get_property(je),
            r = this.instance.get_property(He),
            i = oe(JSON.stringify({
              token: t,
              distinct_id: this.instance.get_distinct_id(),
              groups: this.instance.getGroups(),
              $anon_distinct_id: this.$anon_distinct_id,
              person_properties: n,
              group_properties: r,
              disable_flags: this.instance.config.advanced_disable_feature_flags || void 0
            }));
          this.instance._send_request(this.instance.requestRouter.endpointFor("api", "/decide/?v=3"), {
            data: i
          }, {
            method: "POST"
          }, this.instance._prepare_callback(function (t) {
            e.$anon_distinct_id = void 0, e.receivedFeatureFlags(t), e.setReloadingPaused(!1), e._startReloadTimer();
          }));
        }
      }
    }, {
      key: "getFeatureFlag",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) {
          var n,
            r = this.getFlagVariants()[e],
            i = "".concat(r),
            o = this.instance.get_property(We) || {};
          if (t.send_event || !("send_event" in t)) if (!(e in o) || !o[e].includes(i)) S(o[e]) ? o[e].push(i) : o[e] = [i], null === (n = this.instance.persistence) || void 0 === n || n.register(s({}, We, o)), this.instance.capture("$feature_flag_called", {
            $feature_flag: e,
            $feature_flag_response: r
          });
          return r;
        }
        z.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
      }
    }, {
      key: "getFeatureFlagPayload",
      value: function (e) {
        return this.getFlagPayloads()[e];
      }
    }, {
      key: "isFeatureEnabled",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
        z.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
      }
    }, {
      key: "addFeatureFlagsHandler",
      value: function (e) {
        this.featureFlagEventHandlers.push(e);
      }
    }, {
      key: "removeFeatureFlagsHandler",
      value: function (e) {
        this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(function (t) {
          return t !== e;
        });
      }
    }, {
      key: "receivedFeatureFlags",
      value: function (e) {
        if (this.instance.persistence) {
          this.instance.decideEndpointWasHit = !0;
          var n = this.getFlagVariants(),
            r = this.getFlagPayloads();
          !function (e, n) {
            var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
              i = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
              o = e.featureFlags,
              a = e.featureFlagPayloads;
            if (o) if (S(o)) {
              var u,
                l = {};
              if (o) for (var c = 0; c < o.length; c++) l[o[c]] = !0;
              n && n.register((s(u = {}, Ke, o), s(u, Be, l), u));
            } else {
              var d,
                h = o,
                f = a;
              e.errorsWhileComputingFlags && (h = t(t({}, r), h), f = t(t({}, i), f)), n && n.register((s(d = {}, Ke, Object.keys(et(h))), s(d, Be, h || {}), s(d, Ze, f || {}), d));
            }
          }(e, this.instance.persistence, n, r), this._fireFeatureFlagsCallbacks();
        }
      }
    }, {
      key: "override",
      value: function (e) {
        if (!this.instance.__loaded || !this.instance.persistence) return z.uninitializedWarning("posthog.feature_flags.override");
        if (this._override_warning = !1, !1 === e) this.instance.persistence.unregister(Xe);else if (S(e)) {
          for (var t = {}, n = 0; n < e.length; n++) t[e[n]] = !0;
          this.instance.persistence.register(s({}, Xe, t));
        } else this.instance.persistence.register(s({}, Xe, e));
      }
    }, {
      key: "onFeatureFlags",
      value: function (e) {
        var t = this;
        if (this.addFeatureFlagsHandler(e), this.instance.decideEndpointWasHit) {
          var n = this._prepareFeatureFlagsForCallbacks(),
            r = n.flags,
            i = n.flagVariants;
          e(r, i);
        }
        return function () {
          return t.removeFeatureFlagsHandler(e);
        };
      }
    }, {
      key: "updateEarlyAccessFeatureEnrollment",
      value: function (e, n) {
        var r,
          i,
          o = s({}, "$feature_enrollment/".concat(e), n);
        this.instance.capture("$feature_enrollment_update", {
          $feature_flag: e,
          $feature_enrollment: n,
          $set: o
        }), this.setPersonPropertiesForFlags(o, !1);
        var a = t(t({}, this.getFlagVariants()), {}, s({}, e, n));
        null === (r = this.instance.persistence) || void 0 === r || r.register((s(i = {}, Ke, Object.keys(et(a))), s(i, Be, a), i)), this._fireFeatureFlagsCallbacks();
      }
    }, {
      key: "getEarlyAccessFeatures",
      value: function (e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
          r = this.instance.get_property(Ne);
        if (r && !n) return e(r);
        this.instance._send_request(this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token)), {}, {
          method: "GET"
        }, function (n) {
          var r,
            i = n.earlyAccessFeatures;
          return null === (r = t.instance.persistence) || void 0 === r || r.register(s({}, Ne, i)), e(i);
        });
      }
    }, {
      key: "_prepareFeatureFlagsForCallbacks",
      value: function () {
        var e = this.getFlags(),
          t = this.getFlagVariants();
        return {
          flags: e.filter(function (e) {
            return t[e];
          }),
          flagVariants: Object.keys(t).filter(function (e) {
            return t[e];
          }).reduce(function (e, n) {
            return e[n] = t[n], e;
          }, {})
        };
      }
    }, {
      key: "_fireFeatureFlagsCallbacks",
      value: function () {
        var e = this._prepareFeatureFlagsForCallbacks(),
          t = e.flags,
          n = e.flagVariants;
        this.featureFlagEventHandlers.forEach(function (e) {
          return e(t, n);
        });
      }
    }, {
      key: "setPersonPropertiesForFlags",
      value: function (e) {
        var n = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
          r = this.instance.get_property(je) || {};
        this.instance.register(s({}, je, t(t({}, r), e))), n && this.instance.reloadFeatureFlags();
      }
    }, {
      key: "resetPersonPropertiesForFlags",
      value: function () {
        this.instance.unregister(je);
      }
    }, {
      key: "setGroupPropertiesForFlags",
      value: function (e) {
        var n = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
          r = this.instance.get_property(He) || {};
        0 !== Object.keys(r).length && Object.keys(r).forEach(function (n) {
          r[n] = t(t({}, r[n]), e[n]), delete e[n];
        }), this.instance.register(s({}, He, t(t({}, r), e))), n && this.instance.reloadFeatureFlags();
      }
    }, {
      key: "resetGroupPropertiesForFlags",
      value: function (e) {
        if (e) {
          var n = this.instance.get_property(He) || {};
          this.instance.register(s({}, He, t(t({}, n), {}, s({}, e, {}))));
        } else this.instance.unregister(He);
      }
    }]), e;
  }();
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return C(e) && isFinite(e) && Math.floor(e) === e;
});
var nt = "0123456789abcdef",
  rt = function () {
    function e(t) {
      if (r(this, e), this.bytes = t, 16 !== t.length) throw new TypeError("not 128-bit length");
    }
    return o(e, [{
      key: "toString",
      value: function () {
        for (var e = "", t = 0; t < this.bytes.length; t++) e = e + nt.charAt(this.bytes[t] >>> 4) + nt.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
        if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
        return e;
      }
    }, {
      key: "clone",
      value: function () {
        return new e(this.bytes.slice(0));
      }
    }, {
      key: "equals",
      value: function (e) {
        return 0 === this.compareTo(e);
      }
    }, {
      key: "compareTo",
      value: function (e) {
        for (var t = 0; t < 16; t++) {
          var n = this.bytes[t] - e.bytes[t];
          if (0 !== n) return Math.sign(n);
        }
        return 0;
      }
    }], [{
      key: "fromFieldsV7",
      value: function (t, n, r, i) {
        if (!Number.isInteger(t) || !Number.isInteger(n) || !Number.isInteger(r) || !Number.isInteger(i) || t < 0 || n < 0 || r < 0 || i < 0 || t > 0xffffffffffff || n > 4095 || r > 1073741823 || i > 4294967295) throw new RangeError("invalid field value");
        var o = new Uint8Array(16);
        return o[0] = t / Math.pow(2, 40), o[1] = t / Math.pow(2, 32), o[2] = t / Math.pow(2, 24), o[3] = t / Math.pow(2, 16), o[4] = t / Math.pow(2, 8), o[5] = t, o[6] = 112 | n >>> 8, o[7] = n, o[8] = 128 | r >>> 24, o[9] = r >>> 16, o[10] = r >>> 8, o[11] = r, o[12] = i >>> 24, o[13] = i >>> 16, o[14] = i >>> 8, o[15] = i, new e(o);
      }
    }]), e;
  }(),
  it = function () {
    function e() {
      r(this, e), s(this, "timestamp", 0), s(this, "counter", 0), s(this, "random", new at());
    }
    return o(e, [{
      key: "generate",
      value: function () {
        var e = this.generateOrAbort();
        if (I(e)) {
          this.timestamp = 0;
          var t = this.generateOrAbort();
          if (I(t)) throw new Error("Could not generate UUID after timestamp reset");
          return t;
        }
        return e;
      }
    }, {
      key: "generateOrAbort",
      value: function () {
        var e = Date.now();
        if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
          if (!(e + 1e4 > this.timestamp)) return;
          this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
        }
        return rt.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
      }
    }, {
      key: "resetCounter",
      value: function () {
        this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
      }
    }]), e;
  }(),
  ot = function (e) {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
$ && !I($.crypto) && crypto.getRandomValues && (ot = function (e) {
  return crypto.getRandomValues(e);
});
var st,
  at = function () {
    function e() {
      r(this, e), s(this, "buffer", new Uint32Array(8)), s(this, "cursor", 1 / 0);
    }
    return o(e, [{
      key: "nextUint32",
      value: function () {
        return this.cursor >= this.buffer.length && (ot(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
      }
    }]), e;
  }(),
  ut = function () {
    return lt().toString();
  },
  lt = function () {
    return (st || (st = new it())).generate();
  },
  ct = "Thu, 01 Jan 1970 00:00:00 GMT",
  dt = "";
var ht = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function ft(e, t) {
  if (t) {
    var n = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : B;
      if (dt) return dt;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var n = e.split("."), r = Math.min(n.length, 8), i = "dmn_chk_" + ut(), o = new RegExp("(^|;)\\s*" + i + "=1"); !dt && r--;) {
        var s = n.slice(r).join("."),
          a = i + "=1;domain=." + s;
        t.cookie = a, o.test(t.cookie) && (t.cookie = a + ";expires=" + ct, dt = s);
      }
      return dt;
    }(e);
    if (!n) {
      var r = function (e) {
        var t = e.match(ht);
        return t ? t[0] : "";
      }(e);
      r !== n && z.info("Warning: cookie subdomain discovery mismatch", r, n), n = r;
    }
    return n ? "; domain=." + n : "";
  }
  return "";
}
var pt = {
    is_supported: function () {
      return !!B;
    },
    error: function (e) {
      z.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (B) {
        try {
          for (var t = e + "=", n = B.cookie.split(";").filter(function (e) {
              return e.length;
            }), r = 0; r < n.length; r++) {
            for (var i = n[r]; " " == i.charAt(0);) i = i.substring(1, i.length);
            if (0 === i.indexOf(t)) return decodeURIComponent(i.substring(t.length, i.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(pt.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, n, r, i) {
      if (B) try {
        var o = "",
          s = "",
          a = ft(B.location.hostname, r);
        if (n) {
          var u = new Date();
          u.setTime(u.getTime() + 24 * n * 60 * 60 * 1e3), o = "; expires=" + u.toUTCString();
        }
        i && (s = "; secure");
        var l = e + "=" + encodeURIComponent(JSON.stringify(t)) + o + "; SameSite=Lax; path=/" + a + s;
        return l.length > 3686.4 && z.warn("cookieStore warning: large cookie, len=" + l.length), B.cookie = l, l;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        pt.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  _t = null,
  gt = {
    is_supported: function () {
      if (!x(_t)) return _t;
      var e = !0;
      if (I($)) e = !1;else try {
        var t = "__mplssupport__";
        gt.set(t, "xyz"), '"xyz"' !== gt.get(t) && (e = !1), gt.remove(t);
      } catch (t) {
        e = !1;
      }
      return e || z.error("localStorage unsupported; falling back to cookie store"), _t = e, e;
    },
    error: function (e) {
      z.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == $ ? void 0 : $.localStorage.getItem(e);
      } catch (e) {
        gt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(gt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, t) {
      try {
        null == $ || $.localStorage.setItem(e, JSON.stringify(t));
      } catch (e) {
        gt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == $ || $.localStorage.removeItem(e);
      } catch (e) {
        gt.error(e);
      }
    }
  },
  vt = ["distinct_id", De, Le],
  mt = t(t({}, gt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = pt.parse(e) || {};
        } catch (e) {}
        var n = K(t, JSON.parse(gt.get(e) || "{}"));
        return gt.set(e, n), n;
      } catch (e) {}
      return null;
    },
    set: function (e, t, n, r, i) {
      try {
        gt.set(e, t);
        var o = {};
        vt.forEach(function (e) {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && pt.set(e, o, n, r, i);
      } catch (e) {
        gt.error(e);
      }
    },
    remove: function (e, t) {
      try {
        null == $ || $.localStorage.removeItem(e), pt.remove(e, t);
      } catch (e) {
        gt.error(e);
      }
    }
  }),
  yt = {},
  bt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      z.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return yt[e] || null;
    },
    parse: function (e) {
      return yt[e] || null;
    },
    set: function (e, t) {
      yt[e] = t;
    },
    remove: function (e) {
      delete yt[e];
    }
  },
  kt = null,
  wt = {
    is_supported: function () {
      if (!x(kt)) return kt;
      if (kt = !0, I($)) kt = !1;else try {
        var e = "__support__";
        wt.set(e, "xyz"), '"xyz"' !== wt.get(e) && (kt = !1), wt.remove(e);
      } catch (e) {
        kt = !1;
      }
      return kt;
    },
    error: function (e) {
      z.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == $ ? void 0 : $.sessionStorage.getItem(e);
      } catch (e) {
        wt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(wt.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, t) {
      try {
        null == $ || $.sessionStorage.setItem(e, JSON.stringify(t));
      } catch (e) {
        wt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == $ || $.sessionStorage.removeItem(e);
      } catch (e) {
        wt.error(e);
      }
    }
  },
  St = ["localhost", "127.0.0.1"],
  Ft = !(!H || !("withCredentials" in new H())) || !!j,
  Pt = function (e) {
    var t = null == B ? void 0 : B.createElement("a");
    return I(t) ? null : (t.href = e, t);
  },
  Rt = function (e, t) {
    return !!function (e) {
      try {
        new RegExp(e);
      } catch (e) {
        return !1;
      }
      return !0;
    }(t) && new RegExp(t).test(e);
  },
  It = function (e) {
    var t,
      n,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      i = [];
    return J(e, function (e, r) {
      I(e) || I(r) || "undefined" === r || (t = encodeURIComponent(function (e) {
        return e instanceof File;
      }(e) ? e.name : e.toString()), n = encodeURIComponent(r), i[i.length] = n + "=" + t);
    }), i.join(r);
  },
  Et = function (e, t) {
    for (var n, r = ((e.split("#")[0] || "").split("?")[1] || "").split("&"), i = 0; i < r.length; i++) {
      var o = r[i].split("=");
      if (o[0] === t) {
        n = o;
        break;
      }
    }
    if (!S(n) || n.length < 2) return "";
    var s = n[1];
    try {
      s = decodeURIComponent(s);
    } catch (e) {
      z.error("Skipping decoding for malformed query param: " + s);
    }
    return s.replace(/\+/g, " ");
  },
  xt = function (e, t) {
    var n = e.match(new RegExp(t + "=([^&]*)"));
    return n ? n[1] : null;
  };
var Ct = {
    campaignParams: function (e) {
      var t = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gclid", "gad_source", "gbraid", "wbraid", "fbclid", "msclkid"].concat(e || []),
        n = {};
      return J(t, function (e) {
        var t = B ? Et(B.URL, e) : "";
        t.length && (n[e] = t);
      }), n;
    },
    searchEngine: function () {
      var e = null == B ? void 0 : B.referrer;
      return e ? 0 === e.search("https?://(.*)google.([^/?]*)") ? "google" : 0 === e.search("https?://(.*)bing.com") ? "bing" : 0 === e.search("https?://(.*)yahoo.com") ? "yahoo" : 0 === e.search("https?://(.*)duckduckgo.com") ? "duckduckgo" : null : null;
    },
    searchInfo: function () {
      var e = Ct.searchEngine(),
        t = "yahoo" != e ? "q" : "p",
        n = {};
      if (!x(e)) {
        n.$search_engine = e;
        var r = B ? Et(B.referrer, t) : "";
        r.length && (n.ph_keyword = r);
      }
      return n;
    },
    browser: function (e, t, n) {
      return t = t || "", n || X(e, " OPR/") ? X(e, "Mini") ? "Opera Mini" : "Opera" : /(BlackBerry|PlayBook|BB10)/i.test(e) ? "BlackBerry" : X(e, "IEMobile") || X(e, "WPDesktop") ? "Internet Explorer Mobile" : X(e, "SamsungBrowser/") ? "Samsung Internet" : X(e, "Edge") || X(e, "Edg/") ? "Microsoft Edge" : X(e, "FBIOS") ? "Facebook Mobile" : X(e, "Chrome") ? "Chrome" : X(e, "CriOS") ? "Chrome iOS" : X(e, "UCWEB") || X(e, "UCBrowser") ? "UC Browser" : X(e, "FxiOS") ? "Firefox iOS" : X(t, "Apple") || function (e) {
        return X(e, "Safari") && !X(e, "Chrome") && !X(e, "Android");
      }(e) ? X(e, "Mobile") ? "Mobile Safari" : "Safari" : X(e, "Android") ? "Android Mobile" : X(e, "Konqueror") || X(e, "konqueror") ? "Konqueror" : X(e, "Firefox") ? "Firefox" : X(e, "MSIE") || X(e, "Trident/") ? "Internet Explorer" : X(e, "Gecko") ? "Mozilla" : "";
    },
    browserVersion: function (e, t, n) {
      var r = {
        "Internet Explorer Mobile": [/rv:(\d+(\.\d+)?)/],
        "Microsoft Edge": [/Edge?\/(\d+(\.\d+)?)/],
        Chrome: [/Chrome\/(\d+(\.\d+)?)/],
        "Chrome iOS": [/CriOS\/(\d+(\.\d+)?)/],
        "UC Browser": [/(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/],
        Safari: [/Version\/(\d+(\.\d+)?)/],
        "Mobile Safari": [/Version\/(\d+(\.\d+)?)/],
        Opera: [/(Opera|OPR)\/(\d+(\.\d+)?)/],
        Firefox: [/Firefox\/(\d+(\.\d+)?)/],
        "Firefox iOS": [/FxiOS\/(\d+(\.\d+)?)/],
        Konqueror: [/Konqueror[:/]?(\d+(\.\d+)?)/i],
        BlackBerry: [/BlackBerry (\d+(\.\d+)?)/, /Version\/(\d+(\.\d+)?)/],
        "Android Mobile": [/android\s(\d+(\.\d+)?)/],
        "Samsung Internet": [/SamsungBrowser\/(\d+(\.\d+)?)/],
        "Internet Explorer": [/(rv:|MSIE )(\d+(\.\d+)?)/],
        Mozilla: [/rv:(\d+(\.\d+)?)/]
      }[Ct.browser(e, t, n)];
      if (I(r)) return null;
      for (var i = 0; i < r.length; i++) {
        var o = r[i],
          s = e.match(o);
        if (s) return parseFloat(s[s.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    os: function (e) {
      if (/Windows/i.test(e)) {
        if (/Phone/.test(e) || /WPDesktop/.test(e)) return {
          os_name: "Windows Phone",
          os_version: ""
        };
        var t = /Windows NT ([0-9.]+)/i.exec(e);
        return t && t[1] ? {
          os_name: "Windows",
          os_version: t[1]
        } : {
          os_name: "Windows",
          os_version: ""
        };
      }
      if (/(iPhone|iPad|iPod)/.test(e)) {
        var n = /OS (\d+)_(\d+)_?(\d+)?/i.exec(e);
        return n && n[1] ? {
          os_name: "iOS",
          os_version: [n[1], n[2], n[3] || "0"].join(".")
        } : {
          os_name: "iOS",
          os_version: ""
        };
      }
      if (/Android/.test(e)) {
        var r = /Android (\d+)\.(\d+)\.?(\d+)?/i.exec(e);
        return r && r[1] ? {
          os_name: "Android",
          os_version: [r[1], r[2], r[3] || "0"].join(".")
        } : {
          os_name: "Android",
          os_version: ""
        };
      }
      if (/(BlackBerry|PlayBook|BB10)/i.test(e)) return {
        os_name: "BlackBerry",
        os_version: ""
      };
      if (/Mac/i.test(e)) {
        var i = /Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i.exec(e);
        return i && i[1] ? {
          os_name: "Mac OS X",
          os_version: [i[1], i[2], i[3] || "0"].join(".")
        } : {
          os_name: "Mac OS X",
          os_version: ""
        };
      }
      return /Linux/.test(e) ? {
        os_name: "Linux",
        os_version: ""
      } : /CrOS/.test(e) ? {
        os_name: "Chrome OS",
        os_version: ""
      } : {
        os_name: "",
        os_version: ""
      };
    },
    device: function (e) {
      return /Windows Phone/i.test(e) || /WPDesktop/.test(e) ? "Windows Phone" : /iPad/.test(e) ? "iPad" : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(BlackBerry|PlayBook|BB10)/i.test(e) ? "BlackBerry" : /Android/.test(e) && !/Mobile/.test(e) ? "Android Tablet" : /Android/.test(e) ? "Android" : "";
    },
    deviceType: function (e) {
      var t = this.device(e);
      return "iPad" === t || "Android Tablet" === t ? "Tablet" : t ? "Mobile" : "Desktop";
    },
    referrer: function () {
      return (null == B ? void 0 : B.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != B && B.referrer && (null === (e = Pt(B.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    properties: function () {
      if (!U) return {};
      var e = Ct.os(U),
        t = e.os_name,
        n = e.os_version;
      return K(re({
        $os: t,
        $os_version: n,
        $browser: Ct.browser(U, navigator.vendor, W.opera),
        $device: Ct.device(U),
        $device_type: Ct.deviceType(U)
      }), {
        $current_url: null == N ? void 0 : N.href,
        $host: null == N ? void 0 : N.host,
        $pathname: null == N ? void 0 : N.pathname,
        $raw_user_agent: U.length > 1e3 ? U.substring(0, 997) + "..." : U,
        $browser_version: Ct.browserVersion(U, navigator.vendor, W.opera),
        $browser_language: Ct.browserLanguage(),
        $screen_height: null == $ ? void 0 : $.screen.height,
        $screen_width: null == $ ? void 0 : $.screen.width,
        $viewport_height: null == $ ? void 0 : $.innerHeight,
        $viewport_width: null == $ ? void 0 : $.innerWidth,
        $lib: "web",
        $lib_version: m.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: ee() / 1e3
      });
    },
    people_properties: function () {
      if (!U) return {};
      var e = Ct.os(U),
        t = e.os_name,
        n = e.os_version;
      return K(re({
        $os: t,
        $os_version: n,
        $browser: Ct.browser(U, navigator.vendor, W.opera)
      }), {
        $browser_version: Ct.browserVersion(U, navigator.vendor, W.opera)
      });
    }
  },
  Ot = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"],
  Tt = function () {
    function e(t) {
      r(this, e);
      var n = "";
      t.token && (n = t.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), this.props = {}, this.campaign_params_saved = !1, this.custom_campaign_params = t.custom_campaign_params || [], t.persistence_name ? this.name = "ph_" + t.persistence_name : this.name = "ph_" + n + "_posthog", -1 === Ot.indexOf(t.persistence.toLowerCase()) && (z.critical("Unknown persistence type " + t.persistence + "; falling back to localStorage+cookie"), t.persistence = "localStorage+cookie");
      var i = t.persistence.toLowerCase();
      "localstorage" === i && gt.is_supported() ? this.storage = gt : "localstorage+cookie" === i && mt.is_supported() ? this.storage = mt : "sessionstorage" === i && wt.is_supported() ? this.storage = wt : "memory" === i ? this.storage = bt : "cookie" === i ? this.storage = pt : mt.is_supported() ? this.storage = mt : this.storage = pt, this.load(), this.update_config(t), this.save();
    }
    return o(e, [{
      key: "properties",
      value: function () {
        var e = {};
        return J(this.props, function (t, n) {
          if (n === Be && R(t)) for (var r = Object.keys(t), i = 0; i < r.length; i++) e["$feature/".concat(r[i])] = t[r[i]];else s = n, a = !1, (x(o = Qe) ? a : D && o.indexOf === D ? -1 != o.indexOf(s) : (J(o, function (e) {
            if (a || (a = e === s)) return G;
          }), a)) || (e[n] = t);
          var o, s, a;
        }), e;
      }
    }, {
      key: "load",
      value: function () {
        if (!this.disabled) {
          var e = this.storage.parse(this.name);
          e && (this.props = K({}, e));
        }
      }
    }, {
      key: "save",
      value: function () {
        this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure);
      }
    }, {
      key: "remove",
      value: function () {
        this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
      }
    }, {
      key: "clear",
      value: function () {
        this.remove(), this.props = {};
      }
    }, {
      key: "register_once",
      value: function (e, t, n) {
        var r = this;
        if (R(e)) {
          I(t) && (t = "None"), this.expire_days = I(n) ? this.default_expiry : n;
          var i = !1;
          if (J(e, function (e, n) {
            r.props.hasOwnProperty(n) && r.props[n] !== t || (r.props[n] = e, i = !0);
          }), i) return this.save(), !0;
        }
        return !1;
      }
    }, {
      key: "register",
      value: function (e, t) {
        var n = this;
        if (R(e)) {
          this.expire_days = I(t) ? this.default_expiry : t;
          var r = !1;
          if (J(e, function (t, i) {
            e.hasOwnProperty(i) && n.props[i] !== t && (n.props[i] = t, r = !0);
          }), r) return this.save(), !0;
        }
        return !1;
      }
    }, {
      key: "unregister",
      value: function (e) {
        e in this.props && (delete this.props[e], this.save());
      }
    }, {
      key: "update_campaign_params",
      value: function () {
        this.campaign_params_saved || (this.register(Ct.campaignParams(this.custom_campaign_params)), this.campaign_params_saved = !0);
      }
    }, {
      key: "update_search_keyword",
      value: function () {
        this.register(Ct.searchInfo());
      }
    }, {
      key: "update_referrer_info",
      value: function () {
        this.register({
          $referrer: this.props.$referrer || Ct.referrer(),
          $referring_domain: this.props.$referring_domain || Ct.referringDomain()
        });
      }
    }, {
      key: "get_referrer_info",
      value: function () {
        return re({
          $referrer: this.props.$referrer,
          $referring_domain: this.props.$referring_domain
        });
      }
    }, {
      key: "safe_merge",
      value: function (e) {
        return J(this.props, function (t, n) {
          n in e || (e[n] = t);
        }), e;
      }
    }, {
      key: "update_config",
      value: function (e) {
        this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie);
      }
    }, {
      key: "set_disabled",
      value: function (e) {
        this.disabled = e, this.disabled ? this.remove() : this.save();
      }
    }, {
      key: "set_cross_subdomain",
      value: function (e) {
        e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
      }
    }, {
      key: "get_cross_subdomain",
      value: function () {
        return !!this.cross_subdomain;
      }
    }, {
      key: "set_secure",
      value: function (e) {
        e !== this.secure && (this.secure = e, this.remove(), this.save());
      }
    }, {
      key: "set_event_timer",
      value: function (e, t) {
        var n = this.props[Ce] || {};
        n[e] = t, this.props[Ce] = n, this.save();
      }
    }, {
      key: "remove_event_timer",
      value: function (e) {
        var t = (this.props[Ce] || {})[e];
        return I(t) || (delete this.props[Ce][e], this.save()), t;
      }
    }, {
      key: "get_user_state",
      value: function () {
        return this.props[Ve] || "anonymous";
      }
    }, {
      key: "set_user_state",
      value: function (e) {
        this.props[Ve] = e, this.save();
      }
    }, {
      key: "get_quota_limits",
      value: function () {
        return this.props[ze] || {};
      }
    }, {
      key: "set_quota_limits",
      value: function (e) {
        this.props[ze] = e, this.save();
      }
    }]), e;
  }(),
  $t = 2,
  At = 4;
var Mt = o(function e(t) {
    var n,
      i,
      o = this,
      a = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    r(this, e), s(this, "bucketSize", 100), s(this, "refillRate", 10), s(this, "mutationBuckets", {}), s(this, "loggedTracker", {}), s(this, "refillBuckets", function () {
      Object.keys(o.mutationBuckets).forEach(function (e) {
        o.mutationBuckets[e] = o.mutationBuckets[e] + o.refillRate, o.mutationBuckets[e] >= o.bucketSize && delete o.mutationBuckets[e];
      });
    }), s(this, "getNodeOrRelevantParent", function (e) {
      var t = o.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var n = t.closest("svg");
        if (n) return [o.rrweb.mirror.getId(n), n];
      }
      return [e, t];
    }), s(this, "numberOfChanges", function (e) {
      var t, n, r, i, o, s, a, u;
      return (null !== (t = null === (n = e.removes) || void 0 === n ? void 0 : n.length) && void 0 !== t ? t : 0) + (null !== (r = null === (i = e.attributes) || void 0 === i ? void 0 : i.length) && void 0 !== r ? r : 0) + (null !== (o = null === (s = e.texts) || void 0 === s ? void 0 : s.length) && void 0 !== o ? o : 0) + (null !== (a = null === (u = e.adds) || void 0 === u ? void 0 : u.length) && void 0 !== a ? a : 0);
    }), s(this, "throttleMutations", function (e) {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        n = o.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(function (e) {
        var t,
          n,
          r,
          i = f(o.getNodeOrRelevantParent(e.id), 2),
          s = i[0],
          a = i[1];
        if (0 === o.mutationBuckets[s]) return !1;
        (o.mutationBuckets[s] = null !== (t = o.mutationBuckets[s]) && void 0 !== t ? t : o.bucketSize, o.mutationBuckets[s] = Math.max(o.mutationBuckets[s] - 1, 0), 0 === o.mutationBuckets[s]) && (o.loggedTracker[s] || (o.loggedTracker[s] = !0, null === (n = (r = o.options).onBlockedNode) || void 0 === n || n.call(r, s, a)));
        return e;
      }));
      var r = o.numberOfChanges(t);
      return 0 !== r || n === r ? e : void 0;
    }), this.rrweb = t, this.options = a, this.refillRate = null !== (n = this.options.refillRate) && void 0 !== n ? n : this.refillRate, this.bucketSize = null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, setInterval(function () {
      o.refillBuckets();
    }, 1e3);
  }),
  qt = function (e) {
    return e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e;
  }(qt || {}),
  Dt = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: function (e) {
      return e;
    },
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6
  },
  Lt = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  Bt = ["/s/", "/e/", "/i/vo/e/"];
function Nt(e, t, n, r) {
  if (I(e) || x(e)) return e;
  var i = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return E(i) && (i = parseInt(i)), i > n ? "[SessionReplay] ".concat(r, " body too large to record (").concat(i, " bytes)") : e;
}
var jt = function (e, n) {
    var r,
      i,
      o,
      s = e.session_recording,
      a = !1 !== s.recordHeaders && n.recordHeaders,
      u = !1 !== s.recordBody && n.recordBody,
      l = !1 !== s.recordPerformance && n.recordPerformance,
      c = (r = s, o = Math.min(1e6, null !== (i = r.payloadSizeLimitBytes) && void 0 !== i ? i : 1e6), function (e) {
        return null != e && e.requestBody && (e.requestBody = Nt(e.requestBody, e.requestHeaders, o, "Request")), null != e && e.responseBody && (e.responseBody = Nt(e.responseBody, e.responseHeaders, o, "Response")), e;
      }),
      d = function (e) {
        return c(function (e) {
          var t = Pt(e.name);
          if (!(t && t.pathname && Bt.includes(t.pathname))) return e;
        }((t = e, Object.keys(null !== (n = t.requestHeaders) && void 0 !== n ? n : {}).forEach(function (e) {
          var n;
          Lt.includes(e.toLowerCase()) && (null === (n = t.requestHeaders) || void 0 === n || delete n[e]);
        }), t)));
        var t, n;
      },
      h = P(e.session_recording.maskNetworkRequestFn);
    return h && P(e.session_recording.maskCapturedNetworkRequestFn) && z.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), h && (e.session_recording.maskCapturedNetworkRequestFn = function (n) {
      var r = e.session_recording.maskNetworkRequestFn({
        url: n.name
      });
      return t(t({}, n), {}, {
        name: null == r ? void 0 : r.url
      });
    }), s.maskRequestFn = P(e.session_recording.maskCapturedNetworkRequestFn) ? function (t) {
      var n,
        r,
        i,
        o = d(t);
      return o && null !== (n = null === (r = (i = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === r ? void 0 : r.call(i, o)) && void 0 !== n ? n : void 0;
    } : void 0, s.maskRequestFn || (s.maskRequestFn = d), t(t(t({}, Dt), s), {}, {
      recordHeaders: a,
      recordBody: u,
      recordPerformance: l,
      recordInitialRequests: l
    });
  },
  Ht = "__ph_opt_in_out_";
function Ut(e, t) {
  Kt(!0, e, t);
}
function Wt(e, t) {
  Kt(!1, e, t);
}
function Vt(e, t) {
  return "1" === Jt(e, t);
}
function zt(e, t) {
  return !!function (e) {
    if (e && e.respectDnt) {
      var t = e && e.window || $,
        n = null == t ? void 0 : t.navigator,
        r = !1;
      return J([null == n ? void 0 : n.doNotTrack, n.msDoNotTrack, t.doNotTrack], function (e) {
        X([!0, 1, "1", "yes"], e) && (r = !0);
      }), r;
    }
    return !1;
  }(t) || "0" === Jt(e, t);
}
function Gt(e, t) {
  Qt(t = t || {}).remove(Yt(e, t), !!t.crossSubdomainCookie);
}
function Qt(e) {
  return "localStorage" === (e = e || {}).persistenceType ? gt : "localStorage+cookie" === e.persistenceType ? mt : pt;
}
function Yt(e, t) {
  return ((t = t || {}).persistencePrefix || Ht) + e;
}
function Jt(e, t) {
  return Qt(t).get(Yt(e, t));
}
function Kt(e, t, n) {
  E(t) && t.length ? (Qt(n = n || {}).set(Yt(t, n), e ? 1 : 0, C(n.cookieExpiration) ? n.cookieExpiration : null, n.crossSubdomainCookie, n.secureCookie), n.capture && e && n.capture(n.captureEventName || "$opt_in", n.captureProperties || {}, {
    send_instantly: !0
  })) : z.error("gdpr." + (e ? "optIn" : "optOut") + " called with an invalid token");
}
function Xt(e) {
  var t = !1;
  try {
    var n = e.config.token,
      r = e.config.respect_dnt,
      i = e.config.opt_out_capturing_persistence_type,
      o = e.config.opt_out_capturing_cookie_prefix || void 0,
      s = e.config.window;
    n && (t = zt(n, {
      respectDnt: r,
      persistenceType: i,
      persistencePrefix: o,
      window: s
    }));
  } catch (e) {
    z.error("Unexpected error when checking capturing opt-out status: " + e);
  }
  return t;
}
var Zt,
  en = 3e5,
  tn = en;
!function (e) {
  e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet";
}(Zt || (Zt = {}));
var nn = [Zt.MouseMove, Zt.MouseInteraction, Zt.Scroll, Zt.ViewportResize, Zt.Input, Zt.TouchMove, Zt.MediaInteraction, Zt.Drag],
  rn = function (e) {
    return {
      rrwebMethod: e,
      enqueuedAt: Date.now(),
      attempt: 1
    };
  },
  on = function () {
    function e(t) {
      var n = this;
      if (r(this, e), s(this, "queuedRRWebEvents", []), s(this, "isIdle", !1), s(this, "_linkedFlagSeen", !1), s(this, "_lastActivityTimestamp", Date.now()), s(this, "windowId", null), s(this, "sessionId", null), s(this, "_linkedFlag", null), s(this, "_sampleRate", null), s(this, "_minimumDuration", null), s(this, "_forceAllowLocalhostNetworkCapture", !1), this.instance = t, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, null == $ || $.addEventListener("beforeunload", function () {
        n._flushBuffer();
      }), null == $ || $.addEventListener("offline", function () {
        n._tryAddCustomEvent("browser offline", {});
      }), null == $ || $.addEventListener("online", function () {
        n._tryAddCustomEvent("browser online", {});
      }), !this.instance.sessionManager) throw z.error("Session recording started without valid sessionManager"), new Error("Session recording started without valid sessionManager. This is a bug.");
      this.buffer = this.clearBuffer();
    }
    return o(e, [{
      key: "started",
      get: function () {
        return this._captureStarted;
      }
    }, {
      key: "sessionManager",
      get: function () {
        if (!this.instance.sessionManager) throw z.error("Session recording started without valid sessionManager"), new Error("Session recording started without valid sessionManager. This is a bug.");
        return this.instance.sessionManager;
      }
    }, {
      key: "isSampled",
      get: function () {
        return C(this._sampleRate) ? this.instance.get_property(Le) : null;
      }
    }, {
      key: "sessionDuration",
      get: function () {
        var e,
          t,
          n = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
          r = this.sessionManager.checkAndGetSessionAndWindowId(!0).sessionStartTimestamp;
        return n ? n.timestamp - r : null;
      }
    }, {
      key: "isRecordingEnabled",
      get: function () {
        var e = !!this.instance.get_property(Te),
          t = !this.instance.config.disable_session_recording;
        return $ && e && t;
      }
    }, {
      key: "isConsoleLogCaptureEnabled",
      get: function () {
        var e = !!this.instance.get_property($e),
          t = this.instance.config.enable_recording_console_log;
        return null != t ? t : e;
      }
    }, {
      key: "canvasRecording",
      get: function () {
        var e = this.instance.get_property(qe);
        return e && e.fps && e.quality ? {
          enabled: e.enabled,
          fps: e.fps,
          quality: e.quality
        } : void 0;
      }
    }, {
      key: "recordingVersion",
      get: function () {
        var e,
          t = this.instance.get_property(Ae);
        return (null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recorderVersion) || t || "v1";
      }
    }, {
      key: "networkPayloadCapture",
      get: function () {
        var e,
          t,
          n = this.instance.get_property(Me),
          r = {
            recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
            recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
          },
          i = (null == r ? void 0 : r.recordHeaders) || (null == n ? void 0 : n.recordHeaders),
          o = (null == r ? void 0 : r.recordBody) || (null == n ? void 0 : n.recordBody),
          s = this.instance.config.capture_performance || (null == n ? void 0 : n.capturePerformance);
        return i || o || s ? {
          recordHeaders: i,
          recordBody: o,
          recordPerformance: s
        } : void 0;
      }
    }, {
      key: "status",
      get: function () {
        return this.receivedDecide ? this.isRecordingEnabled ? E(this._linkedFlag) && !this._linkedFlagSeen ? "buffering" : O(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "disabled" : "buffering";
      }
    }, {
      key: "startRecordingIfEnabled",
      value: function () {
        this.isRecordingEnabled ? (this.startCaptureAndTrySendingQueuedSnapshots(), z.info("[SessionRecording] started")) : (this.stopRecording(), this.clearBuffer());
      }
    }, {
      key: "stopRecording",
      value: function () {
        this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, z.info("[SessionRecording] stopped"));
      }
    }, {
      key: "makeSamplingDecision",
      value: function (e) {
        var t,
          n = this.sessionId !== e;
        if (C(this._sampleRate)) {
          var r,
            i = this.isSampled;
          if (n || !O(i)) r = Math.random() < this._sampleRate;else r = i;
          r || z.warn("[SessionSampling] Sample rate (".concat(this._sampleRate, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), null === (t = this.instance.persistence) || void 0 === t || t.register(s({}, Le, r));
        } else {
          var o;
          null === (o = this.instance.persistence) || void 0 === o || o.register(s({}, Le, null));
        }
      }
    }, {
      key: "afterDecideResponse",
      value: function (e) {
        var n,
          r,
          i,
          o,
          a,
          u,
          l,
          c,
          d,
          h,
          f,
          p = this;
        this.instance.persistence && this.instance.persistence.register((s(f = {}, Te, !!e.sessionRecording), s(f, $e, null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.consoleLogRecordingEnabled), s(f, Ae, null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.recorderVersion), s(f, Me, t({
          capturePerformance: e.capturePerformance
        }, null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.networkPayloadCapture)), s(f, qe, {
          enabled: null === (c = e.sessionRecording) || void 0 === c ? void 0 : c.recordCanvas,
          fps: null === (d = e.sessionRecording) || void 0 === d ? void 0 : d.canvasFps,
          quality: null === (h = e.sessionRecording) || void 0 === h ? void 0 : h.canvasQuality
        }), f));
        var _ = null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.sampleRate;
        this._sampleRate = I(_) || x(_) ? null : parseFloat(_);
        var g,
          v = null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.minimumDurationMilliseconds;
        (this._minimumDuration = I(v) ? null : v, this._linkedFlag = (null === (i = e.sessionRecording) || void 0 === i ? void 0 : i.linkedFlag) || null, null !== (o = e.sessionRecording) && void 0 !== o && o.endpoint) && (this._endpoint = null === (g = e.sessionRecording) || void 0 === g ? void 0 : g.endpoint);
        if (C(this._sampleRate) && this.sessionManager.onSessionId(function (e) {
          p.makeSamplingDecision(e);
        }), E(this._linkedFlag)) {
          var m = this._linkedFlag;
          this.instance.onFeatureFlags(function (e) {
            p._linkedFlagSeen = e.includes(m);
          });
        }
        this.receivedDecide = !0, this.startRecordingIfEnabled();
      }
    }, {
      key: "log",
      value: function (e) {
        var t,
          n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
        null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
          type: 6,
          data: {
            plugin: "rrweb/console@1",
            payload: {
              level: n,
              trace: [],
              payload: [JSON.stringify(e)]
            }
          },
          timestamp: ee()
        });
      }
    }, {
      key: "startCaptureAndTrySendingQueuedSnapshots",
      value: function () {
        this._startCapture();
      }
    }, {
      key: "_startCapture",
      value: function () {
        var e = this;
        if (!I(Object.assign) && !(this._captureStarted || this.instance.config.disable_session_recording || Xt(this.instance))) {
          this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId();
          var t = "v2" === this.recordingVersion ? "recorder-v2.js" : "recorder.js";
          this.instance.__loaded_recorder_version !== this.recordingVersion ? ue(this.instance.requestRouter.endpointFor("assets", "/static/".concat(t, "?v=").concat(m.LIB_VERSION)), function (n) {
            if (n) return z.error("Could not load ".concat(t), n);
            e._onScriptLoaded();
          }) : this._onScriptLoaded();
        }
      }
    }, {
      key: "_isInteractiveEvent",
      value: function (e) {
        var t;
        return 3 === e.type && -1 !== nn.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
      }
    }, {
      key: "_updateWindowAndSessionIds",
      value: function (e) {
        var t = this._isInteractiveEvent(e);
        t || this.isIdle || e.timestamp - this._lastActivityTimestamp > tn && (this.isIdle = !0, this._tryAddCustomEvent("sessionIdle", {
          reason: "user inactivity",
          timeSinceLastActive: e.timestamp - this._lastActivityTimestamp,
          threshold: tn
        }));
        var n = !1;
        if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
          reason: "user activity",
          type: e.type
        }), n = !0)), !this.isIdle) {
          var r = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
            i = r.windowId,
            o = r.sessionId,
            s = this.sessionId !== o,
            a = this.windowId !== i;
          this.windowId = i, this.sessionId = o, (n || -1 === [$t, At].indexOf(e.type) && (a || s)) && this._tryTakeFullSnapshot();
        }
      }
    }, {
      key: "_tryRRWebMethod",
      value: function (e) {
        try {
          return e.rrwebMethod(), !0;
        } catch (t) {
          return z.warn("[Session-Recording] could not emit queued rrweb event.", t), this.queuedRRWebEvents.length < 10 && this.queuedRRWebEvents.push({
            enqueuedAt: e.enqueuedAt || Date.now(),
            attempt: e.attempt++,
            rrwebMethod: e.rrwebMethod
          }), !1;
        }
      }
    }, {
      key: "_tryAddCustomEvent",
      value: function (e, t) {
        var n = this;
        return this._tryRRWebMethod(rn(function () {
          return n.rrwebRecord.addCustomEvent(e, t);
        }));
      }
    }, {
      key: "_tryTakeFullSnapshot",
      value: function () {
        var e = this;
        return this._tryRRWebMethod(rn(function () {
          return e.rrwebRecord.takeFullSnapshot();
        }));
      }
    }, {
      key: "_onScriptLoaded",
      value: function () {
        var e,
          n = this,
          r = {
            blockClass: "ph-no-capture",
            blockSelector: void 0,
            ignoreClass: "ph-ignore-input",
            maskTextClass: "ph-mask",
            maskTextSelector: void 0,
            maskTextFn: void 0,
            maskAllInputs: !0,
            maskInputOptions: {},
            maskInputFn: void 0,
            slimDOMOptions: {},
            collectFonts: !1,
            inlineStylesheet: !0,
            recordCrossOriginIframes: !1
          };
        this.rrwebRecord = $.rrweb ? $.rrweb.record : $.rrwebRecord;
        for (var i = this.instance.config.session_recording, o = 0, s = Object.entries(i || {}); o < s.length; o++) {
          var a = f(s[o], 2),
            u = a[0],
            l = a[1];
          u in r && (r[u] = l);
        }
        if (this.canvasRecording && this.canvasRecording.enabled && (r.recordCanvas = !0, r.sampling = {
          canvas: this.canvasRecording.fps
        }, r.dataURLOptions = {
          type: "image/webp",
          quality: this.canvasRecording.quality
        }), this.rrwebRecord) {
          this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new Mt(this.rrwebRecord, {
            onBlockedNode: function (e, t) {
              var r = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
              z.info(r, {
                node: t
              }), n.log("[PostHog Recorder] " + r, "warn");
            }
          }), this._scheduleFullSnapshot();
          var c = this._gatherRRWebPlugins();
          this.stopRrweb = this.rrwebRecord(t({
            emit: function (e) {
              n.onRRwebEmit(e);
            },
            plugins: c
          }, r)), this.instance._addCaptureHook(function (e) {
            try {
              if ("$pageview" === e) {
                var t = $ ? n._maskUrl($.location.href) : "";
                if (!t) return;
                n._tryAddCustomEvent("$pageview", {
                  href: t
                }), n._tryTakeFullSnapshot();
              }
            } catch (e) {
              z.error("Could not add $pageview to rrweb session", e);
            }
          }), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
            sessionRecordingOptions: r,
            activePlugins: c.map(function (e) {
              return null == e ? void 0 : e.name;
            })
          }), this._tryAddCustomEvent("$posthog_config", {
            config: this.instance.config
          });
        } else z.error("onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
      }
    }, {
      key: "_scheduleFullSnapshot",
      value: function () {
        var e = this;
        this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), this._fullSnapshotTimer = setInterval(function () {
          e._tryTakeFullSnapshot();
        }, en);
      }
    }, {
      key: "_gatherRRWebPlugins",
      value: function () {
        var e = [];
        (W.rrwebConsoleRecord && this.isConsoleLogCaptureEnabled && e.push(W.rrwebConsoleRecord.getRecordConsolePlugin()), this.networkPayloadCapture && P(W.getRecordNetworkPlugin)) && (!St.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? e.push(W.getRecordNetworkPlugin(jt(this.instance.config, this.networkPayloadCapture))) : z.info("[SessionReplay-NetworkCapture] not started because we are on localhost."));
        return e;
      }
    }, {
      key: "onRRwebEmit",
      value: function (e) {
        if (this._processQueuedEvents(), e && R(e)) {
          if (e.type === qt.Meta) {
            var t = this._maskUrl(e.data.href);
            if (!t) return;
            e.data.href = t;
          }
          e.type === qt.FullSnapshot && this._scheduleFullSnapshot();
          var n = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
          if (n) {
            var r = function (e) {
                var t = e;
                if (t && R(t) && 6 === t.type && R(t.data) && "rrweb/console@1" === t.data.plugin) {
                  t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
                  for (var n = [], r = 0; r < t.data.payload.payload.length; r++) t.data.payload.payload[r] && t.data.payload.payload[r].length > 2e3 ? n.push(t.data.payload.payload[r].slice(0, 2e3) + "...[truncated]") : n.push(t.data.payload.payload[r]);
                  return t.data.payload.payload = n, e;
                }
                return e;
              }(n),
              i = JSON.stringify(r).length;
            if (this._updateWindowAndSessionIds(r), !this.isIdle || r.type === qt.Custom) {
              var o = {
                $snapshot_bytes: i,
                $snapshot_data: r,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
              "disabled" !== this.status ? this._captureSnapshotBuffered(o) : this.clearBuffer();
            }
          }
        }
      }
    }, {
      key: "_processQueuedEvents",
      value: function () {
        var e = this;
        if (this.queuedRRWebEvents.length) {
          var t = p(this.queuedRRWebEvents);
          this.queuedRRWebEvents = [], t.forEach(function (n) {
            Date.now() - n.enqueuedAt > 2e3 ? e._tryAddCustomEvent("rrwebQueueTimeout", {
              enqueuedAt: n.enqueuedAt,
              attempt: n.attempt,
              queueLength: t.length
            }) : e._tryRRWebMethod(n) && e._tryAddCustomEvent("rrwebQueueSuccess", {
              enqueuedAt: n.enqueuedAt,
              attempt: n.attempt,
              queueLength: t.length
            });
          });
        }
      }
    }, {
      key: "_maskUrl",
      value: function (e) {
        var t = this.instance.config.session_recording;
        if (t.maskNetworkRequestFn) {
          var n,
            r = {
              url: e
            };
          return null === (n = r = t.maskNetworkRequestFn(r)) || void 0 === n ? void 0 : n.url;
        }
        return e;
      }
    }, {
      key: "clearBuffer",
      value: function () {
        return this.buffer = void 0, {
          size: 0,
          data: [],
          sessionId: this.sessionId,
          windowId: this.windowId
        };
      }
    }, {
      key: "_flushBuffer",
      value: function () {
        var e = this;
        this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
        var t = this._minimumDuration,
          n = this.sessionDuration,
          r = C(n) && n >= 0,
          i = C(t) && r && n < t;
        return "buffering" === this.status || i ? (this.flushBufferTimer = setTimeout(function () {
          e._flushBuffer();
        }, 2e3), this.buffer || this.clearBuffer()) : this.buffer && 0 !== this.buffer.data.length ? (this._captureSnapshot({
          $snapshot_bytes: this.buffer.size,
          $snapshot_data: this.buffer.data,
          $session_id: this.buffer.sessionId,
          $window_id: this.buffer.windowId
        }), this.clearBuffer()) : this.buffer || this.clearBuffer();
      }
    }, {
      key: "_captureSnapshotBuffered",
      value: function (e) {
        var t,
          n = this,
          r = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
        (!this.buffer || this.buffer.size + e.$snapshot_bytes + r > 943718.4 || this.buffer.sessionId && this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), x(this.buffer.sessionId) && !x(this.sessionId) && (this.buffer.sessionId = this.sessionId, this.buffer.windowId = this.windowId), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || (this.flushBufferTimer = setTimeout(function () {
          n._flushBuffer();
        }, 2e3));
      }
    }, {
      key: "_captureSnapshot",
      value: function (e) {
        this.instance.capture("$snapshot", e, {
          method: "POST",
          _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
          _noTruncate: !0,
          _batchKey: "recordings",
          _metrics: {
            rrweb_full_snapshot: e.$snapshot_data.type === $t
          }
        });
      }
    }]), e;
  }(),
  sn = function () {
    function e(t) {
      r(this, e), this.instance = t, this.instance.decideEndpointWasHit = this.instance._hasBootstrappedFeatureFlags();
    }
    return o(e, [{
      key: "call",
      value: function () {
        var e = this,
          t = oe(JSON.stringify({
            token: this.instance.config.token,
            distinct_id: this.instance.get_distinct_id(),
            groups: this.instance.getGroups(),
            person_properties: this.instance.get_property(je),
            group_properties: this.instance.get_property(He),
            disable_flags: this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load || void 0
          }));
        this.instance._send_request(this.instance.requestRouter.endpointFor("api", "/decide/?v=3"), {
          data: t,
          verbose: !0
        }, {
          method: "POST"
        }, function (t) {
          return e.parseDecideResponse(t);
        });
      }
    }, {
      key: "parseDecideResponse",
      value: function (e) {
        var t,
          n = this;
        if (this.instance.featureFlags.setReloadingPaused(!1), this.instance.featureFlags._startReloadTimer(), 0 !== (null == e ? void 0 : e.status)) {
          if (!B || !B.body) return z.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(function () {
            n.parseDecideResponse(e);
          }, 500);
          this.instance.toolbar.afterDecideResponse(e), null === (t = this.instance.sessionRecording) || void 0 === t || t.afterDecideResponse(e), Je.afterDecideResponse(e, this.instance), this.instance._afterDecideResponse(e), this.instance.config.advanced_disable_feature_flags_on_first_load || this.instance.config.advanced_disable_feature_flags || this.instance.featureFlags.receivedFeatureFlags(e);
          var r = null == $ ? void 0 : $.extendPostHogWithSurveys;
          e.surveys && !r && ue(this.instance.requestRouter.endpointFor("assets", "/static/surveys.js"), function (e) {
            if (e) return z.error("Could not load surveys script", e);
            $.extendPostHogWithSurveys(n.instance);
          });
          var i = null == $ ? void 0 : $.extendPostHogWithExceptionAutoCapture;
          if (e.autocaptureExceptions && e.autocaptureExceptions && I(i) && ue(this.instance.requestRouter.endpointFor("assets", "/static/exception-autocapture.js"), function (t) {
            if (t) return z.error("Could not load exception autocapture script", t);
            $.extendPostHogWithExceptionAutocapture(n.instance, e);
          }), e.siteApps) if (this.instance.config.opt_in_site_apps) {
            var o,
              s = v(e.siteApps);
            try {
              var a = function () {
                var e = o.value,
                  t = e.id,
                  r = e.url,
                  i = n.instance.requestRouter.endpointFor("assets", r);
                W["__$$ph_site_app_".concat(t)] = n.instance, ue(i, function (e) {
                  e && z.error("Error while initializing PostHog app with config id ".concat(t), e);
                });
              };
              for (s.s(); !(o = s.n()).done;) a();
            } catch (e) {
              s.e(e);
            } finally {
              s.f();
            }
          } else e.siteApps.length > 0 && z.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
        } else z.error("Failed to fetch feature flags from PostHog.");
      }
    }]), e;
  }(),
  an = ["source"],
  un = null != $ && $.location ? xt($.location.hash, "__posthog") || xt(location.hash, "state") : null,
  ln = function () {
    function e(t) {
      r(this, e), this.instance = t;
    }
    return o(e, [{
      key: "afterDecideResponse",
      value: function (e) {
        var n = e.toolbarParams || e.editorParams || (e.toolbarVersion ? {
          toolbarVersion: e.toolbarVersion
        } : {});
        e.isAuthenticated && n.toolbarVersion && 0 === n.toolbarVersion.indexOf("toolbar") && this.loadToolbar(t({}, n));
      }
    }, {
      key: "maybeLoadToolbar",
      value: function () {
        var e,
          t,
          n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
          r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
          i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
        if (!$ || !B) return !1;
        n = null !== (e = n) && void 0 !== e ? e : $.location, i = null !== (t = i) && void 0 !== t ? t : $.history;
        try {
          if (!r) {
            try {
              $.localStorage.setItem("test", "test"), $.localStorage.removeItem("test");
            } catch (e) {
              return !1;
            }
            r = null == $ ? void 0 : $.localStorage;
          }
          var o,
            s = un || xt(n.hash, "__posthog") || xt(n.hash, "state"),
            a = s ? te(function () {
              return JSON.parse(atob(decodeURIComponent(s)));
            }) || te(function () {
              return JSON.parse(decodeURIComponent(s));
            }) : null;
          return a && "ph_authorize" === a.action ? ((o = a).source = "url", o && Object.keys(o).length > 0 && (a.desiredHash ? n.hash = a.desiredHash : i ? i.replaceState("", B.title, n.pathname + n.search) : n.hash = "")) : ((o = JSON.parse(r.getItem("_postHogToolbarParams") || "{}")).source = "localstorage", delete o.userIntent), !(!o.token || this.instance.config.token !== o.token) && (this.loadToolbar(o), !0);
        } catch (e) {
          return !1;
        }
      }
    }, {
      key: "loadToolbar",
      value: function (e) {
        var n = this;
        if (!$ || W._postHogToolbarLoaded) return !1;
        W._postHogToolbarLoaded = !0;
        var r = 3e5,
          i = Math.floor(Date.now() / r) * r,
          o = this.instance.requestRouter.endpointFor("assets", "/static/toolbar.js?t=".concat(i)),
          s = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
          a = t(t({
            token: this.instance.config.token
          }, e), {}, {
            apiURL: this.instance.requestRouter.endpointFor("api")
          }, s ? {
            instrument: !1
          } : {});
        a.source;
        var u = c(a, an);
        return $.localStorage.setItem("_postHogToolbarParams", JSON.stringify(u)), ue(o, function (e) {
          e ? z.error("Failed to load toolbar", e) : (W.ph_load_toolbar || W.ph_load_editor)(a, n.instance);
        }), ae($, "turbolinks:load", function () {
          W._postHogToolbarLoaded = !1, n.loadToolbar(a);
        }), !0;
      }
    }, {
      key: "_loadEditor",
      value: function (e) {
        return this.loadToolbar(e);
      }
    }, {
      key: "maybeLoadEditor",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
          t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
          n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
        return this.maybeLoadToolbar(e, t, n);
      }
    }]), e;
  }(),
  cn = function () {
    function e() {
      var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 3e3;
      r(this, e), this.isPolling = !0, this._event_queue = [], this._empty_queue_count = 0, this._poller = void 0, this._pollInterval = t;
    }
    return o(e, [{
      key: "setPollInterval",
      value: function (e) {
        this._pollInterval = e, this.isPolling && this.poll();
      }
    }, {
      key: "poll",
      value: function () {}
    }, {
      key: "unload",
      value: function () {}
    }, {
      key: "getTime",
      value: function () {
        return new Date().getTime();
      }
    }]), e;
  }(),
  dn = function (e) {
    a(i, cn);
    var n = h(i);
    function i(e) {
      var t,
        o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 3e3;
      return r(this, i), (t = n.call(this, o)).handlePollRequest = e, t;
    }
    return o(i, [{
      key: "enqueue",
      value: function (e, t, n) {
        this._event_queue.push({
          url: e,
          data: t,
          options: n
        }), this.isPolling || (this.isPolling = !0, this.poll());
      }
    }, {
      key: "poll",
      value: function () {
        var e = this;
        clearTimeout(this._poller), this._poller = setTimeout(function () {
          if (e._event_queue.length > 0) {
            var t = e.formatQueue(),
              n = function (n) {
                var r = t[n],
                  i = r.url,
                  o = r.data,
                  s = r.options;
                J(o, function (t, n) {
                  o[n].offset = Math.abs(o[n].timestamp - e.getTime()), delete o[n].timestamp;
                }), e.handlePollRequest(i, o, s);
              };
            for (var r in t) n(r);
            e._event_queue.length = 0, e._empty_queue_count = 0;
          } else e._empty_queue_count++;
          e._empty_queue_count > 4 && (e.isPolling = !1, e._empty_queue_count = 0), e.isPolling && e.poll();
        }, this._pollInterval);
      }
    }, {
      key: "unload",
      value: function () {
        var e = this;
        clearTimeout(this._poller);
        var n = this._event_queue.length > 0 ? this.formatQueue() : {};
        this._event_queue.length = 0;
        var r = Object.values(n);
        [].concat(p(r.filter(function (e) {
          return 0 === e.url.indexOf("/e");
        })), p(r.filter(function (e) {
          return 0 !== e.url.indexOf("/e");
        }))).map(function (n) {
          var r = n.url,
            i = n.data,
            o = n.options;
          e.handlePollRequest(r, i, t(t({}, o), {}, {
            transport: "sendBeacon"
          }));
        });
      }
    }, {
      key: "formatQueue",
      value: function () {
        var e = {};
        return J(this._event_queue, function (t) {
          var n = t.url,
            r = t.data,
            i = t.options,
            o = (i ? i._batchKey : null) || n;
          I(e[o]) && (e[o] = {
            data: [],
            url: n,
            options: i
          }), i && e[o].options && e[o].options._metrics && !e[o].options._metrics.rrweb_full_snapshot && (e[o].options._metrics.rrweb_full_snapshot = i._metrics.rrweb_full_snapshot), e[o].data.push(r);
        }), e;
      }
    }]), i;
  }(),
  hn = Uint8Array,
  fn = Uint16Array,
  pn = Uint32Array,
  _n = new hn([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  gn = new hn([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  vn = new hn([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  mn = function (e, t) {
    for (var n = new fn(31), r = 0; r < 31; ++r) n[r] = t += 1 << e[r - 1];
    var i = new pn(n[30]);
    for (r = 1; r < 30; ++r) for (var o = n[r]; o < n[r + 1]; ++o) i[o] = o - n[r] << 5 | r;
    return [n, i];
  },
  yn = mn(_n, 2),
  bn = yn[0],
  kn = yn[1];
bn[28] = 258, kn[258] = 28;
for (var wn = mn(gn, 0)[1], Sn = new fn(32768), Fn = 0; Fn < 32768; ++Fn) {
  var Pn = (43690 & Fn) >>> 1 | (21845 & Fn) << 1;
  Pn = (61680 & (Pn = (52428 & Pn) >>> 2 | (13107 & Pn) << 2)) >>> 4 | (3855 & Pn) << 4, Sn[Fn] = ((65280 & Pn) >>> 8 | (255 & Pn) << 8) >>> 1;
}
var Rn = function (e, t, n) {
    for (var r = e.length, i = 0, o = new fn(t); i < r; ++i) ++o[e[i] - 1];
    var s,
      a = new fn(t);
    for (i = 0; i < t; ++i) a[i] = a[i - 1] + o[i - 1] << 1;
    if (n) {
      s = new fn(1 << t);
      var u = 15 - t;
      for (i = 0; i < r; ++i) if (e[i]) for (var l = i << 4 | e[i], c = t - e[i], d = a[e[i] - 1]++ << c, h = d | (1 << c) - 1; d <= h; ++d) s[Sn[d] >>> u] = l;
    } else for (s = new fn(r), i = 0; i < r; ++i) s[i] = Sn[a[e[i] - 1]++] >>> 15 - e[i];
    return s;
  },
  In = new hn(288);
for (Fn = 0; Fn < 144; ++Fn) In[Fn] = 8;
for (Fn = 144; Fn < 256; ++Fn) In[Fn] = 9;
for (Fn = 256; Fn < 280; ++Fn) In[Fn] = 7;
for (Fn = 280; Fn < 288; ++Fn) In[Fn] = 8;
var En = new hn(32);
for (Fn = 0; Fn < 32; ++Fn) En[Fn] = 5;
var xn,
  Cn = Rn(In, 9, 0),
  On = Rn(En, 5, 0),
  Tn = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  $n = function (e, t, n) {
    (null == t || t < 0) && (t = 0), (null == n || n > e.length) && (n = e.length);
    var r = new (e instanceof fn ? fn : e instanceof pn ? pn : hn)(n - t);
    return r.set(e.subarray(t, n)), r;
  },
  An = function (e, t, n) {
    n <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= n, e[r + 1] |= n >>> 8;
  },
  Mn = function (e, t, n) {
    n <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= n, e[r + 1] |= n >>> 8, e[r + 2] |= n >>> 16;
  },
  qn = function (e, t) {
    for (var n = [], r = 0; r < e.length; ++r) e[r] && n.push({
      s: r,
      f: e[r]
    });
    var i = n.length,
      o = n.slice();
    if (!i) return [new hn(0), 0];
    if (1 == i) {
      var s = new hn(n[0].s + 1);
      return s[n[0].s] = 1, [s, 1];
    }
    n.sort(function (e, t) {
      return e.f - t.f;
    }), n.push({
      s: -1,
      f: 25001
    });
    var a = n[0],
      u = n[1],
      l = 0,
      c = 1,
      d = 2;
    for (n[0] = {
      s: -1,
      f: a.f + u.f,
      l: a,
      r: u
    }; c != i - 1;) a = n[n[l].f < n[d].f ? l++ : d++], u = n[l != c && n[l].f < n[d].f ? l++ : d++], n[c++] = {
      s: -1,
      f: a.f + u.f,
      l: a,
      r: u
    };
    var h = o[0].s;
    for (r = 1; r < i; ++r) o[r].s > h && (h = o[r].s);
    var f = new fn(h + 1),
      p = Dn(n[c - 1], f, 0);
    if (p > t) {
      r = 0;
      var _ = 0,
        g = p - t,
        v = 1 << g;
      for (o.sort(function (e, t) {
        return f[t.s] - f[e.s] || e.f - t.f;
      }); r < i; ++r) {
        var m = o[r].s;
        if (!(f[m] > t)) break;
        _ += v - (1 << p - f[m]), f[m] = t;
      }
      for (_ >>>= g; _ > 0;) {
        var y = o[r].s;
        f[y] < t ? _ -= 1 << t - f[y]++ - 1 : ++r;
      }
      for (; r >= 0 && _; --r) {
        var b = o[r].s;
        f[b] == t && (--f[b], ++_);
      }
      p = t;
    }
    return [new hn(f), p];
  },
  Dn = function e(t, n, r) {
    return -1 == t.s ? Math.max(e(t.l, n, r + 1), e(t.r, n, r + 1)) : n[t.s] = r;
  },
  Ln = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var n = new fn(++t), r = 0, i = e[0], o = 1, s = function (e) {
        n[r++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == i && a != t) ++o;else {
      if (!i && o > 2) {
        for (; o > 138; o -= 138) s(32754);
        o > 2 && (s(o > 10 ? o - 11 << 5 | 28690 : o - 3 << 5 | 12305), o = 0);
      } else if (o > 3) {
        for (s(i), --o; o > 6; o -= 6) s(8304);
        o > 2 && (s(o - 3 << 5 | 8208), o = 0);
      }
      for (; o--;) s(i);
      o = 1, i = e[a];
    }
    return [n.subarray(0, r), t];
  },
  Bn = function (e, t) {
    for (var n = 0, r = 0; r < t.length; ++r) n += e[r] * t[r];
    return n;
  },
  Nn = function (e, t, n) {
    var r = n.length,
      i = Tn(t + 2);
    e[i] = 255 & r, e[i + 1] = r >>> 8, e[i + 2] = 255 ^ e[i], e[i + 3] = 255 ^ e[i + 1];
    for (var o = 0; o < r; ++o) e[i + o + 4] = n[o];
    return 8 * (i + 4 + r);
  },
  jn = function (e, t, n, r, i, o, s, a, u, l, c) {
    An(t, c++, n), ++i[256];
    for (var d = qn(i, 15), h = d[0], f = d[1], p = qn(o, 15), _ = p[0], g = p[1], v = Ln(h), m = v[0], y = v[1], b = Ln(_), k = b[0], w = b[1], S = new fn(19), F = 0; F < m.length; ++F) S[31 & m[F]]++;
    for (F = 0; F < k.length; ++F) S[31 & k[F]]++;
    for (var P = qn(S, 7), R = P[0], I = P[1], E = 19; E > 4 && !R[vn[E - 1]]; --E);
    var x,
      C,
      O,
      T,
      $ = l + 5 << 3,
      A = Bn(i, In) + Bn(o, En) + s,
      M = Bn(i, h) + Bn(o, _) + s + 14 + 3 * E + Bn(S, R) + (2 * S[16] + 3 * S[17] + 7 * S[18]);
    if ($ <= A && $ <= M) return Nn(t, c, e.subarray(u, u + l));
    if (An(t, c, 1 + (M < A)), c += 2, M < A) {
      x = Rn(h, f, 0), C = h, O = Rn(_, g, 0), T = _;
      var q = Rn(R, I, 0);
      An(t, c, y - 257), An(t, c + 5, w - 1), An(t, c + 10, E - 4), c += 14;
      for (F = 0; F < E; ++F) An(t, c + 3 * F, R[vn[F]]);
      c += 3 * E;
      for (var D = [m, k], L = 0; L < 2; ++L) {
        var B = D[L];
        for (F = 0; F < B.length; ++F) {
          var N = 31 & B[F];
          An(t, c, q[N]), c += R[N], N > 15 && (An(t, c, B[F] >>> 5 & 127), c += B[F] >>> 12);
        }
      }
    } else x = Cn, C = In, O = On, T = En;
    for (F = 0; F < a; ++F) if (r[F] > 255) {
      N = r[F] >>> 18 & 31;
      Mn(t, c, x[N + 257]), c += C[N + 257], N > 7 && (An(t, c, r[F] >>> 23 & 31), c += _n[N]);
      var j = 31 & r[F];
      Mn(t, c, O[j]), c += T[j], j > 3 && (Mn(t, c, r[F] >>> 5 & 8191), c += gn[j]);
    } else Mn(t, c, x[r[F]]), c += C[r[F]];
    return Mn(t, c, x[256]), c + C[256];
  },
  Hn = new pn([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Un = new hn(0),
  Wn = function () {
    for (var e = new pn(256), t = 0; t < 256; ++t) {
      for (var n = t, r = 9; --r;) n = (1 & n && 3988292384) ^ n >>> 1;
      e[t] = n;
    }
    return e;
  }(),
  Vn = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var n = e, r = 0; r < t.length; ++r) n = Wn[255 & n ^ t[r]] ^ n >>> 8;
        e = n;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  zn = function (e, t, n, r, i) {
    return function (e, t, n, r, i, o) {
      var s = e.length,
        a = new hn(r + s + 5 * (1 + Math.floor(s / 7e3)) + i),
        u = a.subarray(r, a.length - i),
        l = 0;
      if (!t || s < 8) for (var c = 0; c <= s; c += 65535) {
        var d = c + 65535;
        d < s ? l = Nn(u, l, e.subarray(c, d)) : (u[c] = o, l = Nn(u, l, e.subarray(c, s)));
      } else {
        for (var h = Hn[t - 1], f = h >>> 13, p = 8191 & h, _ = (1 << n) - 1, g = new fn(32768), v = new fn(_ + 1), m = Math.ceil(n / 3), y = 2 * m, b = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << y) & _;
          }, k = new pn(25e3), w = new fn(288), S = new fn(32), F = 0, P = 0, R = (c = 0, 0), I = 0, E = 0; c < s; ++c) {
          var x = b(c),
            C = 32767 & c,
            O = v[x];
          if (g[C] = O, v[x] = C, I <= c) {
            var T = s - c;
            if ((F > 7e3 || R > 24576) && T > 423) {
              l = jn(e, u, 0, k, w, S, P, R, E, c - E, l), R = F = P = 0, E = c;
              for (var $ = 0; $ < 286; ++$) w[$] = 0;
              for ($ = 0; $ < 30; ++$) S[$] = 0;
            }
            var A = 2,
              M = 0,
              q = p,
              D = C - O & 32767;
            if (T > 2 && x == b(c - D)) for (var L = Math.min(f, T) - 1, B = Math.min(32767, c), N = Math.min(258, T); D <= B && --q && C != O;) {
              if (e[c + A] == e[c + A - D]) {
                for (var j = 0; j < N && e[c + j] == e[c + j - D]; ++j);
                if (j > A) {
                  if (A = j, M = D, j > L) break;
                  var H = Math.min(D, j - 2),
                    U = 0;
                  for ($ = 0; $ < H; ++$) {
                    var W = c - D + $ + 32768 & 32767,
                      V = W - g[W] + 32768 & 32767;
                    V > U && (U = V, O = W);
                  }
                }
              }
              D += (C = O) - (O = g[C]) + 32768 & 32767;
            }
            if (M) {
              k[R++] = 268435456 | kn[A] << 18 | wn[M];
              var z = 31 & kn[A],
                G = 31 & wn[M];
              P += _n[z] + gn[G], ++w[257 + z], ++S[G], I = c + A, ++F;
            } else k[R++] = e[c], ++w[e[c]];
          }
        }
        l = jn(e, u, o, k, w, S, P, R, E, c - E, l), o || (l = Nn(u, l, Un));
      }
      return $n(a, 0, r + Tn(l) + i);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, n, r, !i);
  },
  Gn = function (e, t, n) {
    for (; n; ++t) e[t] = n, n >>>= 8;
  },
  Qn = function (e, t) {
    var n = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && Gn(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), n) {
      e[3] = 8;
      for (var r = 0; r <= n.length; ++r) e[r + 10] = n.charCodeAt(r);
    }
  },
  Yn = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function Jn(e, t) {
  void 0 === t && (t = {});
  var n = Vn(),
    r = e.length;
  n.p(e);
  var i = zn(e, t, Yn(t), 8),
    o = i.length;
  return Qn(i, t), Gn(i, o - 8, n.d()), Gn(i, o - 4, r), i;
}
function Kn(e, t) {
  var n = e.length;
  if (!t && "undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var r = new hn(e.length + (e.length >>> 1)), i = 0, o = function (e) {
      r[i++] = e;
    }, s = 0; s < n; ++s) {
    if (i + 5 > r.length) {
      var a = new hn(i + 8 + (n - s << 1));
      a.set(r), r = a;
    }
    var u = e.charCodeAt(s);
    u < 128 || t ? o(u) : u < 2048 ? (o(192 | u >>> 6), o(128 | 63 & u)) : u > 55295 && u < 57344 ? (o(240 | (u = 65536 + (1047552 & u) | 1023 & e.charCodeAt(++s)) >>> 18), o(128 | u >>> 12 & 63), o(128 | u >>> 6 & 63), o(128 | 63 & u)) : (o(224 | u >>> 12), o(128 | u >>> 6 & 63), o(128 | 63 & u));
  }
  return $n(r, 0, i);
}
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(xn || (xn = {}));
var Xn = function (e, t, n) {
    var r = t || {};
    r.ip = n.ip ? 1 : 0, r._ = new Date().getTime().toString(), r.ver = m.LIB_VERSION;
    var i = e.split("?");
    if (i.length > 1) {
      var o,
        s = v(i[1].split("&"));
      try {
        for (s.s(); !(o = s.n()).done;) {
          var a = o.value.split("=")[0];
          I(r[a]) || delete r[a];
        }
      } catch (e) {
        s.e(e);
      } finally {
        s.f();
      }
    }
    var u = e.indexOf("?") > -1 ? "&" : "?";
    return e + u + It(r);
  },
  Zn = function e(t, n) {
    if (n.blob && t.buffer) return new Blob([F(t) ? t : t.buffer], {
      type: "text/plain"
    });
    if (n.sendBeacon || n.blob) {
      var r = e(t, {
        method: "POST"
      });
      return new Blob([r], {
        type: "application/x-www-form-urlencoded"
      });
    }
    return "POST" !== n.method ? null : (i = S(t) || F(t) ? "data=" + encodeURIComponent(t) : "data=" + encodeURIComponent(t.data), "compression" in t && t.compression && (i += "&compression=" + t.compression), i);
    var i;
  },
  er = function (e) {
    if (j && "fetch" === e.options.transport) {
      var n,
        r = Zn(e.data, e.options),
        i = new Headers();
      J(i, function (e, t) {
        i.append(t, e);
      }), "POST" !== e.options.method || e.options.blob || i.append("Content-Type", "application/x-www-form-urlencoded");
      var o = e.url;
      return C(e.retriesPerformedSoFar) && e.retriesPerformedSoFar > 0 && (o = Xn(o, {
        retry_count: e.retriesPerformedSoFar
      }, {})), void j(o, {
        method: (null === (n = e.options) || void 0 === n ? void 0 : n.method) || "GET",
        headers: i,
        keepalive: "POST" === e.options.method,
        body: r
      }).then(function (n) {
        var r = n.status;
        return n.text().then(function (n) {
          var o, s;
          if (null === (o = e.onResponse) || void 0 === o || o.call(e, {
            statusCode: r,
            responseText: n
          }), 200 !== r) P(e.onError) && e.onError({
            statusCode: r,
            responseText: n
          }), (r < 400 || r > 500) && e.retryQueue.enqueue(t(t({}, e), {}, {
            headers: i,
            retriesPerformedSoFar: (e.retriesPerformedSoFar || 0) + 1
          })), null === (s = e.callback) || void 0 === s || s.call(e, {
            status: 0
          });else try {
            var a;
            null === (a = e.callback) || void 0 === a || a.call(e, JSON.parse(n));
          } catch (e) {
            z.error(e);
          }
        });
      }).catch(function (t) {
        var n;
        z.error(t), null === (n = e.callback) || void 0 === n || n.call(e, {
          status: 0
        });
      });
    }
    return tr(e);
  },
  tr = function (e) {
    var t = e.url,
      n = e.data,
      r = e.headers,
      i = e.options,
      o = e.callback,
      s = e.retriesPerformedSoFar,
      a = e.retryQueue,
      u = e.onError,
      l = e.timeout,
      c = void 0 === l ? 6e4 : l,
      d = e.onResponse;
    C(s) && s > 0 && (t = Xn(t, {
      retry_count: s
    }, {}));
    var h = new XMLHttpRequest();
    h.open(i.method || "GET", t, !0);
    var f = Zn(n, i);
    J(r, function (e, t) {
      h.setRequestHeader(t, e);
    }), "POST" !== i.method || i.blob || h.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"), h.timeout = c, h.withCredentials = !0, h.onreadystatechange = function () {
      if (4 === h.readyState) {
        var e = {
          statusCode: h.status,
          responseText: h.responseText
        };
        if (null == d || d(e), 200 === h.status) {
          if (o) {
            var l;
            try {
              l = JSON.parse(h.responseText);
            } catch (e) {
              return void z.error(e);
            }
            o(l);
          }
        } else P(u) && u(e), (h.status < 400 || h.status > 500) && a.enqueue({
          url: t,
          data: n,
          options: i,
          headers: r,
          retriesPerformedSoFar: (s || 0) + 1,
          callback: o
        }), null == o || o({
          status: 0
        });
      }
    }, h.send(f);
  };
var nr,
  rr = function (e) {
    a(i, cn);
    var n = h(i);
    function i(e, t) {
      var o;
      return r(this, i), (o = n.call(this)).isPolling = !1, o.queue = [], o.areWeOnline = !0, o.onRequestError = e, o.rateLimiter = t, !I($) && "onLine" in $.navigator && (o.areWeOnline = $.navigator.onLine, $.addEventListener("online", function () {
        o._handleWeAreNowOnline();
      }), $.addEventListener("offline", function () {
        o.areWeOnline = !1;
      })), o;
    }
    return o(i, [{
      key: "enqueue",
      value: function (e) {
        var t = e.retriesPerformedSoFar || 0;
        if (!(t >= 10)) {
          var n = function (e) {
              var t = 3e3 * Math.pow(2, e),
                n = t / 2,
                r = Math.min(18e5, t),
                i = (Math.random() - .5) * (r - n);
              return Math.ceil(r + i);
            }(t),
            r = new Date(Date.now() + n);
          this.queue.push({
            retryAt: r,
            requestData: e
          });
          var i = "Enqueued failed request for retry in ".concat(n);
          navigator.onLine || (i += " (Browser is offline)"), z.warn(i), this.isPolling || (this.isPolling = !0, this.poll());
        }
      }
    }, {
      key: "poll",
      value: function () {
        var e = this;
        this._poller && clearTimeout(this._poller), this._poller = setTimeout(function () {
          e.areWeOnline && e.queue.length > 0 && e.flush(), e.poll();
        }, this._pollInterval);
      }
    }, {
      key: "flush",
      value: function () {
        var e = new Date(Date.now()),
          t = this.queue.filter(function (t) {
            return t.retryAt < e;
          });
        if (t.length > 0) {
          this.queue = this.queue.filter(function (t) {
            return t.retryAt >= e;
          });
          var n,
            r = v(t);
          try {
            for (r.s(); !(n = r.n()).done;) {
              var i = n.value.requestData;
              this._executeXhrRequest(i);
            }
          } catch (e) {
            r.e(e);
          } finally {
            r.f();
          }
        }
      }
    }, {
      key: "unload",
      value: function () {
        this._poller && (clearTimeout(this._poller), this._poller = void 0);
        var e,
          n = v(this.queue);
        try {
          for (n.s(); !(e = n.n()).done;) {
            var r = e.value.requestData,
              i = r.url,
              o = r.data,
              s = r.options;
            if (this.rateLimiter.isRateLimited(s._batchKey)) z.warn("[RetryQueue] is quota limited. Dropping request.");else try {
              null == $ || $.navigator.sendBeacon(i, Zn(o, t(t({}, s), {}, {
                sendBeacon: !0
              })));
            } catch (e) {
              z.error(e);
            }
          }
        } catch (e) {
          n.e(e);
        } finally {
          n.f();
        }
        this.queue = [];
      }
    }, {
      key: "_executeXhrRequest",
      value: function (e) {
        var t = e.url,
          n = e.data,
          r = e.options,
          i = e.headers,
          o = e.callback,
          s = e.retriesPerformedSoFar;
        this.rateLimiter.isRateLimited(r._batchKey) || er({
          url: t,
          data: n || {},
          options: r || {},
          headers: i || {},
          retriesPerformedSoFar: s || 0,
          callback: o,
          retryQueue: this,
          onError: this.onRequestError,
          onResponse: this.rateLimiter.checkForLimiting
        });
      }
    }, {
      key: "_handleWeAreNowOnline",
      value: function () {
        this.areWeOnline = !0, this.flush();
      }
    }]), i;
  }(),
  ir = 1800,
  or = function () {
    function e(t, n, i, o) {
      r(this, e), s(this, "_sessionIdChangedHandlers", []), this.config = t, this.persistence = n, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = i || ut, this._windowIdGenerator = o || ut;
      var a = t.persistence_name || t.token,
        u = t.session_idle_timeout_seconds || ir;
      if (C(u) ? u > ir ? z.warn("session_idle_timeout_seconds cannot be  greater than 30 minutes. Using 30 minutes instead.") : u < 60 && z.warn("session_idle_timeout_seconds cannot be less than 60 seconds. Using 60 seconds instead.") : (z.warn("session_idle_timeout_seconds must be a number. Defaulting to 30 minutes."), u = ir), this._sessionTimeoutMs = 1e3 * Math.min(Math.max(u, 60), ir), this._window_id_storage_key = "ph_" + a + "_window_id", this._primary_window_exists_storage_key = "ph_" + a + "_primary_window_exists", this._canUseSessionStorage()) {
        var l = wt.parse(this._window_id_storage_key),
          c = wt.parse(this._primary_window_exists_storage_key);
        l && !c ? this._windowId = l : wt.remove(this._window_id_storage_key), wt.set(this._primary_window_exists_storage_key, !0);
      }
      this._listenToReloadWindow();
    }
    return o(e, [{
      key: "onSessionId",
      value: function (e) {
        var t = this;
        return I(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), function () {
          t._sessionIdChangedHandlers = t._sessionIdChangedHandlers.filter(function (t) {
            return t !== e;
          });
        };
      }
    }, {
      key: "_canUseSessionStorage",
      value: function () {
        return "memory" !== this.config.persistence && !this.persistence.disabled && wt.is_supported();
      }
    }, {
      key: "_setWindowId",
      value: function (e) {
        e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && wt.set(this._window_id_storage_key, e));
      }
    }, {
      key: "_getWindowId",
      value: function () {
        return this._windowId ? this._windowId : this._canUseSessionStorage() ? wt.parse(this._window_id_storage_key) : null;
      }
    }, {
      key: "_setSessionId",
      value: function (e, t, n) {
        e === this._sessionId && t === this._sessionActivityTimestamp && n === this._sessionStartTimestamp || (this._sessionStartTimestamp = n, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register(s({}, De, [t, e, n])));
      }
    }, {
      key: "_getSessionId",
      value: function () {
        if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
        var e = this.persistence.props[De];
        return S(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
      }
    }, {
      key: "resetSessionId",
      value: function () {
        this._setSessionId(null, null, null);
      }
    }, {
      key: "_listenToReloadWindow",
      value: function () {
        var e = this;
        null == $ || $.addEventListener("beforeunload", function () {
          e._canUseSessionStorage() && wt.remove(e._primary_window_exists_storage_key);
        });
      }
    }, {
      key: "checkAndGetSessionAndWindowId",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
          t = (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null) || new Date().getTime(),
          n = f(this._getSessionId(), 3),
          r = n[0],
          i = n[1],
          o = n[2],
          s = this._getWindowId(),
          a = o && o > 0 && Math.abs(t - o) > 864e5,
          u = !1,
          l = !i,
          c = !e && Math.abs(t - r) > this._sessionTimeoutMs;
        l || c || a ? (i = this._sessionIdGenerator(), s = this._windowIdGenerator(), z.info("[SessionId] new session ID generated", {
          sessionId: i,
          windowId: s,
          changeReason: {
            noSessionId: l,
            activityTimeout: c,
            sessionPastMaximumLength: a
          }
        }), o = t, u = !0) : s || (s = this._windowIdGenerator(), u = !0);
        var d = 0 === r || !e || a ? t : r,
          h = 0 === o ? new Date().getTime() : o;
        return this._setWindowId(s), this._setSessionId(i, d, h), u && this._sessionIdChangedHandlers.forEach(function (e) {
          return e(i, s);
        }), {
          sessionId: i,
          windowId: s,
          sessionStartTimestamp: h
        };
      }
    }]), e;
  }();
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(nr || (nr = {}));
var sr = function () {
    function e(t) {
      r(this, e), this.instance = t;
    }
    return o(e, [{
      key: "apiHost",
      get: function () {
        return this.instance.config.api_host.trim().replace(/\/$/, "");
      }
    }, {
      key: "uiHost",
      get: function () {
        var e;
        return null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
      }
    }, {
      key: "region",
      get: function () {
        switch (this.apiHost) {
          case "https://app.posthog.com":
          case "https://us.posthog.com":
            return nr.US;
          case "https://eu.posthog.com":
            return nr.EU;
          default:
            return nr.CUSTOM;
        }
      }
    }, {
      key: "endpointFor",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
        if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return (this.uiHost || this.apiHost) + t;
        if (!this.instance.config.__preview_ingestion_endpoints || this.region === nr.CUSTOM) return this.apiHost + t;
        var n = "i.posthog.com" + t;
        switch (e) {
          case "assets":
            return "https://".concat(this.region, "-assets.").concat(n);
          case "api":
            return "https://".concat(this.region, ".").concat(n);
        }
      }
    }]), e;
  }(),
  ar = o(function e(t, n, i, o) {
    r(this, e), this.name = "posthog-js", this.setupOnce = function (e) {
      e(function (e) {
        var r, s, a, u, l;
        if ("error" !== e.level || !t.__loaded) return e;
        e.tags || (e.tags = {});
        var c = t.requestRouter.endpointFor("ui", "/person/" + t.get_distinct_id());
        e.tags["PostHog Person URL"] = c, t.sessionRecordingStarted() && (e.tags["PostHog Recording URL"] = t.get_session_replay_url({
          withTimestamp: !0
        }));
        var d = (null === (r = e.exception) || void 0 === r ? void 0 : r.values) || [],
          h = {
            $exception_message: null === (s = d[0]) || void 0 === s ? void 0 : s.value,
            $exception_type: null === (a = d[0]) || void 0 === a ? void 0 : a.type,
            $exception_personURL: c,
            $sentry_event_id: e.event_id,
            $sentry_exception: e.exception,
            $sentry_exception_message: null === (u = d[0]) || void 0 === u ? void 0 : u.value,
            $sentry_exception_type: null === (l = d[0]) || void 0 === l ? void 0 : l.type,
            $sentry_tags: e.tags
          };
        return n && i && (h.$sentry_url = (o || "https://sentry.io/organizations/") + n + "/issues/?project=" + i + "&query=" + e.event_id), t.capture("$exception", h), e;
      });
    };
  }),
  ur = function () {
    function e(t) {
      var n = this;
      r(this, e), s(this, "_hasSeenPageView", !1), s(this, "_updateScrollData", function () {
        var e, t, r, i;
        n._pageViewData || (n._pageViewData = n._createPageViewData());
        var o = n._pageViewData,
          s = n._scrollY(),
          a = n._scrollHeight(),
          u = n._contentY(),
          l = n._contentHeight();
        o.lastScrollY = s, o.maxScrollY = Math.max(s, null !== (e = o.maxScrollY) && void 0 !== e ? e : 0), o.maxScrollHeight = Math.max(a, null !== (t = o.maxScrollHeight) && void 0 !== t ? t : 0), o.lastContentY = u, o.maxContentY = Math.max(u, null !== (r = o.maxContentY) && void 0 !== r ? r : 0), o.maxContentHeight = Math.max(l, null !== (i = o.maxContentHeight) && void 0 !== i ? i : 0);
      }), this._instance = t;
    }
    return o(e, [{
      key: "_createPageViewData",
      value: function () {
        var e;
        return {
          pathname: null !== (e = null == $ ? void 0 : $.location.pathname) && void 0 !== e ? e : ""
        };
      }
    }, {
      key: "doPageView",
      value: function () {
        var e, n;
        return this._hasSeenPageView ? (n = this._pageViewData, this._pageViewData = this._createPageViewData()) : (this._hasSeenPageView = !0, n = void 0, this._pageViewData || (this._pageViewData = this._createPageViewData())), setTimeout(this._updateScrollData, 0), t({
          $prev_pageview_pathname: null === (e = n) || void 0 === e ? void 0 : e.pathname
        }, this._calculatePrevPageScrollProperties(n));
      }
    }, {
      key: "doPageLeave",
      value: function () {
        var e = this._pageViewData;
        return t({
          $prev_pageview_pathname: null == e ? void 0 : e.pathname
        }, this._calculatePrevPageScrollProperties(e));
      }
    }, {
      key: "_calculatePrevPageScrollProperties",
      value: function (e) {
        if (!e || null == e.maxScrollHeight || null == e.lastScrollY || null == e.maxScrollY || null == e.maxContentHeight || null == e.lastContentY || null == e.maxContentY) return {};
        var t = e.maxScrollHeight,
          n = e.lastScrollY,
          r = e.maxScrollY,
          i = e.maxContentHeight,
          o = e.lastContentY,
          s = e.maxContentY;
        return t = Math.ceil(t), n = Math.ceil(n), r = Math.ceil(r), i = Math.ceil(i), o = Math.ceil(o), s = Math.ceil(s), {
          $prev_pageview_last_scroll: n,
          $prev_pageview_last_scroll_percentage: t <= 1 ? 1 : lr(n / t, 0, 1),
          $prev_pageview_max_scroll: r,
          $prev_pageview_max_scroll_percentage: t <= 1 ? 1 : lr(r / t, 0, 1),
          $prev_pageview_last_content: o,
          $prev_pageview_last_content_percentage: i <= 1 ? 1 : lr(o / i, 0, 1),
          $prev_pageview_max_content: s,
          $prev_pageview_max_content_percentage: i <= 1 ? 1 : lr(s / i, 0, 1)
        };
      }
    }, {
      key: "startMeasuringScrollPosition",
      value: function () {
        null == $ || $.addEventListener("scroll", this._updateScrollData, !0), null == $ || $.addEventListener("scrollend", this._updateScrollData, !0), null == $ || $.addEventListener("resize", this._updateScrollData);
      }
    }, {
      key: "stopMeasuringScrollPosition",
      value: function () {
        null == $ || $.removeEventListener("scroll", this._updateScrollData), null == $ || $.removeEventListener("scrollend", this._updateScrollData), null == $ || $.removeEventListener("resize", this._updateScrollData);
      }
    }, {
      key: "_scrollElement",
      value: function () {
        if (!this._instance.config.scroll_root_selector) return null == $ ? void 0 : $.document.documentElement;
        var e,
          t = v(S(this._instance.config.scroll_root_selector) ? this._instance.config.scroll_root_selector : [this._instance.config.scroll_root_selector]);
        try {
          for (t.s(); !(e = t.n()).done;) {
            var n = e.value,
              r = null == $ ? void 0 : $.document.querySelector(n);
            if (r) return r;
          }
        } catch (e) {
          t.e(e);
        } finally {
          t.f();
        }
      }
    }, {
      key: "_scrollHeight",
      value: function () {
        var e = this._scrollElement();
        return e ? Math.max(0, e.scrollHeight - e.clientHeight) : 0;
      }
    }, {
      key: "_scrollY",
      value: function () {
        if (this._instance.config.scroll_root_selector) {
          var e = this._scrollElement();
          return e && e.scrollTop || 0;
        }
        return $ && ($.scrollY || $.pageYOffset || $.document.documentElement.scrollTop) || 0;
      }
    }, {
      key: "_contentHeight",
      value: function () {
        var e = this._scrollElement();
        return (null == e ? void 0 : e.scrollHeight) || 0;
      }
    }, {
      key: "_contentY",
      value: function () {
        var e = this._scrollElement(),
          t = (null == e ? void 0 : e.clientHeight) || 0;
        return this._scrollY() + t;
      }
    }]), e;
  }();
function lr(e, t, n) {
  return Math.max(t, Math.min(e, n));
}
var cr,
  dr,
  hr,
  fr = {
    icontains: function (e) {
      return !!$ && $.location.href.toLowerCase().indexOf(e.toLowerCase()) > -1;
    },
    regex: function (e) {
      return !!$ && Rt($.location.href, e);
    },
    exact: function (e) {
      return (null == $ ? void 0 : $.location.href) === e;
    }
  },
  pr = function () {
    function e(t) {
      r(this, e), this.instance = t;
    }
    return o(e, [{
      key: "getSurveys",
      value: function (e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
          r = this.instance.get_property(Ue);
        if (r && !n) return e(r);
        this.instance._send_request(this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)), {}, {
          method: "GET"
        }, function (n) {
          var r,
            i = n.surveys || [];
          return null === (r = t.instance.persistence) || void 0 === r || r.register(s({}, Ue, i)), e(i);
        });
      }
    }, {
      key: "getActiveMatchingSurveys",
      value: function (e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.getSurveys(function (n) {
          var r = n.filter(function (e) {
            return !(!e.start_date || e.end_date);
          }).filter(function (e) {
            var t, n, r, i;
            if (!e.conditions) return !0;
            var o = null === (t = e.conditions) || void 0 === t || !t.url || fr[null !== (n = null === (r = e.conditions) || void 0 === r ? void 0 : r.urlMatchType) && void 0 !== n ? n : "icontains"](e.conditions.url),
              s = null === (i = e.conditions) || void 0 === i || !i.selector || (null == B ? void 0 : B.querySelector(e.conditions.selector));
            return o && s;
          }).filter(function (e) {
            if (!e.linked_flag_key && !e.targeting_flag_key) return !0;
            var n = !e.linked_flag_key || t.instance.featureFlags.isFeatureEnabled(e.linked_flag_key),
              r = !e.targeting_flag_key || t.instance.featureFlags.isFeatureEnabled(e.targeting_flag_key);
            return n && r;
          });
          return e(r);
        }, n);
      }
    }]), e;
  }(),
  _r = function () {
    function e() {
      var t = this;
      r(this, e), s(this, "limits", {}), s(this, "checkForLimiting", function (e) {
        var n = e.responseText;
        if (n && n.length) try {
          (JSON.parse(n).quota_limited || []).forEach(function (e) {
            z.info("[RateLimiter] ".concat(e || "events", " is quota limited.")), t.limits[e] = new Date().getTime() + 6e4;
          });
        } catch (e) {
          return void z.warn('[RateLimiter] could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
            text: n
          });
        }
      });
    }
    return o(e, [{
      key: "isRateLimited",
      value: function (e) {
        var t = this.limits[e || "events"] || !1;
        return !1 !== t && new Date().getTime() < t;
      }
    }]), e;
  }(),
  gr = function () {
    return t({
      initialPathName: (null == N ? void 0 : N.pathname) || "",
      referringDomain: Ct.referringDomain()
    }, Ct.campaignParams());
  },
  vr = function () {
    function e(t, n, i) {
      var o = this;
      r(this, e), s(this, "_onSessionIdCallback", function (e) {
        var t = o._getStoredProps();
        if (!t || t.sessionId !== e) {
          var n = {
            sessionId: e,
            props: o._sessionSourceParamGenerator()
          };
          o._persistence.register(s({}, Ge, n));
        }
      }), this._sessionIdManager = t, this._persistence = n, this._sessionSourceParamGenerator = i || gr, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
    }
    return o(e, [{
      key: "_getStoredProps",
      value: function () {
        return this._persistence.props[Ge];
      }
    }, {
      key: "getSessionProps",
      value: function () {
        var e,
          t = null === (e = this._getStoredProps()) || void 0 === e ? void 0 : e.props;
        return t ? {
          $client_session_initial_referring_host: t.referringDomain,
          $client_session_initial_pathname: t.initialPathName,
          $client_session_initial_utm_source: t.utm_source,
          $client_session_initial_utm_campaign: t.utm_campaign,
          $client_session_initial_utm_medium: t.utm_medium,
          $client_session_initial_utm_content: t.utm_content,
          $client_session_initial_utm_term: t.utm_term
        } : {};
      }
    }]), e;
  }(),
  mr = ["ahrefsbot", "ahrefssiteaudit", "applebot", "baiduspider", "bingbot", "bingpreview", "bot.htm", "bot.php", "crawler", "duckduckbot", "facebookexternal", "facebookcatalog", "gptbot", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "semrushbot", "sitebulb", "slurp", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google"],
  yr = function (e, t) {
    if (!e) return !1;
    var n = e.toLowerCase();
    return mr.concat(t || []).some(function (e) {
      var t = e.toLowerCase();
      return n.includes ? n.includes(t) : -1 !== n.indexOf(t);
    });
  };
!function (e) {
  e[e.INIT_MODULE = 0] = "INIT_MODULE", e[e.INIT_SNIPPET = 1] = "INIT_SNIPPET";
}(cr || (cr = {}));
var br = function () {},
  kr = {},
  wr = "posthog",
  Sr = !Ft && -1 === (null == U ? void 0 : U.indexOf("MSIE")) && -1 === (null == U ? void 0 : U.indexOf("Mozilla")),
  Fr = function () {
    var e, t, n;
    return {
      api_host: "https://app.posthog.com",
      api_method: "POST",
      api_transport: "XHR",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: (t = null == B ? void 0 : B.location, n = null == t ? void 0 : t.hostname, !!E(n) && "herokuapp.com" !== n.split(".").slice(-2).join(".")),
      persistence: "localStorage+cookie",
      persistence_name: "",
      cookie_name: "",
      loaded: br,
      store_google: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: !0,
      debug: !1,
      verbose: !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_cookie: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == $ || null === (e = $.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_blacklist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      inapp_protocol: "//",
      inapp_link_new_window: !1,
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      on_request_error: function (e) {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.responseText;
        z.error(t);
      },
      get_device_id: function (e) {
        return e;
      },
      _onCapture: br,
      capture_performance: void 0,
      name: "posthog",
      callback_fn: "posthog._jsc",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800
    };
  },
  Pr = function (e, t, n, r) {
    var i,
      o = n !== wr && hr ? n ? hr[n] : void 0 : hr,
      s = {
        initComplete: !1,
        syncCode: !1
      },
      a = function (e) {
        return function (t) {
          s[e] || (s[e] = !0, s.initComplete && s.syncCode && (null == r || r(t)));
        };
      };
    if (o && dr === cr.INIT_MODULE) i = o;else {
      if (o && !S(o)) return void z.error("You have already initialized " + n);
      i = new Ir();
    }
    if (i._init(e, t, n, a("initComplete")), i.toolbar.maybeLoadToolbar(), i.sessionRecording = new on(i), i.sessionRecording.startRecordingIfEnabled(), i.config.disable_scroll_properties || i.pageViewManager.startMeasuringScrollPosition(), i.__autocapture = i.config.autocapture, Je._setIsAutocaptureEnabled(i), Je._isAutocaptureEnabled) {
      i.__autocapture = i.config.autocapture;
      Je.enabledForProject(i.config.token, 100, 100) ? Je.isBrowserSupported() ? Je.init(i) : (i.__autocapture = !1, z.info("Disabling Automatic Event Collection because this browser is not supported")) : (i.__autocapture = !1, z.info("Not in active bucket: disabling Automatic Event Collection."));
    }
    return m.DEBUG = m.DEBUG || i.config.debug, !I(o) && S(o) && (i._execute_array.call(i.people, o.people), i._execute_array(o)), a("syncCode")(i), i;
  },
  Rr = function () {
    function e() {
      r(this, e), s(this, "__forceAllowLocalhost", !1);
    }
    return o(e, [{
      key: "_forceAllowLocalhost",
      get: function () {
        return this.__forceAllowLocalhost;
      },
      set: function (e) {
        z.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
      }
    }]), e;
  }(),
  Ir = function () {
    function e() {
      var t = this;
      r(this, e), s(this, "webPerformance", new Rr()), this.config = Fr(), this.compression = {}, this.decideEndpointWasHit = !1, this.SentryIntegration = ar, this.segmentIntegration = function () {
        return function (e) {
          Promise && Promise.resolve || z.warn("This browser does not have Promise support, and can not use the segment integration");
          var t = function (t, n) {
            t.event.userId || t.event.anonymousId === e.get_distinct_id() || e.reset(), t.event.userId && t.event.userId !== e.get_distinct_id() && (e.register({
              distinct_id: t.event.userId
            }), e.reloadFeatureFlags());
            var r = e._calculate_event_properties(n, t.event.properties);
            return t.event.properties = Object.assign({}, r, t.event.properties), t;
          };
          return {
            name: "PostHog JS",
            type: "enrichment",
            version: "1.0.0",
            isLoaded: function () {
              return !0;
            },
            load: function () {
              return Promise.resolve();
            },
            track: function (e) {
              return t(e, e.event.event);
            },
            page: function (e) {
              return t(e, "$pageview");
            },
            identify: function (e) {
              return t(e, "$identify");
            },
            screen: function (e) {
              return t(e, "$screen");
            }
          };
        }(t);
      }, this.__captureHooks = [], this.__request_queue = [], this.__loaded = !1, this.__loaded_recorder_version = void 0, this.__autocapture = void 0, this._jsc = function () {}, this.analyticsDefaultEndpoint = "/e/", this.elementsChainAsString = !1, this.featureFlags = new tt(this), this.toolbar = new ln(this), this.pageViewManager = new ur(this), this.surveys = new pr(this), this.rateLimiter = new _r(), this.requestRouter = new sr(this), this.people = {
        set: function (e, n, r) {
          var i = E(e) ? s({}, e, n) : e;
          t.setPersonProperties(i), null == r || r({});
        },
        set_once: function (e, n, r) {
          var i = E(e) ? s({}, e, n) : e;
          t.setPersonProperties(void 0, i), null == r || r({});
        }
      };
    }
    return o(e, [{
      key: "init",
      value: function (e, t, n) {
        if (I(n)) z.critical("You must name your new library: init(token, config, name)");else if (I(e) || function (e) {
          return E(e) && 0 === e.trim().length;
        }(e)) z.critical("You must provide a token to initialize posthog");else {
          if (n !== wr) {
            var r = Pr(e, t, n, function (e) {
              hr[n] = e, e._loaded();
            });
            return hr[n] = r, r;
          }
          z.critical("You must initialize the main posthog object right after you include the PostHog js snippet");
        }
      }
    }, {
      key: "_init",
      value: function (e) {
        var n,
          r,
          i,
          o = this,
          s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
          a = arguments.length > 2 ? arguments[2] : void 0,
          u = arguments.length > 3 ? arguments[3] : void 0;
        this.__loaded = !0, this.config = {}, this._triggered_notifs = [];
        var l,
          c = {
            segmentRegister: !1,
            syncCode: !1
          },
          d = function (e) {
            return function () {
              c[e] || (c[e] = !0, c.segmentRegister && c.syncCode && (null == u || u(o)));
            };
          };
        (s.request_headers = s.request_headers || s.xhr_headers, this.set_config(K({}, Fr(), s, {
          name: a,
          token: e,
          callback_fn: (a === wr ? a : wr + "." + a) + "._jsc"
        })), this._jsc = function () {}, null != $ && null !== (n = $.rrweb) && void 0 !== n && n.record || null != $ && $.rrwebRecord) && (this.__loaded_recorder_version = null == $ || null === (l = $.rrweb) || void 0 === l ? void 0 : l.version);
        if (this.persistence = new Tt(this.config), this._requestQueue = new dn(this._handle_queued_event.bind(this)), this._retryQueue = new rr(this.config.on_request_error, this.rateLimiter), this.__captureHooks = [], this.__request_queue = [], this.sessionManager = new or(this.config, this.persistence), this.sessionPropsManager = new vr(this.sessionManager, this.persistence), this.sessionPersistence = "sessionStorage" === this.config.persistence ? this.persistence : new Tt(t(t({}, this.config), {}, {
          persistence: "sessionStorage"
        })), this._gdpr_init(), s.segment ? (this.config.get_device_id = function () {
          return s.segment.user().anonymousId();
        }, s.segment.user().id() && (this.register({
          distinct_id: s.segment.user().id()
        }), this.persistence.set_user_state("identified")), s.segment.register(this.segmentIntegration()).then(d("segmentRegister"))) : d("segmentRegister")(), void 0 !== (null === (r = s.bootstrap) || void 0 === r ? void 0 : r.distinctID)) {
          var h,
            f,
            p = this.config.get_device_id(ut()),
            _ = null !== (h = s.bootstrap) && void 0 !== h && h.isIdentifiedID ? p : s.bootstrap.distinctID;
          this.persistence.set_user_state(null !== (f = s.bootstrap) && void 0 !== f && f.isIdentifiedID ? "identified" : "anonymous"), this.register({
            distinct_id: s.bootstrap.distinctID,
            $device_id: _
          });
        }
        if (this._hasBootstrappedFeatureFlags()) {
          var g,
            v,
            m = Object.keys((null === (g = s.bootstrap) || void 0 === g ? void 0 : g.featureFlags) || {}).filter(function (e) {
              var t, n;
              return !(null === (t = s.bootstrap) || void 0 === t || null === (n = t.featureFlags) || void 0 === n || !n[e]);
            }).reduce(function (e, t) {
              var n, r;
              return e[t] = (null === (n = s.bootstrap) || void 0 === n || null === (r = n.featureFlags) || void 0 === r ? void 0 : r[t]) || !1, e;
            }, {}),
            y = Object.keys((null === (v = s.bootstrap) || void 0 === v ? void 0 : v.featureFlagPayloads) || {}).filter(function (e) {
              return m[e];
            }).reduce(function (e, t) {
              var n, r, i, o;
              null !== (n = s.bootstrap) && void 0 !== n && null !== (r = n.featureFlagPayloads) && void 0 !== r && r[t] && (e[t] = null === (i = s.bootstrap) || void 0 === i || null === (o = i.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
              return e;
            }, {});
          this.featureFlags.receivedFeatureFlags({
            featureFlags: m,
            featureFlagPayloads: y
          });
        }
        if (!this.get_distinct_id()) {
          var b = this.config.get_device_id(ut());
          this.register_once({
            distinct_id: b,
            $device_id: b
          }, ""), this.persistence.set_user_state("anonymous");
        }
        null == $ || null === (i = $.addEventListener) || void 0 === i || i.call($, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this)), d("syncCode")();
      }
    }, {
      key: "_afterDecideResponse",
      value: function (e) {
        var t;
        if (this.compression = {}, e.supportedCompression && !this.config.disable_compression) {
          var n,
            r = {},
            i = v(e.supportedCompression);
          try {
            for (i.s(); !(n = i.n()).done;) {
              r[n.value] = !0;
            }
          } catch (e) {
            i.e(e);
          } finally {
            i.f();
          }
          this.compression = r;
        }
        null !== (t = e.analytics) && void 0 !== t && t.endpoint && (this.analyticsDefaultEndpoint = e.analytics.endpoint), e.elementsChainAsString && (this.elementsChainAsString = e.elementsChainAsString), e.__preview_ingestion_endpoints && (this.config.__preview_ingestion_endpoints = e.__preview_ingestion_endpoints);
      }
    }, {
      key: "_loaded",
      value: function () {
        var e = this.config.advanced_disable_decide;
        e || this.featureFlags.setReloadingPaused(!0);
        try {
          this.config.loaded(this);
        } catch (e) {
          z.critical("`loaded` function failed", e);
        }
        this._start_queue_if_opted_in(), this.config.capture_pageview && B && this.capture("$pageview", {
          title: B.title
        }, {
          send_instantly: !0
        }), e || (new sn(this).call(), this.featureFlags.resetRequestQueue());
      }
    }, {
      key: "_start_queue_if_opted_in",
      value: function () {
        var e;
        this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.poll());
      }
    }, {
      key: "_dom_loaded",
      value: function () {
        var e = this;
        this.has_opted_out_capturing() || Y(this.__request_queue, function (t) {
          e._send_request.apply(e, p(t));
        }), this.__request_queue = [], this._start_queue_if_opted_in();
      }
    }, {
      key: "_prepare_callback",
      value: function (e, t) {
        if (I(e)) return null;
        if (Ft) return function (n) {
          e(n, t);
        };
        var n = this._jsc,
          r = "" + Math.floor(1e8 * Math.random()),
          i = this.config.callback_fn + "[" + r + "]";
        return n[r] = function (i) {
          delete n[r], e(i, t);
        }, i;
      }
    }, {
      key: "_handle_unload",
      value: function () {
        var e, t;
        this.config.request_batching ? (this.config.capture_pageview && this.config.capture_pageleave && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this.config.capture_pageview && this.config.capture_pageleave && this.capture("$pageleave", null, {
          transport: "sendBeacon"
        });
      }
    }, {
      key: "_handle_queued_event",
      value: function (e, t, n) {
        var r = JSON.stringify(t);
        this.__compress_and_send_json_request(e, r, n || kr, br);
      }
    }, {
      key: "__compress_and_send_json_request",
      value: function (e, n, r, i) {
        var o = function (e, n, r) {
            return e === xn.GZipJS ? [Jn(Kn(n), {
              mtime: 0
            }), t(t({}, r), {}, {
              blob: !0,
              urlQueryArgs: {
                compression: xn.GZipJS
              }
            })] : [{
              data: oe(n)
            }, r];
          }(this.compression[xn.GZipJS] ? xn.GZipJS : xn.Base64, n, r),
          s = f(o, 2),
          a = s[0],
          u = s[1];
        this._send_request(e, a, u, i);
      }
    }, {
      key: "_send_request",
      value: function (e, n, r, i) {
        if (this.__loaded && this._retryQueue && !this.rateLimiter.isRateLimited(r._batchKey)) if (Sr) this.__request_queue.push([e, n, r, i]);else {
          var o = {
            method: this.config.api_method,
            transport: this.config.api_transport,
            verbose: this.config.verbose
          };
          r = K(o, r || {}), Ft || (r.method = "GET");
          var s = $ && "sendBeacon" in $.navigator && "sendBeacon" === r.transport;
          if (e = Xn(e, r.urlQueryArgs || {}, {
            ip: this.config.ip
          }), s) try {
            null == $ || $.navigator.sendBeacon(e, Zn(n, t(t({}, r), {}, {
              sendBeacon: !0
            })));
          } catch (e) {} else if (Ft || !B) try {
            er({
              url: e,
              data: n,
              headers: this.config.request_headers,
              options: r,
              callback: i,
              retriesPerformedSoFar: 0,
              retryQueue: this._retryQueue,
              onError: this.config.on_request_error,
              onResponse: this.rateLimiter.checkForLimiting
            });
          } catch (e) {
            z.error(e);
          } else {
            var a,
              u = B.createElement("script");
            u.type = "text/javascript", u.async = !0, u.defer = !0, u.src = e;
            var l = B.getElementsByTagName("script")[0];
            null === (a = l.parentNode) || void 0 === a || a.insertBefore(u, l);
          }
        }
      }
    }, {
      key: "_execute_array",
      value: function (e) {
        var t,
          n = this,
          r = [],
          i = [],
          o = [];
        Y(e, function (e) {
          e && (t = e[0], S(t) ? o.push(e) : P(e) ? e.call(n) : S(e) && "alias" === t ? r.push(e) : S(e) && -1 !== t.indexOf("capture") && P(n[t]) ? o.push(e) : i.push(e));
        });
        var s = function (e, t) {
          Y(e, function (e) {
            if (S(e[0])) {
              var n = t;
              J(e, function (e) {
                n = n[e[0]].apply(n, e.slice(1));
              });
            } else this[e[0]].apply(this, e.slice(1));
          }, t);
        };
        s(r, this), s(i, this), s(o, this);
      }
    }, {
      key: "_hasBootstrappedFeatureFlags",
      value: function () {
        var e, t;
        return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
      }
    }, {
      key: "push",
      value: function (e) {
        this._execute_array([e]);
      }
    }, {
      key: "capture",
      value: function (e, n) {
        var r,
          i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : kr;
        if (!this.__loaded || !this.sessionPersistence || !this._requestQueue) return z.uninitializedWarning("posthog.capture");
        if (!Xt(this)) {
          var o = (i = i || kr).transport;
          if (o && (i.transport = o), !I(e) && E(e)) {
            if (!U || this.config.opt_out_useragent_filter || !yr(U, this.config.custom_blocked_useragents)) {
              this.sessionPersistence.update_search_keyword(), this.config.store_google && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info();
              var s = {
                uuid: ut(),
                event: e,
                properties: this._calculate_event_properties(e, n || {})
              };
              "$identify" === e && (s.$set = i.$set, s.$set_once = i.$set_once), (s = ie(s, i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = i.timestamp || new Date();
              var a = t(t({}, s.properties.$set), s.$set);
              (function (e) {
                if (R(e)) {
                  for (var t in e) if (k.call(e, t)) return !1;
                  return !0;
                }
                return !1;
              })(a) || this.setPersonPropertiesForFlags(a), z.info("send", s);
              var u = JSON.stringify(s),
                l = null !== (r = i._url) && void 0 !== r ? r : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
                c = i !== kr;
              return !this.config.request_batching || c && !i._batchKey || i.send_instantly ? this.__compress_and_send_json_request(l, u, i) : this._requestQueue.enqueue(l, s, i), this._invokeCaptureHooks(e, s), s;
            }
          } else z.error("No event name provided to posthog.capture");
        }
      }
    }, {
      key: "_addCaptureHook",
      value: function (e) {
        this.__captureHooks.push(e);
      }
    }, {
      key: "_invokeCaptureHooks",
      value: function (e, t) {
        this.config._onCapture(e, t), J(this.__captureHooks, function (t) {
          return t(e);
        });
      }
    }, {
      key: "_calculate_event_properties",
      value: function (e, n) {
        if (!this.persistence || !this.sessionPersistence) return n;
        var r = this.persistence.remove_event_timer(e),
          i = t({}, n);
        if (i.token = this.config.token, "$snapshot" === e) {
          var o = t(t({}, this.persistence.properties()), this.sessionPersistence.properties());
          return i.distinct_id = o.distinct_id, i;
        }
        var s = Ct.properties();
        if (this.sessionManager) {
          var a = this.sessionManager.checkAndGetSessionAndWindowId(),
            u = a.sessionId,
            l = a.windowId;
          i.$session_id = u, i.$window_id = l;
        }
        if (this.sessionPropsManager && this.config.__preview_send_client_session_params && ("$pageview" === e || "$pageleave" === e || "$autocapture" === e)) {
          var c = this.sessionPropsManager.getSessionProps();
          i = K(i, c);
        }
        if (!this.config.disable_scroll_properties) {
          var d = {};
          "$pageview" === e ? d = this.pageViewManager.doPageView() : "$pageleave" === e && (d = this.pageViewManager.doPageLeave()), i = K(i, d);
        }
        if ("$pageview" === e && B && (i.title = B.title), "$performance_event" === e) {
          var h = this.persistence.properties();
          return i.distinct_id = h.distinct_id, i.$current_url = s.$current_url, i;
        }
        if (!I(r)) {
          var f = new Date().getTime() - r;
          i.$duration = parseFloat((f / 1e3).toFixed(3));
        }
        U && this.config.opt_out_useragent_filter && (i.$browser_type = yr(U, this.config.custom_blocked_useragents) ? "bot" : "browser"), i = K({}, Ct.properties(), this.persistence.properties(), this.sessionPersistence.properties(), i);
        var p = this.config.property_blacklist;
        S(p) ? J(p, function (e) {
          delete i[e];
        }) : z.error("Invalid value for property_blacklist config: " + p);
        var _ = this.config.sanitize_properties;
        return _ && (i = _(i, e)), i;
      }
    }, {
      key: "register",
      value: function (e, t) {
        var n;
        null === (n = this.persistence) || void 0 === n || n.register(e, t);
      }
    }, {
      key: "register_once",
      value: function (e, t, n) {
        var r;
        null === (r = this.persistence) || void 0 === r || r.register_once(e, t, n);
      }
    }, {
      key: "register_for_session",
      value: function (e) {
        var t;
        null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
      }
    }, {
      key: "unregister",
      value: function (e) {
        var t;
        null === (t = this.persistence) || void 0 === t || t.unregister(e);
      }
    }, {
      key: "unregister_for_session",
      value: function (e) {
        var t;
        null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
      }
    }, {
      key: "_register_single",
      value: function (e, t) {
        this.register(s({}, e, t));
      }
    }, {
      key: "getFeatureFlag",
      value: function (e, t) {
        return this.featureFlags.getFeatureFlag(e, t);
      }
    }, {
      key: "getFeatureFlagPayload",
      value: function (e) {
        var t = this.featureFlags.getFeatureFlagPayload(e);
        try {
          return JSON.parse(t);
        } catch (e) {
          return t;
        }
      }
    }, {
      key: "isFeatureEnabled",
      value: function (e, t) {
        return this.featureFlags.isFeatureEnabled(e, t);
      }
    }, {
      key: "reloadFeatureFlags",
      value: function () {
        this.featureFlags.reloadFeatureFlags();
      }
    }, {
      key: "updateEarlyAccessFeatureEnrollment",
      value: function (e, t) {
        this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
      }
    }, {
      key: "getEarlyAccessFeatures",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        return this.featureFlags.getEarlyAccessFeatures(e, t);
      }
    }, {
      key: "onFeatureFlags",
      value: function (e) {
        return this.featureFlags.onFeatureFlags(e);
      }
    }, {
      key: "onSessionId",
      value: function (e) {
        var t, n;
        return null !== (t = null === (n = this.sessionManager) || void 0 === n ? void 0 : n.onSessionId(e)) && void 0 !== t ? t : function () {};
      }
    }, {
      key: "getSurveys",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.surveys.getSurveys(e, t);
      }
    }, {
      key: "getActiveMatchingSurveys",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.surveys.getActiveMatchingSurveys(e, t);
      }
    }, {
      key: "identify",
      value: function (e, t, n) {
        if (!this.__loaded || !this.persistence) return z.uninitializedWarning("posthog.identify");
        if (C(e) && (e = e.toString(), z.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
          if (["distinct_id", "distinctid"].includes(e.toLowerCase())) z.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else {
            var r = this.get_distinct_id();
            if (this.register({
              $user_id: e
            }), !this.get_property("$device_id")) {
              var i = r;
              this.register_once({
                $had_persisted_distinct_id: !0,
                $device_id: i
              }, "");
            }
            e !== r && e !== this.get_property(xe) && (this.unregister(xe), this.register({
              distinct_id: e
            }));
            var o = "anonymous" === this.persistence.get_user_state();
            e !== r && o ? (this.persistence.set_user_state("identified"), this.setPersonPropertiesForFlags(t || {}, !1), this.capture("$identify", {
              distinct_id: e,
              $anon_distinct_id: r
            }, {
              $set: t || {},
              $set_once: n || {}
            }), this.featureFlags.setAnonymousDistinctId(r)) : (t || n) && this.setPersonProperties(t, n), e !== r && (this.reloadFeatureFlags(), this.unregister(We));
          }
        } else z.error("Unique user id has not been set in posthog.identify");
      }
    }, {
      key: "setPersonProperties",
      value: function (e, t) {
        (e || t) && (this.setPersonPropertiesForFlags(e || {}), this.capture("$set", {
          $set: e || {},
          $set_once: t || {}
        }));
      }
    }, {
      key: "group",
      value: function (e, n, r) {
        if (e && n) {
          var i = this.getGroups();
          i[e] !== n && this.resetGroupPropertiesForFlags(e), this.register({
            $groups: t(t({}, i), {}, s({}, e, n))
          }), r && (this.capture("$groupidentify", {
            $group_type: e,
            $group_key: n,
            $group_set: r
          }), this.setGroupPropertiesForFlags(s({}, e, r))), i[e] === n || r || this.reloadFeatureFlags();
        } else z.error("posthog.group requires a group type and group key");
      }
    }, {
      key: "resetGroups",
      value: function () {
        this.register({
          $groups: {}
        }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
      }
    }, {
      key: "setPersonPropertiesForFlags",
      value: function (e) {
        var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
        this.featureFlags.setPersonPropertiesForFlags(e, t);
      }
    }, {
      key: "resetPersonPropertiesForFlags",
      value: function () {
        this.featureFlags.resetPersonPropertiesForFlags();
      }
    }, {
      key: "setGroupPropertiesForFlags",
      value: function (e) {
        var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
        this.featureFlags.setGroupPropertiesForFlags(e, t);
      }
    }, {
      key: "resetGroupPropertiesForFlags",
      value: function (e) {
        this.featureFlags.resetGroupPropertiesForFlags(e);
      }
    }, {
      key: "reset",
      value: function (e) {
        var t, n, r, i;
        if (!this.__loaded) return z.uninitializedWarning("posthog.reset");
        var o = this.get_property("$device_id");
        null === (t = this.persistence) || void 0 === t || t.clear(), null === (n = this.sessionPersistence) || void 0 === n || n.clear(), null === (r = this.persistence) || void 0 === r || r.set_user_state("anonymous"), null === (i = this.sessionManager) || void 0 === i || i.resetSessionId();
        var s = this.config.get_device_id(ut());
        this.register_once({
          distinct_id: s,
          $device_id: e ? s : o
        }, "");
      }
    }, {
      key: "get_distinct_id",
      value: function () {
        return this.get_property("distinct_id");
      }
    }, {
      key: "getGroups",
      value: function () {
        return this.get_property("$groups") || {};
      }
    }, {
      key: "get_session_id",
      value: function () {
        var e, t;
        return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
      }
    }, {
      key: "get_session_replay_url",
      value: function (e) {
        if (!this.sessionManager) return "";
        var t = this.sessionManager.checkAndGetSessionAndWindowId(!0),
          n = t.sessionId,
          r = t.sessionStartTimestamp,
          i = this.requestRouter.endpointFor("ui", "/replay/" + n);
        if (null != e && e.withTimestamp && r) {
          var o,
            s = null !== (o = e.timestampLookBack) && void 0 !== o ? o : 10;
          if (!r) return i;
          var a = Math.max(Math.floor((new Date().getTime() - r) / 1e3) - s, 0);
          i += "?t=".concat(a);
        }
        return i;
      }
    }, {
      key: "alias",
      value: function (e, t) {
        return e === this.get_property(Ee) ? (z.critical("Attempting to create alias for existing People user - aborting."), -2) : (I(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(xe, e), this.capture("$create_alias", {
          alias: e,
          distinct_id: t
        })) : (z.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1));
      }
    }, {
      key: "set_config",
      value: function (e) {
        var n,
          r,
          i = t({}, this.config);
        if (R(e) && (K(this.config, e), this.config.persistence_name || (this.config.persistence_name = this.config.cookie_name), this.config.disable_persistence || (this.config.disable_persistence = this.config.disable_cookie), null === (n = this.persistence) || void 0 === n || n.update_config(this.config), null === (r = this.sessionPersistence) || void 0 === r || r.update_config(this.config), gt.is_supported() && "true" === gt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (m.DEBUG = !0), this.sessionRecording && !I(e.disable_session_recording))) {
          var o = i.disable_session_recording !== e.disable_session_recording,
            s = !Xt(this) && !e.disable_session_recording && !this.sessionRecording.started;
          (o || s) && (e.disable_session_recording ? this.sessionRecording.stopRecording() : this.sessionRecording.startRecordingIfEnabled());
        }
      }
    }, {
      key: "startSessionRecording",
      value: function () {
        this.set_config({
          disable_session_recording: !1
        });
      }
    }, {
      key: "stopSessionRecording",
      value: function () {
        this.set_config({
          disable_session_recording: !0
        });
      }
    }, {
      key: "sessionRecordingStarted",
      value: function () {
        var e;
        return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
      }
    }, {
      key: "loadToolbar",
      value: function (e) {
        return this.toolbar.loadToolbar(e);
      }
    }, {
      key: "get_property",
      value: function (e) {
        var t;
        return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
      }
    }, {
      key: "getSessionProperty",
      value: function (e) {
        var t;
        return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
      }
    }, {
      key: "toString",
      value: function () {
        var e,
          t = null !== (e = this.config.name) && void 0 !== e ? e : wr;
        return t !== wr && (t = wr + "." + t), t;
      }
    }, {
      key: "_gdpr_init",
      value: function () {
        "localStorage" === this.config.opt_out_capturing_persistence_type && gt.is_supported() && (!this.has_opted_in_capturing() && this.has_opted_in_capturing({
          persistence_type: "cookie"
        }) && this.opt_in_capturing({
          enable_persistence: !1
        }), !this.has_opted_out_capturing() && this.has_opted_out_capturing({
          persistence_type: "cookie"
        }) && this.opt_out_capturing({
          clear_persistence: !1
        }), this.clear_opt_in_out_capturing({
          persistence_type: "cookie",
          enable_persistence: !1
        })), this.has_opted_out_capturing() ? this._gdpr_update_persistence({
          clear_persistence: !0
        }) : this.has_opted_in_capturing() || !this.config.opt_out_capturing_by_default && !pt.get("ph_optout") || (pt.remove("ph_optout"), this.opt_out_capturing({
          clear_persistence: this.config.opt_out_persistence_by_default
        }));
      }
    }, {
      key: "_gdpr_update_persistence",
      value: function (e) {
        var t, n, r, i, o;
        if (e && e.clear_persistence) r = !0;else {
          if (!e || !e.enable_persistence) return;
          r = !1;
        }
        this.config.disable_persistence || (null === (t = this.persistence) || void 0 === t ? void 0 : t.disabled) === r || null === (i = this.persistence) || void 0 === i || i.set_disabled(r);
        this.config.disable_persistence || (null === (n = this.sessionPersistence) || void 0 === n ? void 0 : n.disabled) === r || null === (o = this.sessionPersistence) || void 0 === o || o.set_disabled(r);
      }
    }, {
      key: "_gdpr_call_func",
      value: function (e, t) {
        return t = K({
          capture: this.capture.bind(this),
          persistence_type: this.config.opt_out_capturing_persistence_type,
          cookie_prefix: this.config.opt_out_capturing_cookie_prefix,
          cookie_expiration: this.config.cookie_expiration,
          cross_subdomain_cookie: this.config.cross_subdomain_cookie,
          secure_cookie: this.config.secure_cookie
        }, t || {}), gt.is_supported() || "localStorage" !== t.persistence_type || (t.persistence_type = "cookie"), e(this.config.token, {
          capture: t.capture,
          captureEventName: t.capture_event_name,
          captureProperties: t.capture_properties,
          persistenceType: t.persistence_type,
          persistencePrefix: t.cookie_prefix,
          cookieExpiration: t.cookie_expiration,
          crossSubdomainCookie: t.cross_subdomain_cookie,
          secureCookie: t.secure_cookie
        });
      }
    }, {
      key: "opt_in_capturing",
      value: function (e) {
        e = K({
          enable_persistence: !0
        }, e || {}), this._gdpr_call_func(Ut, e), this._gdpr_update_persistence(e);
      }
    }, {
      key: "opt_out_capturing",
      value: function (e) {
        var t = K({
          clear_persistence: !0
        }, e || {});
        this._gdpr_call_func(Wt, t), this._gdpr_update_persistence(t);
      }
    }, {
      key: "has_opted_in_capturing",
      value: function (e) {
        return this._gdpr_call_func(Vt, e);
      }
    }, {
      key: "has_opted_out_capturing",
      value: function (e) {
        return this._gdpr_call_func(zt, e);
      }
    }, {
      key: "clear_opt_in_out_capturing",
      value: function (e) {
        var t = K({
          enable_persistence: !0
        }, null != e ? e : {});
        this._gdpr_call_func(Gt, t), this._gdpr_update_persistence(t);
      }
    }, {
      key: "debug",
      value: function (e) {
        !1 === e ? (null == $ || $.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
          debug: !1
        })) : (null == $ || $.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
          debug: !0
        }));
      }
    }]), e;
  }();
!function (e, t) {
  for (var n = 0; n < t.length; n++) e.prototype[t[n]] = ne(e.prototype[t[n]]);
}(Ir, ["identify"]);
var Er,
  xr,
  Cr = {},
  Or = function () {
    hr.init = function (e, t, n) {
      if (n) return hr[n] || (hr[n] = Cr[n] = Pr(e || "", t || {}, n, function (e) {
        hr[n] = Cr[n] = e, e._loaded();
      })), hr[n];
      var r = hr;
      return Cr[wr] ? r = Cr[wr] : e && (r = Pr(e, t || {}, wr, function (e) {
        Cr[wr] = e, e._loaded();
      }), Cr[wr] = r), hr = r, dr === cr.INIT_SNIPPET && (W[wr] = hr), J(Cr, function (e, t) {
        t !== wr && (hr[t] = e);
      }), r;
    };
  };
!function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(Er || (Er = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(xr || (xr = {}));
var Tr = (dr = cr.INIT_MODULE, hr = new Ir(), Or(), hr.init(), function () {
  function e() {
    e.done || (e.done = !0, Sr = !1, J(Cr, function (e) {
      e._dom_loaded();
    }));
  }
  null != B && B.addEventListener && ("complete" === B.readyState ? e() : B.addEventListener("DOMContentLoaded", e, !1)), $ && ae($, "load", e, !0);
}(), hr);
export { xn as Compression, Ir as PostHog, xr as SurveyQuestionType, Er as SurveyType, Tr as default, Tr as posthog };
