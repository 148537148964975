<div class="column education-wrapper" style="gap: 12px">
    <!-- Education -->
    <ng-container *ngIf="educationType && educationType.length > 0">
        <div class="column">
            <p class="mid-header">Ausbildung:</p>
            <p>{{ educationString }}</p>
        </div>
    </ng-container>

    <!-- Further Education -->
    <ng-container *ngIf="continuingEducation && continuingEducation.length > 0">
        <div class="column">
            <p class="mid-header">Weiterbildung:</p>
            <p>{{ continuingEducationString }}</p>
        </div>
    </ng-container>

    <!-- Work Experience -->
    <ng-container *ngIf="workExperience && workExperience.length > 0">
        <div class="column">
            <p class="mid-header">Berufserfahrung:</p>
            <p *ngFor="let experience of workExperience; let i = index">
                {{ experience?.areaOfExperience || "" }} min
                {{ experience?.yearsOfExperience }} Jahre
            </p>
        </div>
    </ng-container>

    <!-- Drivers' License -->
    <ng-container *ngIf="drivingLicenseString">
        <div class="column">
            <p class="mid-header">Führerschein:</p>
            <p>{{ drivingLicenseString }}</p>
        </div>
    </ng-container>

    <!-- Other Requirements -->
    <ng-container *ngIf="otherRequirements">
        <div class="column">
            <p class="mid-header">Andere:</p>
            <div [innerHTML]="otherRequirements"></div>
        </div>
    </ng-container>
</div>
