<button
    (click)="openLink()"
    mat-raised-button
    class="custom-green-btn"
    style="padding: 12px 12px 12px 12px"
>
    <div class="row-center" style="padding: 12px 12px 12px 12px">
        <img src="assets/images/whatsapp.svg" height="20" width="20" />
        <p>
            Kontaktiere uns<br />
            auf WhatsApp
        </p>
    </div>
</button>
