import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterWhatsappButtonComponent } from "./footer-whatsapp-button/footer-whatsapp-button.component";

@Component({
    selector: "app-footer",
    standalone: true,
    imports: [CommonModule, FooterWhatsappButtonComponent],
    templateUrl: "./footer.component.html",
    styleUrl: "./footer.component.scss",
})
export class FooterComponent {
    @Input() showWhatsappButton: boolean = false;
}
