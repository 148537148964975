import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "app-somethingwrong",
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: "./somethingwrong.component.html",
    styleUrl: "./somethingwrong.component.scss",
})
export class SomethingwrongComponent {}
