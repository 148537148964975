import { isDevMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import "hammerjs";
import { environment } from "src/environments/environment";
import { AppModule } from "./app/app.module";

if (environment.production) {
    Sentry.init({
        dsn: environment.app.sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: isDevMode() ? 1.0 : 0.05,
        replaysSessionSampleRate: isDevMode() ? 0.1 : 0.005,
        replaysOnErrorSampleRate: isDevMode() ? 1.0 : 0.1,
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: any) => console.error(err));
