import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { getAvailableSize } from "src/shared/util/utilFunctions";

const ratio = 0.5625;
@Component({
    selector: "app-video-embed",
    standalone: true,
    imports: [CommonModule, YouTubePlayerModule],
    templateUrl: "./video-embed.component.html",
    styleUrl: "./video-embed.component.scss",
})
export class VideoEmbedComponent implements AfterViewInit {
    @ViewChild("youTubePlayer") youTubePlayer!: ElementRef<HTMLDivElement>;
    @Input() youtubeVideoId!: string;
    @Input() isYoutube!: boolean;
    showYouTubePlayer: boolean = false;

    videoHeight: number = 300 * ratio;
    videoWidth: number = 300;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showYouTubePlayer = true;
            this.onResize();
        }, 500); //
        this.onResize();
        window.addEventListener("resize", this.onResize.bind(this));
    }

    onResize(): void {
        const relevantWidth = getAvailableSize().relevantWidth;
        // margin of content and padding of card
        this.videoWidth = relevantWidth - 4 * 16;
        // 16:9 aspect ratio
        this.videoHeight = this.videoWidth * 0.5625;
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit() {}
}
