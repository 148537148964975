import { Component, HostListener, ViewChild } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { GroupedMatch, Match, MatchApplier } from "../../model";
import { MatchCardComponent } from "../match/match-card/match-card.component";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { UserService } from "src/shared/services/user.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTabsModule, MatTabGroup } from "@angular/material/tabs";
import { HammerModule, Meta } from "@angular/platform-browser";
import { ApplicantProfileAvatarComponent } from "../applicant/applicant-profile-avatar/applicant-profile-avatar.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { SomethingwrongComponent } from "src/app/modules/somethingwrong/somethingwrong.component";
import { HelpButtonComponent } from "../help/help-button/help-button.component";
import { MatchAppliedCardComponent } from "src/app/modules/match/match-applied/match-applied-card/match-applied-card.component";
import { MatchListHintComponent } from "../match-list-hint/match-list-hint.component";
import { FooterComponent } from "../footer/footer.component";
import { MatchListHintTopComponent } from "../match-list-hint/match-list-hint-top/match-list-hint-top.component";
import { ExplainDialogService } from "src/shared/services/explain-dialog.service";
import { AnalyticsService } from "src/shared/services/analytics.service";
import {
    MatBottomSheet,
    MatBottomSheetModule,
} from "@angular/material/bottom-sheet";
import { HomeListFilterComponent } from "src/app/modules/home/home-list-filter/home-list-filter.component";
import { ListFilterService } from "src/shared/services/list-filter.service";
import { MatchListService } from "src/shared/services/match-list.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-home",
    standalone: true,
    imports: [
        CommonModule,
        MatchCardComponent,
        RouterModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule,
        SomethingwrongComponent,
        HammerModule,
        ApplicantProfileAvatarComponent,
        MatIconModule,
        MatButtonModule,
        HelpButtonComponent,
        MatchAppliedCardComponent,
        MatchListHintComponent,
        FooterComponent,
        MatchListHintTopComponent,
        MatBottomSheetModule,
    ],
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
    @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
    @ViewChild(HelpButtonComponent) helpButtonComponent!: HelpButtonComponent;

    applierExtId: string | null = null;
    isLoading = false;
    topMatchesList: GroupedMatch[] = [];
    alternativesList: GroupedMatch[] = [];
    appliedList: Match[] = [];
    hasError: boolean = false;
    matchApplier: MatchApplier | null = null;

    currentTab: number = 0;
    allMatches: Match[] = [];

    subscription!: Subscription;

    readonly tabsMax: number = 1;

    onTabChange(event: any) {
        // handle the tab change
        var nextIndex = event.index;
        this.currentTab = nextIndex;

        // Get the current URL without the query parameters
        let pathWithoutQueryParams = this.router.url.split("?")[0];

        // Replace the current state with the new tabId query parameter
        this.location.replaceState(
            pathWithoutQueryParams,
            `tabId=${nextIndex}`
        );
    }

    constructor(
        protected userService: UserService,
        private route: ActivatedRoute,
        private meta: Meta,
        private router: Router,
        private location: Location,
        private explainDialogService: ExplainDialogService,
        private analyticsService: AnalyticsService,
        private bottomSheet: MatBottomSheet,
        private matchListService: MatchListService,
        private filterService: ListFilterService
    ) { }

    ngOnInit() {
        var tabId = Number(this.route.snapshot.queryParams["tabId"]);
        if (tabId && tabId >= 0 && tabId <= this.tabsMax) {
            this.currentTab = tabId;
        }
        this.meta.removeTag("theme-color");
        this.meta.updateTag({
            name: "theme-color",
            content: "#e4e4f2",
        });
        this.isLoading = true;
        this.applierExtId = this.userService.getExtId();
        if (!this.applierExtId) {
            this.hasError = true;
            this.isLoading = false;
        } else {
            this.matchListService.fetchMatches(this.userService.getExtId()!);
        }


        this.subscription = this.matchListService.matchLists.subscribe((matchLists) => {
            this.allMatches = matchLists.allOpen;
            this.topMatchesList = matchLists.topMatches;
            this.alternativesList = matchLists.alternatives;
            this.appliedList = matchLists.applied;
            if (this.matchListService.isInitialized) {
                this.isLoading = false;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }


    ngAfterViewInit() {
        var hasOpened = localStorage.getItem("hasOpened");
        if (!hasOpened) {
            try {
                this.explainDialogService.openDialog();
                localStorage.setItem("hasOpened", "true");
            } catch (error) {
                console.log(error);
            }
        }
    }


    goToTab(index: number) {
        this.tabGroup.selectedIndex = index;
    }

    swipe(e: any, direction: string): void {
        const coord = [e.changedPointers[0].pageX, e.changedPointers[0].pageY];

        if (direction === "left") {
            this.goToTab(1);
            return;
        }
        if (direction === "right") {
            this.goToTab(0);
            return;
        }
    }

    openBottomSheet(): void {
        this.bottomSheet.open(HomeListFilterComponent);
        this.handleFrameDisplay(window.innerWidth, window.innerHeight);
    }


    filtersChanged() {
        return this.filterService.filtersChanged;
    }

    resetFilters() {
        this.filterService.resetFilters();
    }

    @HostListener("window:resize", [
        "$event.target.innerWidth",
        "$event.target.innerHeight",
    ])
    handleFrameDisplay(width: number, height: number) {
        const usesFrame = width > 600;

        if (usesFrame) {
            const frame = document.querySelector(".frame") as HTMLElement;
            if (!frame) {
                return;
            }
            const sheet: HTMLElement | null =
                document.querySelector(".mat-bottom-sheet-container");

            const rect = frame.getBoundingClientRect();
            var frameWidth = rect.width;

            sheet?.style.setProperty("width", `${frameWidth}px`);
            sheet?.style.setProperty("min-width", `${frameWidth}px`);
        }
    }

}
