export interface Job {
    title?: string | null;
    city: string;
    postcode: string;
    onboarding?: string | null;
    shift: number[];
    shiftOther?: string | null;
    educationType: number[];
    position: number[];
    salaryOptions?: string[] | null;
    benefits?: string[] | null;
    educationOther?: string | null;
    drivingLicense?: string | null;
    specificationCareType: number[];
    careType: number[];
    workingType: number[];
    specialRequirements: number[];
    minSalary?: number | null;
    maxSalary?: number | null;
    continuingEducation: ContinuingEducation[];
    workingHours: (WorkingHour | null)[];
    continuingEducationOther?: string | null;
    workExperience: (WorkExperience | null)[];
    videoUrl?: string | null;
    note?: string | null;
    otherRequirements?: string | null;
    latitude: number;
    longitude: number;
    facilityLocation: FacilityLocation;
}

export interface WorkingHour {
    shiftType: number;
    startTime?: string | null;
    endTime?: string | null;
}

function mapWorkingHour(workingHourData: any): WorkingHour {
    return {
        shiftType: workingHourData.shift_type || null,
        startTime: workingHourData.start_time || null,
        endTime: workingHourData.end_time || null,
    };
}

export interface ContinuingEducation {
    continuingEducation: number;
    isRequired: boolean;
}

function mapContinuingEducation(
    continuingEducationData: any
): ContinuingEducation {
    return {
        continuingEducation:
            continuingEducationData.continuing_education || null,
        isRequired: !!continuingEducationData.is_required,
    };
}

export interface WorkExperience {
    areaOfExperience: string;
    yearsOfExperience?: number | null;
}

function mapWorkExperience(workExperienceData: any): WorkExperience {
    return {
        yearsOfExperience: workExperienceData.years_of_experience || null,
        areaOfExperience: workExperienceData.area_of_experience || null,
    };
}

export interface FacilityLocation {
    street?: string | null;
    houseNumber?: string | null;
}

export function mapFacilityLocation(facilityLocationData: any): FacilityLocation {
    return {
        street: facilityLocationData.street || null,
        houseNumber: facilityLocationData.house_number || null,
    };
}

export function mapJob(jobData: any): Job {
    return {
        title: jobData.title || null,
        city: jobData.city || "",
        postcode: jobData.postcode || "",
        onboarding: jobData.onboarding || null,
        shift: jobData.shift || [],
        shiftOther: jobData.shift_other || null,
        educationType: jobData.education_type || [],
        position: jobData.position || [],
        salaryOptions: jobData.salary_options || null,
        benefits: jobData.benefits || null,
        educationOther: jobData.education_other || null,
        drivingLicense: jobData.driving_license || null,
        specificationCareType: jobData.specification_care_type || [],
        careType: jobData.care_type || [],
        workingType: jobData.working_type || [],
        specialRequirements: jobData.special_requirements || [],
        minSalary: jobData.min_salary || null,
        maxSalary: jobData.max_salary || null,
        continuingEducation: jobData.continuing_education
            ? jobData.continuing_education.map((continuingEducation: any) =>
                mapContinuingEducation(continuingEducation)
            )
            : [],
        workingHours: jobData.working_hours
            ? jobData.working_hours.map((workingHour: any) =>
                mapWorkingHour(workingHour)
            )
            : [],
        continuingEducationOther: jobData.continuing_education_other || null,
        workExperience: jobData.work_experience
            ? jobData.work_experience.map((workExperience: any) =>
                mapWorkExperience(workExperience)
            )
            : [],
        videoUrl: jobData.video_url || null,
        note: jobData.note || null,
        otherRequirements: jobData.other_requirements || null,
        latitude: jobData.latitude || 0,
        longitude: jobData.longitude || 0,
        facilityLocation: jobData.facility_location
            ? mapFacilityLocation(jobData.facility_location)
            : {},
    };
}
