import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Match } from "../../../model";
import { CompanyBenefit } from "src/shared/util/utilFunctions";

@Component({
    selector: "app-company-benefits",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./company-benefits.component.html",
    styleUrl: "./company-benefits.component.scss",
})
export class CompanyBenefitsComponent {
    @Input() companyBenefits: CompanyBenefit[] = [];
}
