<div class="label-wrapper" [ngClass]="{ clickable: matches.length === 1 }">
    <mat-card
        class="match-card mat-elevation-z4"
        (click)="
            matches.length === 1
                ? openMatchDetails(matches[0].extId)
                : displayGroupedJobHint()
        "
        matRipple
        [matRippleDisabled]="matches.length !== 1"
    >
        <mat-card-header>
            @if ( company.logo ) {
            <div class="company-icon">
                <img
                    style="width: 100%; object-fit: cover"
                    src="{{ company.logo }}"
                />
            </div>
            }
            <app-match-card-fit-position
                *ngIf="(matches[0]?.fitIndex ?? 10) <= 3"
                [fitIndex]="matches[0].fitIndex ?? 10"
            />
            <div class="header-wrapper">
                <img
                    class="match-card-header-image"
                    src="{{ company.gallery[0] }}"
                    *ngIf="company.gallery && company.gallery.length > 0"
                />
                <div
                    class="image-overlay"
                    *ngIf="company.gallery && company.gallery.length > 0"
                ></div>
                <div
                    class="image-overlay-no-image"
                    *ngIf="!company.gallery || company.gallery.length == 0"
                ></div>
                <div
                    style="
                        padding-left: 24px;
                        padding-right: 12px;
                        z-index: 2;
                        position: absolute;
                    "
                >
                    <p class="company-name">{{ company.displayName }}</p>
                </div>
            </div>
        </mat-card-header>
        <div
            class="single-jobs-container column"
            style="padding: 10px 16px 24px 16px; gap: 16px"
        >
            <app-match-card-content
                *ngFor="let match of matches"
                [match]="match"
                [applier]="applier"
                [clickable]="matches.length != 1"
                (handleClick)="
                    matches.length != 1 ? openMatchDetails($event) : null
                "
            />
        </div>
    </mat-card>
</div>
