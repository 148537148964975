<div class="column">
    <div class="header">
        <img src="../../assets/images/lank-logo.png" height="56" />
    </div>
    <div class="content">
        <mat-icon fontIcon="search_off" class="search-off-icon" />
        <p class="message">Dieser Job existiert leider nicht mehr :(</p>
        <ng-container *ngIf="!inPreview">
            <p class="sub-message">
                Zum Glück haben wir noch viele andere Jobs für dich. Kehre
                einfach zu deiner Liste zurück, um schnell deinen besten Job zu
                finden!
            </p>
            <p-button
                [style]="{ padding: '15px 30px 15px 30px' }"
                icon="pi pi-arrow-right"
                label="Zurück zur Liste"
                (onClick)="goBack()"
            ></p-button>
        </ng-container>
    </div>
</div>
