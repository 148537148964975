import { Routes } from "@angular/router";
import { HomeComponent } from "./modules/home/home.component";
import { MatchDetailsComponent } from "./modules/match/match-details/match-details.component";
import { MatchAppliedComponent } from "./modules/match/match-applied/match-applied.component";
import { JobPreviewComponent } from "./modules/job/job-preview/job-preview.component";
import { ApplicantRegistrationFinishedComponent } from "./modules/applicant/applicant-registration-finished/applicant-registration-finished.component";
const routeConfig: Routes = [
    {
        path: ":extId",
        component: HomeComponent,
        data: { animation: "isLeft" },
        title: " länk | Deine passenden Jobvorschläge",
    },
    {
        path: ":extId/match/:matchExtId",
        component: MatchDetailsComponent,
        data: { animation: "isRight" },
        title: "Match Details",
    },
    {
        path: ":extId/match/:matchExtId/applied",
        component: MatchAppliedComponent,
        title: "Accepted Match",
    },

    {
        path: ":extId/match/:matchExtId/appliedAfterRegistration",
        component: MatchAppliedComponent,
        title: "Accepted Match",
    },

    {
        path: "preview/:jobExtId",
        component: JobPreviewComponent,
        title: "Job Preview",
    },

    {
        path: ":extId/registered",
        component: ApplicantRegistrationFinishedComponent,
        title: "Registered",
    },
];
export default routeConfig;

// hyrz4ao8kv
