import { MatchProperties } from "src/shared/util/utilFunctions";
import { Company, mapCompany } from "./company";
import { Job, mapJob } from "./job";

export interface Match {
    extId: string;
    appliedAt?: Date | null;
    distance: number;
    duration: number;
    status: number;
    company: Company;
    job: Job;
    stage: string;
    fitProbability: number;
    fitIndex?: number | null;
    matchProperties?: MatchProperties | null;
}

export function mapMatch(matchData: any): Match {
    return {
        extId: matchData.extid || "",
        appliedAt: matchData.applied_at ? new Date(matchData.applied_at) : null,
        distance: matchData.distance || 0,
        duration: matchData.duration || 0,
        status: matchData.status || 0,
        company: mapCompany(matchData.company || {}),
        job: mapJob(matchData.job || {}),
        stage: matchData.stage || "",
        fitProbability: matchData.fit_probability || 0,
    };
}

export interface GroupedMatch {
    company: string;
    fitIndex: number;
    matches: Match[];
}

export type MatchLists = {
    allOpen: Match[];
    applied: Match[];
    topMatches: GroupedMatch[];
    alternatives: GroupedMatch[];
};
