<h3 style="color: black; margin-bottom: 12px; margin-top: 12px">
    Bist du an diesem Job interessiert?
</h3>
@if (showEducation) {
<p
    style="
        font-size: 16px;
        font-weight: 600;
        color: var(--primary-color);
        margin-bottom: 8px;
    "
>
    Die Einrichtung hat die Anforderungen für diese Stelle angegeben:
</p>
<app-match-details-content-education
    [educationType]="match.job.educationType"
    [continuingEducation]="match.job.continuingEducation"
    [drivingLicense]="match.job.drivingLicense"
    [otherRequirements]="match.job.otherRequirements"
    [workExperience]="match.job.workExperience"
>
</app-match-details-content-education>
<mat-divider style="margin-top: 12px"></mat-divider>

}
<p style="margin-top: 12px; font-size: 14px">
    Unsere Karriereberater prüfen dein Profil und leiten es im Anschluss an die
    Einrichtung weiter oder kommen mit Rückfragen direkt auf dich zu.
</p>

<div class="button-row">
    <p-button label="Abbrechen" [text]="true" (onClick)="close()"></p-button>
    <p-button
        [style]="{ 'font-size': '14px' }"
        [loading]="loading"
        label="Ich bin interessiert!"
        (onClick)="accept()"
    ></p-button>
</div>
