import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, distinctUntilChanged } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User, mapUser } from "src/app/model/user";

@Injectable({
    providedIn: "root",
})
// user.service.ts
export class UserService {
    getExtId(): string | null {
        return this.extIdSubject.value;
    }
    navigateToNewExtId(newExtId: string): void {
        const segments = this.router.url.split('?');
        const currentPath = segments[0];
        const updatedPath = currentPath.replace(this.getExtId()!, newExtId);

        this.router.navigate([updatedPath], {
            queryParamsHandling: "preserve",
        });
    }
    private extIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<
        string | null
    >(null);
    public extIdObservable: Observable<string | null> =
        this.extIdSubject.asObservable();

    constructor(private route: ActivatedRoute, private router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    // Assuming 'extId' is the parameter name
                    return this.route.firstChild?.snapshot.params["extId"];
                }),
                distinctUntilChanged() // Only emit when the value actually changes
            )
            .subscribe((extId) => {
                this.extIdSubject.next(extId);
            });
    }

    async updateUser(
        extId: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string
    ): Promise<User> {
        var body = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
        };
        const response = await fetch(
            `${environment.api.baseUrl}accounts/applier/public/${extId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(body),
            }
        );

        if (response.status == 200) {
            const data = await response.json();

            const user: User = mapUser(data);
            return user;
        }

        if (response.status == 404) {
            throw new Error("Not Found");
        } else {
            throw new Error("Something Went Wrong");
        }
    }
}
