<div
    class="column"
    style="margin-bottom: 12px; margin-top: 12px; align-items: center"
>
    <h2 style="color: var(--primary-color)">Registrierung</h2>
    <p style="margin-top: 12px">
        Damit du dein Interesse für eine Stelle hinterlegen kannst, musst du
        deine Registrierung bei länk abschließen.
    </p>
    <form
        [formGroup]="myForm"
        (ngSubmit)="onSubmit()"
        novalidate
        style="
            gap: 12px;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 12px;
        "
    >
        <div class="p-field column input-headline">
            <mat-form-field
                appearance="outline"
                class="rounded-border"
                style="width: 100%"
                subscriptSizing="dynamic"
            >
                <mat-icon matSuffix>person</mat-icon>
                <mat-label class="bigger-input">Vorname</mat-label>
                <input
                    matInput
                    type="text"
                    id="firstname"
                    name="firstname"
                    formControlName="firstname"
                    placeholder="Max"
                    required
                    [class.error]="isControlInvalid('firstname')"
                    class="bigger-input"
                />
                <mat-error *ngIf="isControlInvalid('firstname')"
                    >Diese Info is notwendig.</mat-error
                >
            </mat-form-field>
        </div>

        <div class="p-field column">
            <mat-form-field
                appearance="outline"
                class="rounded-border"
                subscriptSizing="dynamic"
            >
                <mat-icon matSuffix>person</mat-icon>
                <mat-label class="bigger-input">Nachname</mat-label>
                <input
                    matInput
                    type="text"
                    id="lastname"
                    name="lastname"
                    formControlName="lastname"
                    placeholder="Mustermann"
                    required
                    [class.error]="isControlInvalid('lastname')"
                    class="bigger-input"
                />
                <mat-error *ngIf="isControlInvalid('lastname')"
                    >Diese Info is notwendig.</mat-error
                >
            </mat-form-field>
        </div>
        <div class="p-field column">
            <mat-form-field
                appearance="outline"
                class="rounded-border"
                subscriptSizing="dynamic"
            >
                <mat-icon matSuffix>mail</mat-icon>
                <mat-label class="bigger-input">E-Mail</mat-label>
                <input
                    matInput
                    placeholder="max@mustermann.de"
                    type="email"
                    id="email"
                    name="email"
                    formControlName="email"
                    required
                    [class.error]="isControlInvalid('email')"
                    class="bigger-input"
                />
                <mat-error *ngIf="isControlInvalid('email')"
                    >Ungültige Email.</mat-error
                >
            </mat-form-field>
        </div>

        <div class="p-field column">
            <mat-form-field
                appearance="outline"
                class="rounded-border"
                subscriptSizing="dynamic"
            >
                <mat-icon matSuffix>phone</mat-icon>
                <mat-label class="bigger-input">Telefonnummer</mat-label>
                <input
                    matInput
                    placeholder="+49 123 723419"
                    type="tel"
                    id="phone"
                    name="phone"
                    formControlName="phone"
                    required
                    [class.error]="isControlInvalid('phone')"
                    class="bigger-input"
                />
                <mat-error *ngIf="isControlInvalid('phone')"
                    >Ungültige Telefonnummer</mat-error
                >
            </mat-form-field>
        </div>
        <!-- Add more form fields as needed -->
        <mat-checkbox
            formControlName="terms"
            required
            [color]="'primary'"
            class="bigger-input"
            >Ich stimme den
            <a href="https://laenk.de/agb-bewerber/" target="_blank">AGB</a> und
            der
            <a href="https://laenk.de/datenschutz-bewerber/" target="_blank"
                >Datenschutzerklärung</a
            >
            zu</mat-checkbox
        >
        <mat-error *ngIf="isControlInvalid('terms')"
            >Deine Zustimmung ist notwendig.</mat-error
        >
        <button
            [disabled]="myForm.invalid"
            [loading]="loading"
            type="submit"
            pButton
            label="Registrieren"
            class="p-button-raised p-button-primary submit-button"
        ></button>
    </form>
</div>
