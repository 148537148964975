import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Applier, Match } from "../../../../model";
import { MatchDetailsContentEducationComponent } from "./match-details-content-education/match-details-content-education.component";
import { MatchDetailsContentWorkinghoursComponent } from "./match-details-content-workinghours/match-details-content-workinghours.component";
import { MatchDetailsContentCardComponent } from "./match-details-content-card/match-details-content-card.component";
import { CompanyBenefitsComponent } from "../../../company/company-benefits/company-benefits.component";
import { MatchDetailsContentSalaryComponent } from "./match-details-content-salary/match-details-content-salary.component";
import {
    CompanyBenefit,
    cleanHtml,
    getCompanyBenefits,
    getAvailableSize,
} from "src/shared/util/utilFunctions";
import { MatchDetailsContentBenefitsComponent } from "./match-details-content-benefits/match-details-content-benefits.component";
import { VideoEmbedComponent } from "../../../video-embed/video-embed.component";
import { GalleriaModule } from "primeng/galleria";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ConstantsService } from "src/shared/services/constants.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ButtonModule } from "primeng/button";
import { Subscription } from "rxjs";
import { MatchDetailsContentMapComponent } from "./match-details-content-map/match-details-content-map.component";

@Component({
    selector: "app-match-details-content",
    standalone: true,
    imports: [
        CommonModule,
        MatchDetailsContentCardComponent,
        MatchDetailsContentWorkinghoursComponent,
        MatchDetailsContentEducationComponent,
        CompanyBenefitsComponent,
        MatchDetailsContentSalaryComponent,
        MatchDetailsContentBenefitsComponent,
        MatchDetailsContentMapComponent,
        VideoEmbedComponent,
        GalleriaModule,
        MatButtonModule,
        MatIconModule,
        ButtonModule,
    ],
    templateUrl: "./match-details-content.component.html",
    styleUrls: ["./match-details-content.component.scss"],
})
export class MatchDetailsContentComponent implements OnInit {
    @Input() match?: Match | null;
    @Input() applier?: Applier | null;
    showWorkingHours!: boolean;
    showEducation!: boolean;
    showSalary!: boolean;
    showBenefits!: boolean;
    showOnboarding!: boolean;
    showNotes!: boolean;
    showAbout!: boolean;
    showCulture!: boolean;
    videoUrl: string | null = null;
    len!: number;
    indicatorWidth!: number;
    showIndicator: boolean = true;

    youtubeVideoId!: string | null;
    isYoutube!: boolean;

    companyBenefits: CompanyBenefit[] = [];

    images: any[] = [];

    aboutCleanString: string | SafeHtml = "";
    notesCleanString: string | SafeHtml = "";

    subscription?: Subscription;

    get activeIndex(): number {
        return this._activeIndex;
    }

    set activeIndex(newValue) {
        if (
            this.images &&
            0 <= newValue &&
            newValue <= this.images.length - 1
        ) {
            this._activeIndex = newValue;
        }
    }

    next() {
        this.activeIndex++;
    }

    prev() {
        this.activeIndex--;
    }

    _activeIndex: number = 0;

    constructor(
        private constantsService: ConstantsService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.showWorkingHours =
            (this.match?.job?.shift?.length ?? 0) > 0 ||
            (this.match?.job?.workingHours?.length ?? 0) > 0 ||
            !!this.match?.job.shiftOther;

        this.showEducation =
            (this.match?.job?.educationType?.length ?? 0) > 0 ||
            (this.match?.job?.continuingEducation?.length ?? 0) > 0 ||
            !!this.match?.job?.drivingLicense;

        this.showSalary =
            !!this.match?.company?.tarif ||
            (this.match?.job?.minSalary ?? 0) != 0 ||
            (this.match?.job?.maxSalary ?? 0) != 0 ||
            (this.match?.job?.salaryOptions ?? []).length > 0;

        this.showBenefits = (this.match?.job?.benefits ?? []).length > 0;

        this.showOnboarding = !!this.match?.job?.onboarding;

        this.showNotes = !!this.match?.job?.note;

        this.videoUrl =
            this.match?.company?.videoUrl ?? this.match?.job?.videoUrl ?? null;

        if (this.videoUrl) {
            this.youtubeVideoId = this.getYoutubeVideoId(this.videoUrl!);
            this.isYoutube = this.youtubeVideoId != null;

            if (this.isYoutube && !window["YT"]) {
                const tag = document.createElement("script");
                tag.src = "https://www.youtube.com/iframe_api";
                document.body.appendChild(tag);
            }
        } else {
            this.youtubeVideoId = null;
            this.isYoutube = false;
        }

        this.showAbout =
            !!this.match?.company?.about ||
            (this.isYoutube && (this.youtubeVideoId !== null) !== null);

        this.showCulture = !!this.match?.company?.culture;

        this.images =
            this.match?.company?.gallery?.map((image) => {
                return {
                    itemImageSrc: image,
                    thumbnailImageSrc: image,
                    alt: "image",
                    title: "title",
                };
            }) ?? [];

        // each indicator takes on 16px + 8px margin
        this.indicatorWidth = 24 * this.images.length;

        this.len = [
            this.showWorkingHours,
            this.showEducation,
            this.showSalary,
            this.showBenefits,
            this.showOnboarding,
            this.showNotes,
            this.showAbout,
            this.showCulture,
        ].filter((element) => element == true).length;

        if (this.match && this.applier) {
            this.subscription = this.constantsService.constants$.subscribe(
                (constants) => {
                    if (constants) {
                        this.companyBenefits = getCompanyBenefits(
                            this.match!,
                            this.applier!,
                            constants
                        );
                    }
                }
            );
        }

        this.sanitize();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        const relevantWidth = getAvailableSize().relevantWidth;

        const maxWidthAvail = relevantWidth - 16 * 4;

        if (this.indicatorWidth > maxWidthAvail) {
            this.showIndicator = false;
        }
    }

    sanitize() {
        if (this.showAbout) {
            this.aboutCleanString = cleanHtml({
                domSanitizer: this.sanitizer,
                html: this.match?.company?.about ?? "",
            });
        }
        if (this.showNotes) {
            this.notesCleanString = cleanHtml({
                domSanitizer: this.sanitizer,
                html: this.match?.job.note ?? "",
            });
        }
    }

    /**
     * Extracts the YouTube video ID from a given URL.
     * @param url - The YouTube URL from which to extract the video ID.
     * @returns The YouTube video ID if found, or null if the URL is not a YouTube URL or if no video ID is found.
     */
    getYoutubeVideoId(url: string): string | null {
        // This regular expression is designed to match different YouTube URL formats
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            // The video ID is always 11 characters long
            return match[2];
        }

        // Return null if the URL is not a YouTube URL or if no video ID is found
        return null;
    }

    /**
     * Opens the non-YouTube link in a new tab.
     */
    openNonYoutubeLink() {
        if (this.videoUrl) {
            window.open(this.videoUrl!, "_blank");
        }
    }
}
