<div style="display: flex">
    <div class="column applied-content">
        <div class="header">
            <img src="../../assets/images/lank-logo.png" height="56" />
        </div>
        <img
            style="width: 60px; object-fit: cover"
            src="{{
                match.company.logo ??
                    'https://res.cloudinary.com/paulwvnjohi/image/upload/v1679492526/placeholder_sk48uq.png'
            }}"
        />
        <p class="message">
            {{ applier.user.firstName ?? "" }} - du hast dich beworben!
        </p>
        <p class="sub-message">
            Der Anbieter wird sich in den nächsten Tagen bei dir melden! In der
            Zwischenzeit kannst du dich zurücklehnen und dir weitere Jobangebote
            ansehen...
        </p>
        <p-button
            [style]="{ padding: '15px 30px 15px 30px' }"
            icon="pi pi-arrow-right"
            label="Weitere Jobs finden"
            (onClick)="goBack()"
        ></p-button>
    </div>
</div>
