import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ViewChild, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, BehaviorSubject, fromEventPattern, of, Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

/// <reference types="youtube" />
const _c0 = ["youtubeContainer"];
const DEFAULT_PLAYER_WIDTH = 640;
const DEFAULT_PLAYER_HEIGHT = 390;
/**
 * Angular component that renders a YouTube player via the YouTube player
 * iframe API.
 * @see https://developers.google.com/youtube/iframe_api_reference
 */
class YouTubePlayer {
  /** Height of video player */
  get height() {
    return this._height;
  }
  set height(height) {
    this._height = height || DEFAULT_PLAYER_HEIGHT;
  }
  /** Width of video player */
  get width() {
    return this._width;
  }
  set width(width) {
    this._width = width || DEFAULT_PLAYER_WIDTH;
  }
  constructor(_ngZone, platformId) {
    this._ngZone = _ngZone;
    this._destroyed = new Subject();
    this._playerChanges = new BehaviorSubject(undefined);
    this._height = DEFAULT_PLAYER_HEIGHT;
    this._width = DEFAULT_PLAYER_WIDTH;
    /** Whether cookies inside the player have been disabled. */
    this.disableCookies = false;
    /** Outputs are direct proxies from the player itself. */
    this.ready = this._getLazyEmitter('onReady');
    this.stateChange = this._getLazyEmitter('onStateChange');
    this.error = this._getLazyEmitter('onError');
    this.apiChange = this._getLazyEmitter('onApiChange');
    this.playbackQualityChange = this._getLazyEmitter('onPlaybackQualityChange');
    this.playbackRateChange = this._getLazyEmitter('onPlaybackRateChange');
    this._isBrowser = isPlatformBrowser(platformId);
  }
  ngAfterViewInit() {
    // Don't do anything if we're not in a browser environment.
    if (!this._isBrowser) {
      return;
    }
    if (!window.YT || !window.YT.Player) {
      if (this.showBeforeIframeApiLoads && (typeof ngDevMode === 'undefined' || ngDevMode)) {
        throw new Error('Namespace YT not found, cannot construct embedded youtube player. ' + 'Please install the YouTube Player API Reference for iframe Embeds: ' + 'https://developers.google.com/youtube/iframe_api_reference');
      }
      this._existingApiReadyCallback = window.onYouTubeIframeAPIReady;
      window.onYouTubeIframeAPIReady = () => {
        this._existingApiReadyCallback?.();
        this._ngZone.run(() => this._createPlayer());
      };
    } else {
      this._createPlayer();
    }
  }
  ngOnChanges(changes) {
    if (this._shouldRecreatePlayer(changes)) {
      this._createPlayer();
    } else if (this._player) {
      if (changes['width'] || changes['height']) {
        this._setSize();
      }
      if (changes['suggestedQuality']) {
        this._setQuality();
      }
      if (changes['startSeconds'] || changes['endSeconds'] || changes['suggestedQuality']) {
        this._cuePlayer();
      }
    }
  }
  ngOnDestroy() {
    this._pendingPlayer?.destroy();
    if (this._player) {
      this._player.destroy();
      window.onYouTubeIframeAPIReady = this._existingApiReadyCallback;
    }
    this._playerChanges.complete();
    this._destroyed.next();
    this._destroyed.complete();
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#playVideo */
  playVideo() {
    if (this._player) {
      this._player.playVideo();
    } else {
      this._getPendingState().playbackState = YT.PlayerState.PLAYING;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#pauseVideo */
  pauseVideo() {
    if (this._player) {
      this._player.pauseVideo();
    } else {
      this._getPendingState().playbackState = YT.PlayerState.PAUSED;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#stopVideo */
  stopVideo() {
    if (this._player) {
      this._player.stopVideo();
    } else {
      // It seems like YouTube sets the player to CUED when it's stopped.
      this._getPendingState().playbackState = YT.PlayerState.CUED;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#seekTo */
  seekTo(seconds, allowSeekAhead) {
    if (this._player) {
      this._player.seekTo(seconds, allowSeekAhead);
    } else {
      this._getPendingState().seek = {
        seconds,
        allowSeekAhead
      };
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#mute */
  mute() {
    if (this._player) {
      this._player.mute();
    } else {
      this._getPendingState().muted = true;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#unMute */
  unMute() {
    if (this._player) {
      this._player.unMute();
    } else {
      this._getPendingState().muted = false;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#isMuted */
  isMuted() {
    if (this._player) {
      return this._player.isMuted();
    }
    if (this._pendingPlayerState) {
      return !!this._pendingPlayerState.muted;
    }
    return false;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#setVolume */
  setVolume(volume) {
    if (this._player) {
      this._player.setVolume(volume);
    } else {
      this._getPendingState().volume = volume;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVolume */
  getVolume() {
    if (this._player) {
      return this._player.getVolume();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.volume != null) {
      return this._pendingPlayerState.volume;
    }
    return 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#setPlaybackRate */
  setPlaybackRate(playbackRate) {
    if (this._player) {
      return this._player.setPlaybackRate(playbackRate);
    } else {
      this._getPendingState().playbackRate = playbackRate;
    }
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getPlaybackRate */
  getPlaybackRate() {
    if (this._player) {
      return this._player.getPlaybackRate();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.playbackRate != null) {
      return this._pendingPlayerState.playbackRate;
    }
    return 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getAvailablePlaybackRates */
  getAvailablePlaybackRates() {
    return this._player ? this._player.getAvailablePlaybackRates() : [];
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVideoLoadedFraction */
  getVideoLoadedFraction() {
    return this._player ? this._player.getVideoLoadedFraction() : 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getPlayerState */
  getPlayerState() {
    if (!this._isBrowser || !window.YT) {
      return undefined;
    }
    if (this._player) {
      return this._player.getPlayerState();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.playbackState != null) {
      return this._pendingPlayerState.playbackState;
    }
    return YT.PlayerState.UNSTARTED;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getCurrentTime */
  getCurrentTime() {
    if (this._player) {
      return this._player.getCurrentTime();
    }
    if (this._pendingPlayerState && this._pendingPlayerState.seek) {
      return this._pendingPlayerState.seek.seconds;
    }
    return 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getPlaybackQuality */
  getPlaybackQuality() {
    return this._player ? this._player.getPlaybackQuality() : 'default';
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getAvailableQualityLevels */
  getAvailableQualityLevels() {
    return this._player ? this._player.getAvailableQualityLevels() : [];
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getDuration */
  getDuration() {
    return this._player ? this._player.getDuration() : 0;
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVideoUrl */
  getVideoUrl() {
    return this._player ? this._player.getVideoUrl() : '';
  }
  /** See https://developers.google.com/youtube/iframe_api_reference#getVideoEmbedCode */
  getVideoEmbedCode() {
    return this._player ? this._player.getVideoEmbedCode() : '';
  }
  /** Gets an object that should be used to store the temporary API state. */
  _getPendingState() {
    if (!this._pendingPlayerState) {
      this._pendingPlayerState = {};
    }
    return this._pendingPlayerState;
  }
  /**
   * Determines whether a change in the component state
   * requires the YouTube player to be recreated.
   */
  _shouldRecreatePlayer(changes) {
    const change = changes['videoId'] || changes['playerVars'] || changes['disableCookies'];
    return !!change && !change.isFirstChange();
  }
  /** Creates a new YouTube player and destroys the existing one. */
  _createPlayer() {
    this._player?.destroy();
    this._pendingPlayer?.destroy();
    // A player can't be created if the API isn't loaded,
    // or there isn't a video or playlist to be played.
    if (typeof YT === 'undefined' || !this.videoId && !this.playerVars?.list) {
      return;
    }
    // Important! We need to create the Player object outside of the `NgZone`, because it kicks
    // off a 250ms setInterval which will continually trigger change detection if we don't.
    const player = this._ngZone.runOutsideAngular(() => new YT.Player(this.youtubeContainer.nativeElement, {
      videoId: this.videoId,
      host: this.disableCookies ? 'https://www.youtube-nocookie.com' : undefined,
      width: this.width,
      height: this.height,
      playerVars: this.playerVars
    }));
    const whenReady = () => {
      // Only assign the player once it's ready, otherwise YouTube doesn't expose some APIs.
      this._player = player;
      this._pendingPlayer = undefined;
      player.removeEventListener('onReady', whenReady);
      this._playerChanges.next(player);
      this._setSize();
      this._setQuality();
      if (this._pendingPlayerState) {
        this._applyPendingPlayerState(player, this._pendingPlayerState);
        this._pendingPlayerState = undefined;
      }
      // Only cue the player when it either hasn't started yet or it's cued,
      // otherwise cuing it can interrupt a player with autoplay enabled.
      const state = player.getPlayerState();
      if (state === YT.PlayerState.UNSTARTED || state === YT.PlayerState.CUED || state == null) {
        this._cuePlayer();
      }
    };
    this._pendingPlayer = player;
    player.addEventListener('onReady', whenReady);
  }
  /** Applies any state that changed before the player was initialized. */
  _applyPendingPlayerState(player, pendingState) {
    const {
      playbackState,
      playbackRate,
      volume,
      muted,
      seek
    } = pendingState;
    switch (playbackState) {
      case YT.PlayerState.PLAYING:
        player.playVideo();
        break;
      case YT.PlayerState.PAUSED:
        player.pauseVideo();
        break;
      case YT.PlayerState.CUED:
        player.stopVideo();
        break;
    }
    if (playbackRate != null) {
      player.setPlaybackRate(playbackRate);
    }
    if (volume != null) {
      player.setVolume(volume);
    }
    if (muted != null) {
      muted ? player.mute() : player.unMute();
    }
    if (seek != null) {
      player.seekTo(seek.seconds, seek.allowSeekAhead);
    }
  }
  /** Cues the player based on the current component state. */
  _cuePlayer() {
    if (this._player && this.videoId) {
      this._player.cueVideoById({
        videoId: this.videoId,
        startSeconds: this.startSeconds,
        endSeconds: this.endSeconds,
        suggestedQuality: this.suggestedQuality
      });
    }
  }
  /** Sets the player's size based on the current input values. */
  _setSize() {
    this._player?.setSize(this.width, this.height);
  }
  /** Sets the player's quality based on the current input values. */
  _setQuality() {
    if (this._player && this.suggestedQuality) {
      this._player.setPlaybackQuality(this.suggestedQuality);
    }
  }
  /** Gets an observable that adds an event listener to the player when a user subscribes to it. */
  _getLazyEmitter(name) {
    // Start with the stream of players. This way the events will be transferred
    // over to the new player if it gets swapped out under-the-hood.
    return this._playerChanges.pipe(
    // Switch to the bound event. `switchMap` ensures that the old event is removed when the
    // player is changed. If there's no player, return an observable that never emits.
    switchMap(player => {
      return player ? fromEventPattern(listener => {
        player.addEventListener(name, listener);
      }, listener => {
        // The API seems to throw when we try to unbind from a destroyed player and it doesn't
        // expose whether the player has been destroyed so we have to wrap it in a try/catch to
        // prevent the entire stream from erroring out.
        try {
          player?.removeEventListener?.(name, listener);
        } catch {}
      }) : of();
    }),
    // By default we run all the API interactions outside the zone
    // so we have to bring the events back in manually when they emit.
    source => new Observable(observer => source.subscribe({
      next: value => this._ngZone.run(() => observer.next(value)),
      error: error => observer.error(error),
      complete: () => observer.complete()
    })),
    // Ensures that everything is cleared out on destroy.
    takeUntil(this._destroyed));
  }
  static {
    this.ɵfac = function YouTubePlayer_Factory(ɵt) {
      return new (ɵt || YouTubePlayer)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: YouTubePlayer,
      selectors: [["youtube-player"]],
      viewQuery: function YouTubePlayer_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.youtubeContainer = _t.first);
        }
      },
      inputs: {
        videoId: "videoId",
        height: "height",
        width: "width",
        startSeconds: "startSeconds",
        endSeconds: "endSeconds",
        suggestedQuality: "suggestedQuality",
        playerVars: "playerVars",
        disableCookies: "disableCookies",
        showBeforeIframeApiLoads: "showBeforeIframeApiLoads"
      },
      outputs: {
        ready: "ready",
        stateChange: "stateChange",
        error: "error",
        apiChange: "apiChange",
        playbackQualityChange: "playbackQualityChange",
        playbackRateChange: "playbackRateChange"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 2,
      vars: 0,
      consts: [["youtubeContainer", ""]],
      template: function YouTubePlayer_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YouTubePlayer, [{
    type: Component,
    args: [{
      selector: 'youtube-player',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      // This div is *replaced* by the YouTube player embed.
      template: '<div #youtubeContainer></div>'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    videoId: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    startSeconds: [{
      type: Input
    }],
    endSeconds: [{
      type: Input
    }],
    suggestedQuality: [{
      type: Input
    }],
    playerVars: [{
      type: Input
    }],
    disableCookies: [{
      type: Input
    }],
    showBeforeIframeApiLoads: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    stateChange: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    apiChange: [{
      type: Output
    }],
    playbackQualityChange: [{
      type: Output
    }],
    playbackRateChange: [{
      type: Output
    }],
    youtubeContainer: [{
      type: ViewChild,
      args: ['youtubeContainer', {
        static: true
      }]
    }]
  });
})();
const COMPONENTS = [YouTubePlayer];
class YouTubePlayerModule {
  static {
    this.ɵfac = function YouTubePlayerModule_Factory(ɵt) {
      return new (ɵt || YouTubePlayerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: YouTubePlayerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YouTubePlayerModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { YouTubePlayer, YouTubePlayerModule };
