<div
    class="column wrapper"
    style="border-top: 2px solid darkgrey; padding-top: 8px"
>
    <div class="row">
        <mat-icon
            aria-hidden="false"
            fontIcon="arrow_downward"
            class="sized-icon"
        ></mat-icon>
        <p class="title">Alternativen</p>
    </div>
    <p>
        Diese Jobs passen nicht perfekt zu deinem Profil, sollen dir aber
        trotzdem nicht vorenthalten werden
    </p>
</div>
