<div class="column">
    <div class="header">
        <img src="../../assets/images/lank-logo.png" height="56" />
    </div>
    <div class="content">
        <mat-icon fontIcon="error" class="error-icon" />
        <p class="message">Da ist etwas schief gelaufen :(</p>
        <p>Anscheinend verwendest du einen falschen Link.</p>
    </div>
</div>
