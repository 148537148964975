import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { DialogModule } from "primeng/dialog";
import { MatButtonModule } from "@angular/material/button";
import { HelpContentComponent } from "../help-content/help-content.component";
import { ExplainDialogService } from "src/shared/services/explain-dialog.service";
import { HelpInitialContentComponent } from "../help-initial-content/help-initial-content.component";
import { Subscription } from "rxjs";

@Component({
    selector: "app-help-button",
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        DialogModule,
        HelpInitialContentComponent,
        MatButtonModule,
        HelpContentComponent,
    ],
    templateUrl: "./help-button.component.html",
    styleUrl: "./help-button.component.scss",
})
export class HelpButtonComponent {
    explainDialogVisible: boolean = false;
    helpDialogVisible: boolean = false;
    subscription!: Subscription;

    constructor(private explainDialogService: ExplainDialogService) { }

    ngOnInit() {
        this.subscription = this.explainDialogService.dialogOpen$.subscribe((shouldOpen) => {
            if (shouldOpen) {
                this.explainDialogVisible = true;
            } else {
                this.explainDialogVisible = false;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onExplainDialogHide(event: any) {
        this.explainDialogService.closeDialog();
        this.explainDialogVisible = false;
    }

    onHelpDialogHide(event: any) {
        this.helpDialogVisible = false;
    }

    clickEvent() {
        this.helpDialogVisible = true;
    }
}
