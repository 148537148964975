<div
    class="profile-circle clickable"
    (click)="clickEvent()"
    matRipple
    [style.background-color]="
        !isRegistered ? 'var(--lk-orange)' : 'var(--lk-turquoise)'
    "
>
    {{ displayName }}
</div>
<p-dialog
    #registrationDialog
    [showHeader]="false"
    [(visible)]="visible"
    [style]="{ width: '90%' }"
    [modal]="true"
    [dismissableMask]="true"
    [closeOnEscape]="true"
    [closable]="true"
>
    @if (isRegistered && navIndex == 0) {
    <app-applicant-card [applier]="applier" />
    } @else { @if (navIndex == 0) {

    <app-registration-dialog
        source="profile-avatar"
        (finishRegistration)="handleUserRegistration($event)"
    ></app-registration-dialog>
    } @else {
    <app-applicant-registration-finished (closeDialog)="onDialogHide($event)" />
    } }
</p-dialog>
