import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Applier } from "../../../model";
import { DialogModule } from "primeng/dialog";
import { AnonymousNames } from "../../../model/anonymousNames.model";
import { RegistrationDialogComponent } from "../../registration-dialog/registration-dialog.component";
import { MatRippleModule } from "@angular/material/core";
import { MatchService } from "src/shared/services/match.service";
import { UserService } from "src/shared/services/user.service";
import { Subscription } from "rxjs";
import { ApplicantCardComponent } from "../applicant-card/applicant-card.component";
import { Router } from "@angular/router";
import { ApplicantRegistrationFinishedComponent } from "../applicant-registration-finished/applicant-registration-finished.component";
import { AnalyticsService } from "src/shared/services/analytics.service";

@Component({
    selector: "app-applicant-profile-avatar",
    standalone: true,
    imports: [
        CommonModule,
        DialogModule,
        RegistrationDialogComponent,
        MatRippleModule,
        ApplicantCardComponent,
        ApplicantRegistrationFinishedComponent,
    ],

    templateUrl: "./applicant-profile-avatar.component.html",
    styleUrls: ["./applicant-profile-avatar.component.scss"],
})
export class ApplicantProfileAvatarComponent {
    @Input() applier!: Applier;
    @Input() extId!: string;

    displayName: string = "";

    isRegistered: boolean = false;
    visible: boolean = false;

    subscription!: Subscription;

    navIndex = 0;

    setDisplayName() {
        if (this.isRegistered) {
            this.displayName = `${this.applier.user.firstName![0]}${this.applier.user.lastName![0]
                }`;
        } else {
            var fullName = AnonymousNames.getAnonymousName(this.extId);
            this.displayName = `${fullName[0].toUpperCase()}${fullName[
                fullName.length - 1
            ].toUpperCase()}`;
        }
    }

    ngOnInit() {
        this.isRegistered =
            this.applier && this.applier.user.socialAccount != 3;
        this.setDisplayName();

        this.subscription = this.matchService.matchApplier$.subscribe(
            (matchApplier) => {
                if (matchApplier) {
                    this.applier = matchApplier.applier;
                    this.isRegistered = this.applier.user.socialAccount != 3;

                    this.setDisplayName();
                    this.cdr.detectChanges(); // Manually trigger change detection

                }
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clickEvent() {
        this.visible = true;
        if (!this.isRegistered) {
            this.analyticsService.trackEvent("registration-dialog-open", {
                applier_extId: this.userService.getExtId(),
                source: "profile-avatar",
            });
        }
    }

    onDialogHide(event: any) {
        this.visible = false;
        this.navIndex = 0;
    }

    constructor(
        private matchService: MatchService,
        private userService: UserService,
        private analyticsService: AnalyticsService, private cdr: ChangeDetectorRef,

    ) {
    }



    async handleUserRegistration(user: any) {
        this.navIndex = 1;
    }
}
