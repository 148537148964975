import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

type Constants = {
    extendedCareType: ExtendedConstantMap;
    extendedPosition: ExtendedConstantMap;
    workingType: ConstantsMap;
    educations: ConstantsMap;
    positions: ConstantsMap;
    shiftHours: ConstantsMap;
    workingHours: ConstantsMap;
    continuingEducation: ConstantsMap;
    ownership: ConstantsMap;
    companySize: ConstantsMap;
    drivingLicense: ConstantsMap;
};
type ConstantsMap = {
    [key: string]: string;
};

type ExtendedConstantMap = {
    [key: number]: ExtendedConstant;
};

type ExtendedConstant = {
    label: string;
    parentLabel: string;
};

enum ConstantsChoice {
    ExtendedCareType = "extended_care_type",
    ExtendedPosition = "extended_position",
    WorkingType = "shift_time_type",
    Educations = "education_type",
    Positions = "position",
    ShiftHours = "shift_hour_type",
    WorkingHours = "working_hours_type",
    ContinuingEducation = "continuing_education_type",
    Ownership = "ownership",
    CompanySize = "company_size",
    DrivingLicense = "driver_license_type",
}

@Injectable({
    providedIn: "root",
})
export class ConstantsService {
    private constants: BehaviorSubject<Constants | null> =
        new BehaviorSubject<Constants | null>(null);

    public get constants$() {
        return this.constants.asObservable();
    }

    public get constantsValue() {
        return this.constants.getValue();
    }

    constructor() {
        getAllConstants()
            .then((constants) => {
                this.constants.next(constants);
            })
            .catch((error) => {
                console.error(error);
                Sentry.captureException(error);
            });
    }
}

async function getAllConstants(): Promise<Constants> {
    const responses = await Promise.all([
        fetch(`${environment.api.baseUrl}common/constants`).then((response) =>
            response.json()
        ),
        fetch(
            `${environment.api.baseUrl}common/constants/?choice=extended_care_type`
        ).then((response) => response.json()),
        fetch(
            `${environment.api.baseUrl}common/constants/?choice=extended_position`
        ).then((response) => response.json()),
    ]);

    const dataDict = responses[0];
    const extendedDataDictCareType = responses[1];
    const extendedDataDictPosition = responses[2];
    const constants: Constants = {
        extendedCareType: getExtendedMap(
            extendedDataDictCareType[ConstantsChoice.ExtendedCareType]
        ),
        extendedPosition: getExtendedMap(
            extendedDataDictPosition[ConstantsChoice.ExtendedPosition]
        ),
        workingType: getMap(dataDict[ConstantsChoice.WorkingType]),
        educations: getMap(dataDict[ConstantsChoice.Educations]),
        positions: getMap(dataDict[ConstantsChoice.Positions]),
        shiftHours: getMap(dataDict[ConstantsChoice.ShiftHours]),
        workingHours: getMap(dataDict[ConstantsChoice.WorkingHours]),
        continuingEducation: getMap(
            dataDict[ConstantsChoice.ContinuingEducation]
        ),
        ownership: getMap(dataDict[ConstantsChoice.Ownership]),
        companySize: getMap(dataDict[ConstantsChoice.CompanySize]),
        drivingLicense: getMap(dataDict[ConstantsChoice.DrivingLicense]),
    };
    return constants;
}

function getMap(list: any): ConstantsMap {
    const labelDict: ConstantsMap = {};
    for (const item of list) {
        labelDict[item["id"]] = item["label"];
    }
    return labelDict;
}

function getExtendedMap(
    list: any,
    childIdsIndicator: string = "children_ids"
): ExtendedConstantMap {
    const resultDict: ExtendedConstantMap = {};
    for (const el in list) {
        if (el.startsWith("_")) {
            const parentLabel = list[el]["label"];
            for (const childId of list[el][childIdsIndicator]) {
                const childLabel = list[String(childId)]["label"];
                resultDict[childId] = {
                    label: childLabel,
                    parentLabel: parentLabel,
                };
            }
        } else {
            const numericEl = parseInt(el);
            resultDict[numericEl] = {
                label: list[el]["label"],
                parentLabel: "Andere",
            };
        }
    }
    return resultDict;
}

export type { Constants, ConstantsChoice, ConstantsMap, ExtendedConstantMap };
