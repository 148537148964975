<div class="column help-content">
    <h3>Hej, schön dass du da bist 🙌</h3>
    <p>
        Das ist länk: Deine persönlichen Jobvorschläge – so sortiert, wie sie am
        besten zu dir passen. <br />
        <br />Top-Job gefunden - und nun?
    </p>
    <ol type="1">
        <li>Job öffnen</li>
        <li>"Interessiert" anklicken</li>
        <li>Fertig</li>
    </ol>
    <p>
        <br />
        Bei Fragen oder Unterstützungsbedarf erreichst du hier jederzeit deine
        Karriereberaterin über den Whatsapp Button ganz unten 💜
    </p>
    <div class="row" style="justify-content: center; margin-top: 8px">
        <button
            pButton
            label="Weiter"
            class="p-button-raised p-button-primary okay-button"
            style="padding: 12 40px"
            (click)="closeDialog()"
        ></button>
    </div>
</div>
