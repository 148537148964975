import { Injectable, isDevMode } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Match } from "src/app/model";
import { mapMatch } from "src/app/model/match";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class JobService {
    private match: BehaviorSubject<Match | null | undefined> =
        new BehaviorSubject<Match | null | undefined>(undefined);

    public get matchObject$() {
        return this.match.asObservable();
    }

    constructor() {
        if (isDevMode()) {
            console.log("JobService");
        }
    }

    async getJob(jobExtId: string | null) {
        const response = await fetch(
            `${environment.api.baseUrl}match/matchlist/public/?external_job_id=${jobExtId}`
        );

        if (response.status == 200) {
            const data = await response.json();

            const extendedData = {
                extid: "****",
                distance: 10,
                duration: 10,
                status: 1,
                ...data,
            };

            var match: Match = mapMatch(extendedData);

            if (isDevMode()) {
                console.log(match);
            }

            this.match.next(match);
        } else {
            this.match.next(null);
        }
    }
}
