<footer
    class="column-center"
    style="
        gap: 8px;
        background-color: rgb(191, 191, 219);
        padding-top: 12px;
        padding-bottom: 12px;
        bottom: 0;
    "
>
    @if (showWhatsappButton) {
    <app-footer-whatsapp-button></app-footer-whatsapp-button>
    }
    <a href="https://laenk.de/impressum/" target="_blank" class="no-underline"
        >Impressum</a
    >
    <a
        href="https://laenk.de/datenschutzerklaerung/"
        target="_blank"
        class="no-underline"
        >Datenschutz</a
    >
    <a href="mailto:info@laenk.de" class="no-underline">Kontakt</a>
</footer>
