import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "app-match-applied-card-status",
    standalone: true,
    imports: [CommonModule, MatStepperModule, MatIconModule],
    templateUrl: "./match-applied-card-status.component.html",
    styleUrl: "./match-applied-card-status.component.scss",
})
export class MatchAppliedCardStatusComponent {
    @Input() currentStageString!: string;

    currentStage: number = 0;

    isAborted: boolean = false;

    stages = [
        { label: "Interessiert", icon: "bookmark_added" },
        { label: "Beworben", icon: "send" },
        { label: "Interview", icon: "phone_in_talk" },
        { label: "Eingestellt", icon: "assignment_turned_in" },
    ];

    ngOnInit() {
        switch (this.currentStageString) {
            case "INTERESTED":
                this.currentStage = 0;
                break;
            case "FORWARDED" || "FIRST_CONTACT":
                this.currentStage = 1;
                break;
            case "INTERVIEW" || "HOSPITATION" || "SHADOWING":
                this.currentStage = 2;
                break;
            case "ACCEPTED":
                this.currentStage = 3;
                break;
            case "ABORTED":
                this.isAborted = true;
                break;
        }
    }
}
