import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Applier } from "src/app/model";
import { MatIconModule } from "@angular/material/icon";
import {
    getShiftHoursNumberFromString,
    onlyUnique,
} from "src/shared/util/utilFunctions";
import { ConstantsService } from "src/shared/services/constants.service";
import { ButtonModule } from "primeng/button";
import { UserService } from "src/shared/services/user.service";
import { AnalyticsService } from "src/shared/services/analytics.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-applicant-card",
    standalone: true,
    imports: [CommonModule, MatIconModule, ButtonModule],
    templateUrl: "./applicant-card.component.html",
    styleUrl: "./applicant-card.component.scss",
})
export class ApplicantCardComponent {
    @Input() applier!: Applier;

    positionName: string = "";
    careTypeName: string = "";
    shiftName: string = "";

    subscription!: Subscription;

    workingTypeName: string = "";

    constructor(
        private constantsService: ConstantsService,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) { }

    ngOnInit() {
        this.subscription = this.constantsService.constants$.subscribe((constants) => {
            if (!constants) {
                return;
            }
            // position
            if (
                this.applier.positionType.length > 0 &&
                this.applier.positionType[0] in constants.positions
            ) {
                this.positionName =
                    constants.positions[this.applier.positionType[0]];
            } else {
                this.positionName = "";
            }

            // caretype
            if (
                this.applier.careType.length > 0 &&
                this.applier.careType[0] in constants.extendedCareType
            ) {
                var careTypeNamesArray = this.applier.careType
                    .map((careType) => {
                        var parentGroup =
                            constants.extendedCareType[careType]?.parentLabel;
                        if (parentGroup) {
                            return parentGroup;
                        } else {
                            return constants.extendedCareType[careType].label;
                        }
                    })
                    .filter(onlyUnique)
                    .join(", ");
                this.careTypeName = careTypeNamesArray;
            } else {
                this.careTypeName = "";
            }

            // shift
            if (
                this.applier.shiftHour.length > 0 &&
                this.applier.shiftHour[0] in constants.shiftHours
            ) {
                var helper = this.applier.shiftHour
                    .map((shiftHour) => {
                        return constants.shiftHours[shiftHour];
                    })
                    .sort(
                        (a, b) =>
                            getShiftHoursNumberFromString(a) -
                            getShiftHoursNumberFromString(b)
                    )
                    .map((val) => getShiftHoursNumberFromString(val))
                    .join(", ");

                this.shiftName = `${helper} Stunden Dienste`;
            } else {
                this.shiftName = "";
            }

            // working type
            if (
                this.applier.workingType.length > 0 &&
                this.applier.workingType[0] in constants.workingType
            ) {
                this.workingTypeName =
                    constants.workingType[this.applier.workingType[0]];
            } else {
                this.workingTypeName = "";
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    editProfile() {
        var extId = this.userService.getExtId();
        this.analyticsService.trackEvent("edit-profile", {
            applier_extId: extId,
        });
        window.open(
            `https://api.whatsapp.com/send?phone=+497219588259&text=Liebes%20l%C3%A4nk%20Team,%20Ich%20%28${extId}%29%20m%C3%B6chte%20gerne%20mein%20Profil%20bearbeiten%3A`,
            "_blank"
        );
    }
}
