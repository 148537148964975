import { Injectable } from "@angular/core";
import { MatchService } from "./match.service";
import { ListFilterService } from "./list-filter.service";
import { ConstantsService } from "./constants.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { Match, MatchLists, GroupedMatch, Applier } from "src/app/model";
import { getMatchProperties } from "../util/utilFunctions";
import {
    FilterSortCriteria,
    getAllSelectedIds,
} from "src/app/model/homeListFilter";

const notAppliedStates = ["NEW"];
export const filterApplied = (match: Match) =>
    match.stage && !notAppliedStates.includes(match.stage);
export const filterNonApplied = (match: Match) => !filterApplied(match);

@Injectable({
    providedIn: "root",
})
export class MatchListService {
    isInitialized: boolean = false;

    private matchLists$: BehaviorSubject<MatchLists> =
        new BehaviorSubject<MatchLists>({
            allOpen: [],
            applied: [],
            topMatches: [],
            alternatives: [],
        });

    public get matchLists() {
        return this.matchLists$.asObservable();
    }

    public get valueReference() {
        return structuredClone(this.matchLists$.getValue());
    }

    public get totalCount() {
        return this.matchLists$.value.allOpen.length;
    }

    public get filteredCount() {
        return (
            this.matchLists$.value.topMatches.reduce(
                (acc, group) => acc + group.matches.length,
                0
            ) +
            this.matchLists$.value.alternatives.reduce(
                (acc, group) => acc + group.matches.length,
                0
            )
        );
    }

    constructor(
        private matchService: MatchService,
        private filterService: ListFilterService,
        private constantsService: ConstantsService
    ) {
        combineLatest([
            this.matchService.matchApplier$,
            this.constantsService.constants$,
        ]).subscribe(([matchApplierValue, constants]) => {
            if (matchApplierValue && constants) {
                // Get match properties
                var allMatches = matchApplierValue.matches;
                allMatches.forEach((match) => {
                    match.matchProperties = getMatchProperties(
                        match,
                        matchApplierValue.applier,
                        constants
                    );
                });

                var allMatches = this.fitIndexAssignmentInitial(
                    allMatches,
                    matchApplierValue.applier
                );

                // Split matches into open and applied
                var openMatches = allMatches.filter(filterNonApplied);
                var appliedList = allMatches.filter(filterApplied);

                var newObject: MatchLists = {
                    allOpen: openMatches,
                    applied: appliedList,
                    topMatches: [],
                    alternatives: [],
                };

                newObject = this.process(newObject, matchApplierValue.applier);

                this.isInitialized = true;
                this.matchLists$.next(newObject);
            }
        });

        combineLatest([
            this.matchService.matchApplier$,
            this.filterService.filtersSubject$,
        ]).subscribe(([matchApplierValue, _]) => {
            if (matchApplierValue?.applier) {
                var newObject = this.process(
                    this.valueReference,
                    matchApplierValue?.applier
                );
                this.matchLists$.next(newObject);
            }
        });
    }

    /**
     * Processes the match lists and returns the new `MatchLists` object.
     * The processing includes filtering, sorting, and grouping the matches.
     *
     * @param oldObject - The original `MatchLists` object to be processed.
     * @returns A new `MatchLists` object with the processed matches.
     */
    process(oldObject: MatchLists, applier: Applier): MatchLists {
        var filters = this.filterService.filterSubjectValue;
        let newList;
        if (this.filterService.initialized) {
            var sortedAllProp = this.sortLists(oldObject.allOpen);
            var sortedAll = [...sortedAllProp];

            newList = sortedAll
                .filter((match) => this.filterCareTypes(match, filters))
                .filter((match) => this.filterPosition(match, filters))
                // .filter((match) => this.filterShiftType(match, filters))
                .filter((match) =>
                    this.filterDistance(
                        match,
                        this.filterService.filterSubjectValue
                    )
                );
        } else {
            newList = oldObject.allOpen;
        }

        let [topMatches, alternatives] = this.splitLists(newList, applier);

        var newObject: MatchLists = {
            ...oldObject,
            topMatches: this.groupMatchesByCompany(topMatches),
            alternatives: this.groupMatchesByCompany(alternatives),
        };

        return newObject;
    }

    /**
     * Assigns a fit index to the matches based on their fit probability.
     * The matches are grouped by company name, and the fit index is assigned to each group.
     *
     * @param matches - The array of matches to assign the fit index to.
     * @returns The array of matches with the fit index assigned.
     */
    private fitIndexAssignmentInitial(matches: Match[], applier: Applier) {
        let [topMatches, alternatives] = this.splitLists(matches, applier);

        topMatches = topMatches.sort(
            (a, b) => b.fitProbability - a.fitProbability
        );

        var topMatchesGrouped = this.groupMatchesByCompany(topMatches);

        // Assign the groups fit index to the matches
        topMatchesGrouped.forEach((group, index) => {
            group.matches.forEach((match) => {
                match.fitIndex = index + 1;
            });
        });

        // ungroup the matches and combine in one array
        var ungrouped = topMatchesGrouped.flatMap((group) => group.matches);

        var finalList = ungrouped.concat(alternatives);
        return finalList;
    }

    /**
     * Groups an array of matches by company name.
     *
     * @param matches - The array of matches to be grouped.
     * @returns An array of grouped matches, where each group contains matches belonging to the same company.
     */
    private groupMatchesByCompany(matches: Match[]): GroupedMatch[] {
        const grouped: GroupedMatch[] = [];
        let currentGroup: GroupedMatch | null = null;

        for (const match of matches) {
            if (!currentGroup || currentGroup.company !== match.company.name) {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = {
                    company: match.company.name,
                    matches: [match],
                    fitIndex: match.fitIndex || 0,
                };
            } else {
                currentGroup.matches.push(match);
                currentGroup.fitIndex = Math.min(
                    currentGroup.fitIndex,
                    match.fitIndex || 0
                );
            }
        }

        if (currentGroup) {
            grouped.push(currentGroup);
        }

        return grouped;
    }

    filterCareTypes(match: Match, filters: FilterSortCriteria) {
        const careType = match.job.careType[0];
        var selected = getAllSelectedIds(filters.careTypes);
        return selected.includes(careType);
    }

    filterPosition(match: Match, filters: FilterSortCriteria) {
        const position = match.job.position[0];
        var selected = getAllSelectedIds(filters.positions);
        return selected.includes(position);
    }

    filterShiftType(match: Match, filters: FilterSortCriteria) {
        const shiftType = match.job.workingHours
            .filter((wh) => wh != null && wh.shiftType != undefined)
            .map((wh) => wh!.shiftType);
        const shiftTypeSet = new Set(shiftType);

        // return false if any of the matches shift types are not in the filter
        for (let shift of shiftTypeSet) {
            if (!filters.shiftTypes.has(shift)) {
                return false;
            }
        }
        return true;
    }

    filterDistance(match: Match, filters: FilterSortCriteria) {
        return match.distance <= filters.maxDistance;
    }

    splitLists(list: Match[], applier: Applier): [Match[], Match[]] {
        const splitCondition = (match: Match) => match.fitProbability > 0.5;

        let firstList = list.filter(splitCondition);
        let secondList = list.filter((match) => !splitCondition(match));

        return [firstList, secondList];
    }

    sortLists(listProp: Match[]): Match[] {
        var list = [...listProp];
        var sortMethod = this.filterService.filterSubjectValue.sort;

        switch (sortMethod) {
            case "fit":
                return this.sortByFit(list);
            case "distance":
                return this.sortByDistance(list);
        }
    }

    sortByFit(list: Match[]): Match[] {
        list.sort((a, b) => b.fitProbability - a.fitProbability);
        return list;
    }

    sortByDistance(list: Match[]): Match[] {
        list.sort((a, b) => a.distance - b.distance);
        return list;
    }

    fetchMatches(extId: string) {
        this.matchService.getMatchApplierData(extId);
    }
}
