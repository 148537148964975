<div class="column" style="gap: 8px">
    <div *ngIf="salaryString !== null">
        <p class="mid-header">Bruttomonatsgehalt inkl. aller Zulagen:</p>
        <p>
            {{ salaryString }}
        </p>
    </div>
    <div *ngIf="tarifString !== null">
        <p class="mid-header">Tarif:</p>
        <p>
            {{ tarifString }}
        </p>
    </div>
    <div *ngIf="overTimeRegulationString !== null">
        <p class="mid-header">Überstunden:</p>
        <p>
            {{ overTimeRegulationString }}
        </p>
    </div>
    <div *ngIf="otherSalaryOptions.length > 0">
        <p class="mid-header">Weiteres:</p>
        <div *ngFor="let option of otherSalaryOptions">
            {{ option }}
        </div>
    </div>
</div>
