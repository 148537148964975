import { CommonModule } from "@angular/common";
import { Component, HostListener, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, combineLatest } from "rxjs";
import { Applier, Match } from "src/app/model";
import {
    Constants,
    ConstantsService,
} from "src/shared/services/constants.service";
import { MatchListService } from "src/shared/services/match-list.service";
import { MatchService } from "src/shared/services/match.service";
import { NavigationService } from "src/shared/services/navigation.service";
import { UserService } from "src/shared/services/user.service";
import {
    MatchProperties,
    getAvailableSize,
    getMatchProperties,
} from "src/shared/util/utilFunctions";
import { JobNotfoundComponent } from "../../job/job-notfound/job-notfound.component";
import { MatchDetailsApplyButtonComponent } from "./match-details-apply-button/match-details-apply-button.component";
import { MatchDetailsContentComponent } from "./match-details-content/match-details-content.component";
@Component({
    selector: "app-match-details",
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatToolbarModule,
        MatchDetailsContentComponent,
        MatchDetailsApplyButtonComponent,
        MatProgressSpinnerModule,
        JobNotfoundComponent,
        MatButtonModule,
    ],
    templateUrl: "./match-details.component.html",
    styleUrls: ["./match-details.component.scss"],
})
export class MatchDetailsComponent {
    @Input() match: Match | null = null;
    @Input() applier: Applier | null = null;
    @Input() isPreview?: boolean;
    private subscription?: Subscription;
    constantsSubscription?: Subscription;

    matchProperties?: MatchProperties;
    scrollPosition = 0;

    constants: Constants | null = null;

    contentOpacity = 1;

    titleOpacity = 0;

    isLoading: boolean = false;

    readonly minHeaderHeight: number = 56; // Minimum header height
    readonly headerStartHeight: number = 250; // Minimum header height

    constructor(
        private route: ActivatedRoute,
        private matchService: MatchService,
        private titleService: Title,
        private matchListService: MatchListService,
        private meta: Meta,
        private router: Router,
        private userService: UserService,
        private navigationService: NavigationService,
        private constantsService: ConstantsService
    ) { }

    ngOnInit() {
        if (!this.isPreview) {
            this.isLoading = true;
            var params = this.route.snapshot.paramMap;
            let extId = params.get("extId");
            let matchExtId = params.get("matchExtId");

            var matchApplier = this.matchService.matchApplierValue;
            if (matchApplier) {
                this.isLoading = false;
                this.applier = matchApplier.applier!;
                this.match = matchApplier.matches.find(
                    (match) => match.extId == matchExtId
                )!;
            } else {
                this.matchService.getMatchApplierData(extId!);
            }

            this.subscription = combineLatest([
                this.constantsService.constants$,
                this.matchListService.matchLists,
                this.matchService.matchApplier$,
            ]).subscribe(([constants, matchLists, matchApplier]) => {
                if (constants && matchApplier) {
                    this.isLoading = false;
                    this.match = matchLists.allOpen.find(
                        (match) => match.extId == matchExtId
                    ) ?? matchLists.applied.find(
                        (match) => match.extId == matchExtId
                    )!;
                    this.applier = matchApplier.applier!;
                    this.matchProperties = getMatchProperties(
                        this.match,
                        this.applier,
                        constants
                    );
                }
                this.constants = constants;
            });
        } else {
            if (this.match && this.applier) {
                this.constantsSubscription = this.constantsService.constants$.subscribe((constants) => {
                    if (constants) {
                        this.matchProperties = getMatchProperties(
                            this.match!,
                            this.applier!,
                            constants
                        );
                    }
                });
            }
        }

        if (this.match && this.match.company && this.constants) {
            this.titleService.setTitle(
                `${this.match.company.displayName} - ${this.constants.positions[this.match.job.position[0]!]
                }`
            );
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.constantsSubscription) {
            this.constantsSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        const frame = document.querySelector(".frame") as HTMLElement;

        if (frame) {
            frame.addEventListener("scroll", this.handleScroll.bind(this));
        } else {
            window.addEventListener("scroll", this.handleScroll.bind(this));
        }
    }

    @HostListener("window:resize", [
        "$event.target.innerWidth",
        "$event.target.innerHeight",
    ])
    handleFrameDisplay(width: number, height: number) {
        const usesFrame = width > 600;

        const bottomElement: HTMLElement | null =
            document.querySelector(".floating-button");

        const leftElement: HTMLElement | null =
            document.querySelector(".header-scrolled");
        if (!leftElement) {
            return;
        }

        if (usesFrame) {
            // get the frame element
            const frame = document.querySelector(".frame") as HTMLElement;
            if (!frame) {
                return;
            }

            // get the frame width and heightq
            const rect = frame.getBoundingClientRect();
            var frameWidth = rect.width;
            const frameHeight = rect.height;

            var widthDiff = width - frameWidth;
            var newWidthDiff = widthDiff / 2;
            const heightDiff = height - frameHeight;
            if (bottomElement) {
                bottomElement.style.bottom = `${heightDiff}px`;
            }

            if (!leftElement.hasAttribute("data-transformed")) {
                leftElement.setAttribute("data-transformed", "true");
            }
        } else {
            // no frame, mobile view
            if (bottomElement) {
                bottomElement.style.bottom = ``;
            }

            var frameWidth = width;
            var newWidthDiff = 0;
        }

        leftElement.style.left = `${newWidthDiff}px`;
        leftElement.style.width = `${frameWidth}px`;
    }

    ngAfterViewChecked() {
        this.handleFrameDisplay(window.innerWidth, window.innerHeight);
    }

    @HostListener("window:scroll", ["$event"])
    handleScroll(event: any) {
        const frame = getAvailableSize().usesFrame;

        if (frame) {
            const frame = document.querySelector(".frame") as HTMLElement;
            this.scrollPosition = frame.scrollTop;
        } else {
            this.scrollPosition = window.scrollY;
        }

        this.contentOpacity =
            1 -
            this.scrollPosition /
            (this.headerStartHeight - this.minHeaderHeight);

        if (this.scrollPosition > 170) {
            this.titleOpacity = Math.min(30, this.scrollPosition - 170) / 30;
        } else {
            this.titleOpacity = 0;
        }
        this.updateContentMetaTag();
    }

    updateContentMetaTag() {
        const start = 0;
        const end = 194;
        var a = Math.min(Math.max(start, this.scrollPosition), end);
        var ratio = (a - start) / (end - start);

        // RGB values for white and #6633ff
        const startColor = { r: 228, g: 228, b: 242 };
        const endColor = { r: 191, g: 191, b: 219 };

        // Calculate the interpolated RGB values
        const r = startColor.r + ratio * (endColor.r - startColor.r);
        const g = startColor.g + ratio * (endColor.g - startColor.g);
        const b = startColor.b + ratio * (endColor.b - startColor.b);
        this.meta.updateTag({
            name: "theme-color",
            content: `rgb(${r}, ${g}, ${b})`,
        });
    }

    getOverlayBackgroundStyle() {
        const opacity =
            this.scrollPosition /
            (this.headerStartHeight - this.minHeaderHeight);
        // return `rgba(101, 62, 255, ${opacity})`;
        return `rgba(191, 191, 219, ${opacity})`;
    }
    getOverlayBorderStyle() {
        const opacity =
            this.scrollPosition /
            (this.headerStartHeight - this.minHeaderHeight);
        // return `rgba(101, 62, 255, ${opacity})`;
        return `1px solid rgba(0, 0, 0, 0.12);`;
    }

    getBackButtonTop() {
        const dist = 42;
        var a = Math.min(dist, this.scrollPosition);
        var ratio = 1 - (dist - a) / dist;
        return `${42 - ratio * dist}px`;
    }

    getBackButtonLeft() {
        const start = 42;
        const end = 70;
        var a = Math.min(Math.max(start, this.scrollPosition), end);
        var ratio = (a - start) / (end - start);

        var base = 20;
        const availableSize = getAvailableSize();
        const usesFrame = availableSize.usesFrame;

        if (usesFrame) {
            const frameWidth = availableSize.relevantWidth;
            const frameHeight = availableSize.relevantHeight;
            const widthDiff = window.innerWidth - frameWidth;
            const heightDiff = window.innerHeight - frameHeight;
            var newDiff = widthDiff / 2;
            base = base + newDiff;
        }
        return `${base - ratio * 20}px`;
    }

    getBackButtonBackgroundColor() {
        const start = 70;
        const end = 100;
        var a = Math.min(Math.max(start, this.scrollPosition), end);
        var ratio = (a - start) / (end - start);
        return `rgba(228, 228, 242, ${0.8 - ratio * 0.8} )`;
    }

    getBackButtonForegroundColor() {
        const start = 70;
        const end = 100;
        var a = Math.min(Math.max(start, this.scrollPosition), end);
        var ratio = 0;

        // RGB values for white and #6633ff
        const white = { r: 100, g: 100, b: 100 };
        const color6633ff = { r: 102, g: 51, b: 255 };

        // Calculate the interpolated RGB values
        const r = white.r + ratio * (color6633ff.r - white.r);
        const g = white.g + ratio * (color6633ff.g - white.g);
        const b = white.b + ratio * (color6633ff.b - white.b);

        // Assuming you want to keep the alpha transition as in the original function
        const alpha = 0.8 + ratio * 0.2;

        return `rgba(${Math.round(r)}, ${Math.round(g)}, ${Math.round(
            b
        )}, ${alpha})`;
    }

    goBack() {
        const previousUrl = this.navigationService.getPreviousUrl();

        // previousUrl includes /match/ when user was redirected to different extId by backend
        // therefore window.history.back() would redirect to same match
        if (previousUrl && !previousUrl.includes('/match/')) {
            window.history.back();
        } else {
            this.router.navigate([`${this.userService.getExtId()}`], {
                queryParamsHandling: "preserve",
            });
        }
    }
}
