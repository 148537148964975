import { Component, ElementRef, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Applier, Company, GroupedMatch, Match } from "src/app/model";
import { Router } from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatchCardContentComponent } from "./match-card-content/match-card-content.component";
import { UserService } from "src/shared/services/user.service";
import { MatchService } from "src/shared/services/match.service";
import { MatchCardFitPositionComponent } from "src/app/modules/match/match-card/match-card-fit-position/match-card-fit-position.component";
import { AnalyticsService } from "src/shared/services/analytics.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-match-card",
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatchCardContentComponent,
        MatRippleModule,
        MatIconModule,
        MatchCardFitPositionComponent,
    ],
    templateUrl: "match-card.component.html",
    styleUrls: ["./match-card.component.scss"],
})
export class MatchCardComponent {
    @Input() groupedMatch!: GroupedMatch;
    @Input() applier!: Applier;

    matches!: Match[];

    company!: Company;

    constructor(
        private router: Router,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private matchService: MatchService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.matches = this.groupedMatch.matches;
        this.company = this.groupedMatch.matches[0].company;
    }

    /**
     * Opens the match details for a given external ID and tracks the event with the analytics service.
     *
     * @param extId - The external ID of the match.
     * @returns void
     */
    openMatchDetails(extId: string): void {
        var source = "card";

        var listPosition = this.matchService.getMatchListPosition(extId);
        if (listPosition) {
            // listPosition is 0-based, but we want to start at 1
            listPosition += 1;
        }

        this.analyticsService.trackEvent("match-details-clicked", {
            match_extId: extId,
            applier_extId: this.userService.getExtId(),
            listPosition: listPosition,
            source: source,
        });

        this.router.navigate([`${this.userService.getExtId()}/match/${extId}`]);
    }

    /**
     * Transforms a date object into a string formatted as "dd.mm.yyyy".
     *
     * @param dateProp - The date object to transform.
     * @returns The formatted date string.
     */
    transformDate(dateProp: Date): string {
        const date = new Date(dateProp);
        return date.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "numeric",
        });
    }

    /**
     * Displays a snackbar hint to the user that they can click on the individual job to open it.
     *
     * @returns void
     */
    displayGroupedJobHint() {
        this.snackBar.open(
            "Klicke auf den einzelnen Job, um diesen zu öffnen",
            "Schließen",
            {
                duration: 4000,
            }
        );
    }
}
