import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-match-details-content-benefits",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./match-details-content-benefits.component.html",
    styleUrl: "./match-details-content-benefits.component.scss",
})
export class MatchDetailsContentBenefitsComponent {
    @Input() benefits!: string[];
}
