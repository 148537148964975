import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    private previousUrl?: string;
    private currentUrl: string;

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                if (this.currentUrl != event.url) {
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = event.url;
                }
            });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }
}
