<div class="column registered-content">
    <mat-icon fontIcon="check_circle" class="sized-icon" />
    <p class="message">
        Hej {{ applier.user.firstName ?? "" }} - du hast dich erfolgreich
        registriert!
    </p>
    <p class="sub-message">
        Ab jetzt kannst du dich mit einem einzigen Klick auf Stellen bewerben.
        Außerdem haben wir dir eine Mail geschickt, über die du immer wieder
        später hierhin zurückkehren kannst.
    </p>
    <p-button
        [style]="{ padding: '15px 30px 15px 30px' }"
        icon="pi pi-arrow-right"
        label="Weiter nach Jobs suchen"
        (onClick)="goBack()"
    />
</div>
