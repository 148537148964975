import { SHA256, enc } from "crypto-js";

export class AnonymousNames {
    static hasher = SHA256;
    static names = new Map<number, string>([
        [1, "Anonymer Salamandär"],
        [2, "Anonymer Dälfin"],
        [3, "Anonymer Älch"],
        [4, "Anonyme Flädermaus"],
        [5, "Anonymer Ärdmolch"],
        [6, "Anonymes Nilpfärd"],
        [7, "Anonyme Nacktschnäcke"],
        [8, "Anonyme Bärgziege"],
        [9, "Anonymer Tigär"],
        [10, "Anonymer Zwärgspitz"],
        [11, "Anonymer Häring"],
        [12, "Anonymer Lämming"],
        [13, "Anonymer Wäls"],
        [14, "Anonymer Hängst"],
        [15, "Anonymer Spärling"],
        [16, "Anonymes Ärdmännchen"],
    ]);

    public static getAnonymousName(extId: string): string {
        const digest = AnonymousNames.hasher(enc.Utf8.parse(extId));
        const bytes = digest.words.map((word) => (word < 0 ? -word : word)); // Convert words to bytes
        const namesLength = AnonymousNames.names.size;
        const constantIndex = (bytes[0] % namesLength) + 1; // +1 because Map is 1-indexed in this case

        return (
            AnonymousNames.names.get(constantIndex) ??
            AnonymousNames.names.get(1)!
        );
    }
}
