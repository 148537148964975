import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-match-details-content-card",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./match-details-content-card.component.html",
    styleUrls: ["./match-details-content-card.component.scss"],
})
export class MatchDetailsContentCardComponent {
    @Input() title!: string;
}
