<div class="column" style="gap: 16px; margin-bottom: 56px; margin-top: 16px">
    <!-- Benefits -->
    <app-company-benefits
        [companyBenefits]="companyBenefits"
        *ngIf="companyBenefits.length > 0"
    />

    <!-- Education -->
    <app-match-details-content-card title="Anforderungen" *ngIf="showEducation">
        <app-match-details-content-education
            [educationType]="match?.job?.educationType"
            [continuingEducation]="match?.job?.continuingEducation"
            [drivingLicense]="match?.job?.drivingLicense"
            [otherRequirements]="match?.job?.otherRequirements"
            [workExperience]="match?.job?.workExperience"
        >
        </app-match-details-content-education>
    </app-match-details-content-card>

    <!-- Location -->
    <app-match-details-content-card title="Ort">
        <app-match-details-content-map [match]="match" *ngIf="match">
        </app-match-details-content-map>
    </app-match-details-content-card>

    <!-- Arbeitszeiten -->
    <app-match-details-content-card
        title="Arbeitszeiten"
        *ngIf="showWorkingHours"
    >
        <app-match-details-content-workinghours
            [workingHours]="match?.job?.workingHours"
            [shifts]="match?.job?.shift"
            [shiftOther]="match?.job?.shiftOther"
        ></app-match-details-content-workinghours>
    </app-match-details-content-card>

    <!-- Salary -->
    <app-match-details-content-card title="Bezahlung" *ngIf="showSalary">
        <app-match-details-content-salary [match]="match" />
    </app-match-details-content-card>

    <!-- Benefits -->
    <app-match-details-content-card title="Leistungen" *ngIf="showBenefits">
        <app-match-details-content-benefits
            [benefits]="match?.job?.benefits!"
        />
    </app-match-details-content-card>

    <!-- Onboarding -->
    <app-match-details-content-card title="Einarbeitung" *ngIf="showOnboarding">
        <div [innerHTML]="match?.job?.onboarding!"></div>
    </app-match-details-content-card>

    <!-- Notes -->
    <app-match-details-content-card title="Andere Details" *ngIf="showNotes">
        <div [innerHTML]="notesCleanString" ></div>
    </app-match-details-content-card>

    <!-- About -->
    <app-match-details-content-card title="Über uns" *ngIf="showAbout">
        <div class="column" style="gap: 12px">
            <div [innerHTML]="aboutCleanString"></div>
            <div *ngIf="youtubeVideoId !== null && isYoutube">
                <app-video-embed
                    [youtubeVideoId]="youtubeVideoId!"
                    [isYoutube]="isYoutube"
                />
            </div>
            <div *ngIf="!isYoutube && videoUrl != null" class="center">
                <button pButton (click)="openNonYoutubeLink()">
                    Video anschauen
                </button>
            </div>
        </div>
    </app-match-details-content-card>

    <!-- Culture -->
    <app-match-details-content-card
        title="Das macht uns besonders"
        *ngIf="showCulture"
    >
        <div [innerHTML]="match?.company?.culture!"></div>
    </app-match-details-content-card>

    <!-- Gallery -->
    <app-match-details-content-card title="Galerie" *ngIf="images.length > 0">
        <p-galleria
            [value]="images"
            [containerStyle]="{}"
            [showThumbnails]="false"
            [showIndicators]="showIndicator"
            [(activeIndex)]="activeIndex"
        >
            <ng-template pTemplate="item" let-item>
                <button
                    (click)="prev()"
                    class="arrow-button left"
                    color="white"
                    mat-icon-button
                >
                    <mat-icon>arrow_back_ios_new</mat-icon>
                </button>
                <div class="image-container">
                    <img [src]="item.itemImageSrc" class="responsive-image" />
                </div>

                <button
                    (click)="next()"
                    class="arrow-button right"
                    color="white"
                    mat-icon-button
                >
                    <mat-icon>arrow_forward_ios</mat-icon>
                </button>
            </ng-template>
        </p-galleria>
    </app-match-details-content-card>
</div>
