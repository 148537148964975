import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Match } from "../../../../../model";
import { CurrencyPipe } from "@angular/common";

@Component({
    selector: "app-match-details-content-salary",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./match-details-content-salary.component.html",
    styleUrl: "./match-details-content-salary.component.scss",
    providers: [CurrencyPipe],
})
export class MatchDetailsContentSalaryComponent {
    @Input() match?: Match | null;

    minSalary: string | null = null;
    maxSalary: string | null = null;
    salaryString: string | null = null;

    overTimeRegulationString: string | null = null;

    tarifString: string | null = null;

    otherSalaryOptions: string[] = [];

    constructor(private currencyPipe: CurrencyPipe) {}
    ngOnInit() {
        if (this?.match?.job?.minSalary) {
            this.minSalary = this.formatSalary(this?.match?.job?.minSalary);
        }
        if (this?.match?.job?.maxSalary) {
            this.maxSalary = this.formatSalary(this?.match?.job?.maxSalary);
        }

        if (this.maxSalary == this.minSalary) {
            this.maxSalary = null;
        }

        if (this.minSalary) {
            if (this.maxSalary) {
                this.salaryString = `${this.minSalary ?? ""} ${
                    this.maxSalary ? " bis " + this.maxSalary : ""
                }`;
            } else {
                this.salaryString = this.minSalary;
            }
        }

        if (this.match?.company.tarif) {
            this.tarifString = this.match.company.tarif;
        }

        if (this.match?.job.salaryOptions) {
            this.otherSalaryOptions = this.match.job.salaryOptions;
        }

        if (this.match?.company.overtimeRegulation) {
            this.overTimeRegulationString =
                this.match?.company?.overtimeRegulation!;
        }
    }

    formatSalary(salary: number | null): string {
        return (
            this.currencyPipe.transform(
                salary ?? 0,
                "EUR",
                "symbol",
                "1.0-0"
            ) || ""
        );
    }
}
