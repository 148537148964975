export interface User {
    externalId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    birthDate?: string;
    phoneNumber?: string;
    whatsappNumber?: string;
    notificationNumber?: string;
    gender?: number;
    role?: number;
    salutation?: string;
    profilePictureUrl?: string;
    socialAccount: number;
}

export function mapUser(userData: any): User {
    return {
        externalId: userData.external_id || "",
        firstName: userData.first_name || "",
        lastName: userData.last_name || "",
        email: userData.email || "",
        birthDate: userData.birth_date || "",
        phoneNumber: userData.phone_number || "",
        whatsappNumber: userData.whatsapp_number || "",
        notificationNumber: userData.notification_number || "",
        gender: userData.gender || 0,
        role: userData.role || 0,
        salutation: userData.salutation || "",
        profilePictureUrl: userData.profile_picture_url || "",
        socialAccount: userData.social_account || 0,
    };
}

export function getDummyUser(): User {
    return {
        socialAccount: 0,
    };
}
