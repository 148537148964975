import { Component, ViewEncapsulation } from "@angular/core";
import {
    NavigationStart,
    Router,
    Scroll,
    NavigationEnd,
} from "@angular/router";
import { filter } from "rxjs";
import { ConstantsService } from "src/shared/services/constants.service";
import posthog from "posthog-js";
import { AnalyticsService } from "../shared/services/analytics.service";
import { frameWidth } from "src/shared/util/utilFunctions";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-root",
    encapsulation: ViewEncapsulation.None,
    template: `
        <!-- <main [@routeAnimations]="prepareRoute(outlet)"> -->
        <div class="frame">
            <!-- Rest of your HTML goes here -->
            <div
                style="display: flex; justify-content: center; align-items: start; width: 100%;"
            >
                <router-outlet #outlet="outlet" />
            </div>
        </div>
    `,
    // animations: [slider],
    styleUrls: ["./app.component.css"],
})
export class AppComponent {
    // prepareRoute(outlet: RouterOutlet) {
    //     return outlet.activatedRouteData["animation"];
    // }

    constructor(
        private analyticsService: AnalyticsService, // keep this line to init the service
        private constants: ConstantsService, // keep this line to init the service
        private router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        // Navigation and scroll handling
        let navigationTrigger: string;
        this.router.events
            .pipe(filter((e) => e instanceof NavigationStart))
            .subscribe((e: any) => {
                navigationTrigger = e.navigationTrigger;
            });

        this.router.events
            .pipe(filter((e) => e instanceof Scroll))
            .subscribe((e: any) => {
                if (navigationTrigger === "imperative" && !e.anchor) {
                    const el =
                        window.innerWidth >= frameWidth
                            ? (document.querySelector(".frame") as HTMLElement)
                            : window;
                    el.scrollTo(0, 0);
                }
            });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                posthog.capture("$pageview");
            }
        });

        this.matIconRegistry.addSvgIcon(
            "care_type_fit",
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                "assets/icons/care_type_fit.svg"
            )
        );
        this.matIconRegistry.addSvgIcon(
            "location_fit",
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                "assets/icons/location_fit.svg"
            )
        );
        this.matIconRegistry.addSvgIcon(
            "position_fit",
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                "assets/icons/position_fit.svg"
            )
        );
    }
}
