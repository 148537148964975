import * as i0 from '@angular/core';
import { Directive, Optional, Input, HostListener, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/forms';
import * as i2 from 'primeng/api';

/**
 * InputText directive is an extension to standard input element with theming.
 * @group Components
 */
class InputText {
  el;
  ngModel;
  cd;
  config;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant = 'outlined';
  filled;
  constructor(el, ngModel, cd, config) {
    this.el = el;
    this.ngModel = ngModel;
    this.cd = cd;
    this.config = config;
  }
  ngAfterViewInit() {
    this.updateFilledState();
    this.cd.detectChanges();
  }
  ngDoCheck() {
    this.updateFilledState();
  }
  onInput() {
    this.updateFilledState();
  }
  updateFilledState() {
    this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length || this.ngModel && this.ngModel.model;
  }
  static ɵfac = function InputText_Factory(ɵt) {
    return new (ɵt || InputText)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.NgModel, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i2.PrimeNGConfig));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: InputText,
    selectors: [["", "pInputText", ""]],
    hostAttrs: [1, "p-inputtext", "p-component", "p-element"],
    hostVars: 4,
    hostBindings: function InputText_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("input", function InputText_input_HostBindingHandler($event) {
          return ctx.onInput($event);
        });
      }
      if (rf & 2) {
        i0.ɵɵclassProp("p-filled", ctx.filled)("p-variant-filled", ctx.variant === "filled" || ctx.config.inputStyle() === "filled");
      }
    },
    inputs: {
      variant: "variant"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputText, [{
    type: Directive,
    args: [{
      selector: '[pInputText]',
      host: {
        class: 'p-inputtext p-component p-element',
        '[class.p-filled]': 'filled',
        '[class.p-variant-filled]': 'variant === "filled" || config.inputStyle() === "filled"'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1.NgModel,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i2.PrimeNGConfig
  }], {
    variant: [{
      type: Input
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }]
  });
})();
class InputTextModule {
  static ɵfac = function InputTextModule_Factory(ɵt) {
    return new (ɵt || InputTextModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: InputTextModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputTextModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [InputText],
      declarations: [InputText]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputText, InputTextModule };
