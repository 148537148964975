import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Match } from "src/app/model";
import { MatchDetailsContentEducationComponent } from "../match-details/match-details-content/match-details-content-education/match-details-content-education.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatchService } from "src/shared/services/match.service";
import { Router } from "@angular/router";
import { UserService } from "src/shared/services/user.service";
import { MatButtonModule } from "@angular/material/button";
import { ButtonModule } from "primeng/button";
import { AnalyticsService } from "src/shared/services/analytics.service";

@Component({
    selector: "app-match-confirmation-dialog",
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        MatchDetailsContentEducationComponent,
        MatDividerModule,
    ],
    templateUrl: "./match-confirmation-dialog.component.html",
    styleUrl: "./match-confirmation-dialog.component.scss",
})
export class MatchConfirmationDialogComponent {
    @Input() match!: Match;

    @Output() closeDialog = new EventEmitter<void>();

    @Input() afterRegistration: boolean = false;

    showEducation!: boolean;

    loading: boolean = false;

    constructor(
        private matchService: MatchService,
        private router: Router,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit() {
        let hasUnmetRequirements: boolean = false;

        var applier = this.matchService.matchApplierValue?.applier;

        if (this.match?.job?.continuingEducation) {
            hasUnmetRequirements = this.match.job.continuingEducation.some(
                (requirement) =>
                    !applier?.continuingEducation.includes(
                        requirement.continuingEducation
                    )
            );
        }

        this.showEducation =
            hasUnmetRequirements ||
            !!this.match?.job?.drivingLicense ||
            !!this.match.job.otherRequirements;
    }

    close() {
        this.closeDialog.emit();
    }

    accept() {
        this.loading = true;

        this.matchService.acceptMatch(this.match.extId).then(() => {
            this.loading = false;

            if (this.afterRegistration) {
                this.router.navigate([
                    `${this.userService.getExtId()}/match/${
                        this.match.extId
                    }/appliedAfterRegistration`,
                ]);
            } else {
                this.router.navigate([
                    `${this.userService.getExtId()}/match/${
                        this.match.extId
                    }/applied`,
                ]);
            }
        });

        var listPosition = this.matchService.getMatchListPosition(
            this.match.extId
        );
        if (listPosition) {
            // listPosition is 0-based, but we want to start at 1
            listPosition += 1;
        }
        this.analyticsService.trackEvent("applied", {
            match_extId: this.match.extId,
            applier_extId: this.userService.getExtId(),
            listPosition: listPosition,
        });
    }
}
