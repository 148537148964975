export type FilterSortCriteria = {
    sort: "fit" | "distance";
    careTypes: GroupedFilterType;
    maxDistance: number;
    positions: GroupedFilterType
    shiftTypes: Set<number>;
};

export type ChildConstant = {
    label: string;
    id: number;
    selected: boolean;
}
export type GroupedFilterType = {
    [parentLabel: string]: ChildConstant[];
}

export function getAllIds(groupedType: GroupedFilterType) {
    return Object.values(groupedType)
        .flatMap(children => children)
        .map(child => child.id);
}

export function getAllSelectedIds(groupedType: GroupedFilterType) {
    return Object.values(groupedType)
        .flatMap(children => children)
        .filter(child => child.selected)
        .map(child => child.id);
}
/**
 * Deeply compares two FilterSortCriteria objects for equality.
 * @param a - The first FilterSortCriteria object.
 * @param b - The second FilterSortCriteria object.
 * @returns True if both objects are equal, false otherwise.
 */
export function compareFilter(
    a: FilterSortCriteria,
    b: FilterSortCriteria
): boolean {
    // Compare the 'sort' properties
    if (a.sort !== b.sort) {
        return false;
    }

    // Compare the 'careTypes' sets
    let allA = getAllIds(a.careTypes);
    let allB = getAllIds(b.careTypes);
    let allSelectedA = getAllSelectedIds(a.careTypes);
    let allSelectedB = getAllSelectedIds(b.careTypes);


    let allPositionsA = getAllIds(a.positions);
    let allPositionsB = getAllIds(b.positions);
    let allSelectedPositionsA = getAllSelectedIds(a.positions);
    let allSelectedPositionsB = getAllSelectedIds(b.positions);



    const pairs = [[allA, allB], [allSelectedA, allSelectedB], [allPositionsA, allPositionsB], [allSelectedPositionsA, allSelectedPositionsB]];

    for (const [arrayA, arrayB] of pairs) {
        if (arrayA.length !== arrayB.length) {
            return false;
        }
        for (let item of arrayA) {
            if (!arrayB.includes(item)) {
                return false;
            }
        }
    }

    // Compare the 'workingHours' properties
    if (a.shiftTypes.size !== b.shiftTypes.size) {
        return false;
    }
    for (let hour of a.shiftTypes) {
        if (!b.shiftTypes.has(hour)) {
            return false;
        }
    }


    // Compare the 'distanceRange' properties
    if (a.maxDistance !== b.maxDistance) {
        return false;
    }

    // If all checks pass, the objects are equal
    return true;
}
