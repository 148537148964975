import { CommonModule } from "@angular/common";
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { Meta } from "@angular/platform-browser";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputTextModule } from "primeng/inputtext";
import { AnalyticsService } from "src/shared/services/analytics.service";
import { User } from "src/app/model/user";
import { MatchService } from "src/shared/services/match.service";
import { UserService } from "src/shared/services/user.service";

@Component({
    selector: "app-registration-dialog",
    standalone: true,
    imports: [
        CommonModule,
        InputTextModule,
        ButtonModule,
        CardModule,
        ReactiveFormsModule,
        FormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
    ],
    templateUrl: "./registration-dialog.component.html",
    styleUrl: "./registration-dialog.component.scss",
    encapsulation: ViewEncapsulation.None,
})
export class RegistrationDialogComponent {
    @Output() finishRegistration = new EventEmitter<User>();
    @Input() source!: "profile-avatar" | "application";
    myForm!: FormGroup;

    loading: boolean = false;

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private meta: Meta,
        private analyticsService: AnalyticsService,
        private matchService: MatchService
    ) {
        this.myForm = this.fb.group({
            firstname: ["", Validators.required],
            lastname: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            phone: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^(\\+ ?|0)[1-9][\\d ]{6,14}$"),
                ],
            ],
            terms: [false, Validators.requiredTrue],
        });
        this.meta.addTag({
            name: "viewport",
            content:
                "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0",
        });
    }

    async onSubmit() {
        if (this.myForm.valid) {
            this.loading = true;

            var extId = this.userService.getExtId();
            if (extId) {
                var newUser = await this.userService.updateUser(
                    extId,
                    this.myForm.value.firstname,
                    this.myForm.value.lastname,
                    this.myForm.value.email,
                    this.myForm.value.phone
                );

                var extIdNow = this.userService.getExtId();
                await this.matchService.getMatchApplierData(extIdNow!);
                this.loading = false;
                this.analyticsService.trackEvent("registration-finished", {
                    applier_extId: this.userService.getExtId(),
                    source: this.source,
                });
                this.finishRegistration.emit(newUser);
            } else {
                this.loading = false;
            }
        } else {
            Object.keys(this.myForm.controls).forEach((key) => {
                const control = this.myForm.get(key);
                if (control) {
                    control.markAsDirty();
                    control.markAsTouched();
                }
            });
        }
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.myForm.get(controlName);
        return (control?.status != "VALID" && control?.touched) || false;
    }
}
