import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ConstantsService } from "src/shared/services/constants.service";
import { ContinuingEducation, WorkExperience } from "../../../../../model";
import { Subscription } from "rxjs";

@Component({
    selector: "app-match-details-content-education",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./match-details-content-education.component.html",
    styleUrl: "./match-details-content-education.component.scss",
})
export class MatchDetailsContentEducationComponent {
    @Input() educationType?: number[];
    @Input()
    continuingEducation?: ContinuingEducation[];
    @Input()
    workExperience?: (WorkExperience | null)[];
    @Input()
    drivingLicense?: string | null;

    @Input() otherRequirements?: string | null;

    educationString: string = "";
    continuingEducationString: string = "";
    workExperienceString: string = "";
    drivingLicenseString: string = "";

    subscription!: Subscription;

    constructor(private constantsService: ConstantsService) { }

    ngOnInit() {
        this.subscription = this.constantsService.constants$.subscribe((constants) => {
            if (!constants) {
                return;
            }
            this.educationString = (this.educationType ?? [])
                .map(
                    (education: number) =>
                        constants.educations[
                        education as keyof typeof constants.educations
                        ]
                )
                .join(", ");

            this.continuingEducationString = (this.continuingEducation ?? [])
                .map((continuingEducation: ContinuingEducation) => {
                    var name =
                        constants.continuingEducation[
                        continuingEducation.continuingEducation as keyof typeof constants.continuingEducation
                        ];
                    if (!continuingEducation.isRequired) {
                        name = name + " (wünschenswert)";
                    } else {
                        name = name + " (verpflichtend)";
                    }
                    return name;
                })
                .join(", ");

            this.workExperienceString = (this.workExperience ?? [])
                .filter((we) => we !== null)
                .map((workExperience: WorkExperience | null) => {
                    if (workExperience === null) {
                        return "";
                    }
                    var name = workExperience.areaOfExperience;
                    if (workExperience.yearsOfExperience) {
                        name +=
                            " (" + workExperience.yearsOfExperience + " Jahre)";
                    }
                    return name;
                })
                .join(", ");

            this.drivingLicenseString = 
                this.drivingLicense && !['NO', 'UNKNOWN'].includes(this.drivingLicense)
                    ? constants.drivingLicense[this.drivingLicense]
                    : '';
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
