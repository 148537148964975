import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { MatButtonModule } from "@angular/material/button";
import { DialogModule } from "primeng/dialog";
import { MatchConfirmationDialogComponent } from "../../match-confirmation-dialog/match-confirmation-dialog.component";
import { Match } from "src/app/model";
import { MatchRegistrationWrapperComponent } from "../../match-registration-wrapper/match-registration-wrapper.component";
import { MatIconModule } from "@angular/material/icon";
import { MatchService } from "src/shared/services/match.service";
import { UserService } from "src/shared/services/user.service";
import { AnalyticsService } from "src/shared/services/analytics.service";

@Component({
    selector: "app-match-details-apply-button",
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        MatButtonModule,
        DialogModule,
        MatchRegistrationWrapperComponent,
        MatIconModule,
    ],
    templateUrl: "./match-details-apply-button.component.html",
    styleUrl: "./match-details-apply-button.component.scss",
})
export class MatchDetailsApplyButtonComponent {
    @Input() match!: Match;
    @Input() isPreview: boolean = false;

    needsRegistration: boolean = false;
    visible: boolean = false;

    constructor(
        private matchService: MatchService,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit() {
        this.needsRegistration =
            this.matchService.matchApplierValue?.applier.user.socialAccount ==
            3;
    }

    confirmationDialog() {
        this.visible = true;

        if (this.needsRegistration) {
            this.analyticsService.trackEvent("registration-dialog-open", {
                applier_extId: this.userService.getExtId(),
                source: "application",
            });
        }
    }
}
