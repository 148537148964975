<div style="width: 100%; display: flex; justify-content: center">
    @if (isAborted) {
    <div class="aborted-wrapper">
        <div class="stepper-item active">
            <div class="stepper-label">Abgelehnt</div>
            <mat-icon fontIcon="cancel" class="sized-icon" />
        </div>
        <div class="aborted-text">
            Das Unternehmen hat deine Bewerbung leider abgelehnt.
        </div>
    </div>
    } @else {

    <div class="stepper-container">
        <div
            class="stepper-item-wrapper"
            *ngFor="let stage of stages; let i = index"
        >
            <div
                class="stepper-item"
                [class.active]="i == currentStage"
                [class.before]="i < currentStage"
            >
                @if (i == currentStage) {
                <div class="stepper-label">{{ stage.label }}</div>
                }

                <mat-icon [fontIcon]="stage.icon" class="sized-icon" />
            </div>
            @if (i < stages.length - 1) {

            <div class="line"></div>
            }
        </div>
    </div>
    }
</div>
