import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { UserService } from "src/shared/services/user.service";

@Component({
    selector: "app-footer-whatsapp-button",
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: "./footer-whatsapp-button.component.html",
    styleUrl: "./footer-whatsapp-button.component.scss",
})
export class FooterWhatsappButtonComponent {
    constructor(private userService: UserService) {}

    openLink() {
        var extId = this.userService.getExtId();
        window.open(
            `https://api.whatsapp.com/send?phone=+497219588259&text=Liebes%20l%C3%A4nk%20Team,%20ich%20%28${extId}%29%20habe%20eine%20Frage%3A`,
            "_blank"
        );
    }
}
