import { Injectable, isDevMode } from "@angular/core";
import { MatchApplier } from "../../app/model";
import { mapMatchApplier } from "src/app/model/matchApplier";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable({
    providedIn: "root",
})
export class MatchService {
    constructor(private userService: UserService) {
        if (isDevMode()) {
            console.log("MatchService");
        }
    }
    private matchApplier: BehaviorSubject<MatchApplier | null> =
        new BehaviorSubject<MatchApplier | null>(null);

    public get matchApplier$() {
        return this.matchApplier.asObservable();
    }

    public get matchApplierValue() {
        return this.matchApplier.value;
    }

    public get isInited() {
        return this.matchApplier.value !== null;
    }

    saveData(extId: string, matchApplier: MatchApplier) {
        localStorage.setItem(
            "matchApplier",
            JSON.stringify({ extId: extId, data: matchApplier })
        );
    }

    getMatchListPosition(matchExtId: string): number | undefined {
        return this.matchApplier.value?.matches
            .filter((m) => m.appliedAt == null)
            .findIndex((match) => match.extId == matchExtId);
    }

    private async getAllMatches(extId: string): Promise<MatchApplier> {
        try {
            const response = await fetch(
                `${environment.api.baseUrl}accounts/applier/public/${extId}`
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            // redirect if received extId differs
            // this is the case when passing applier.extID but user.external_id is expected
            const externalId = data["applier"]["user"]["external_id"];
            if (externalId && externalId != extId) {
                this.userService.navigateToNewExtId(externalId);
            }

            const matchApplier: MatchApplier = mapMatchApplier(data);
            this.matchApplier.next(matchApplier);
            this.saveData(extId, matchApplier);
            return matchApplier;
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
    }

    async getMatchApplierData(extId: string): Promise<MatchApplier> {
        const storedData = localStorage.getItem("matchApplier");
        if (storedData) {
            const matchApplierData = JSON.parse(storedData);
            if (matchApplierData.extId == extId) {
                this.matchApplier.next(matchApplierData.data);
            }
        }
        try {
            const matchApplier = await this.getAllMatches(extId);
            return matchApplier;
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
    }

    async acceptMatch(matchExtId: string): Promise<any> {
        // return new Promise((resolve) => setTimeout(resolve, 1000)); // TODO: Remove
        const response = await fetch(
            `${environment.api.baseUrl}match/matchlist/public/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify({
                    extID: matchExtId,
                    status: 5,
                }),
            }
        );
        return response;
    }
}
