import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { FooterWhatsappButtonComponent } from "../../footer/footer-whatsapp-button/footer-whatsapp-button.component";

@Component({
    selector: "app-help-content",
    standalone: true,
    imports: [CommonModule, ButtonModule, FooterWhatsappButtonComponent],
    templateUrl: "./help-content.component.html",
    styleUrl: "./help-content.component.scss",
})
export class HelpContentComponent {
    constructor() {}
}
