<mat-card
    class="column mat-elevation-z2 wrapper"
    style="gap: 10px"
    (click)="onClick(match.extId)"
    matRipple
>
    <!-- Properties -->
    <div class="row property-wrapper">
        @if (match.distance <= 15) {
        <mat-icon
            class="sized-icon"
            aria-hidden="false"
            svgIcon="location_fit"
            style="flex-shrink: 0"
        />
        } @else {
        <mat-icon
            class="sized-icon"
            aria-hidden="false"
            fontIcon="pin_drop_outlined"
        />
        }
        <p class="property">
            {{ match.job.postcode }} {{ match.job.city }} ({{
                match.distance.toFixed(0)
            }}
            km)
        </p>
    </div>
    <div class="row property-wrapper">
        @if (matchProperties?.careTypeIntersection) {
        <mat-icon
            class="sized-icon"
            aria-hidden="false"
            svgIcon="care_type_fit"
            style="flex-shrink: 0"
            *ngIf="matchProperties?.careTypeIntersection"
        />
        } @else {
        <mat-icon
            class="sized-icon"
            aria-hidden="false"
            fontIcon="house_sharp"
            style="flex-shrink: 0"
        />}

        <p class="property">
            {{ matchProperties?.careTypeName }}
        </p>
    </div>
    <div class="row property-wrapper">
        @if (matchProperties?.positionIntersection){<mat-icon
            class="sized-icon"
            aria-hidden="false"
            svgIcon="position_fit"
            style="flex-shrink: 0"
        />} @else {
        <mat-icon
            class="sized-icon"
            aria-hidden="false"
            fontIcon="person"
            style="flex-shrink: 0"
        />}
        <p class="property">
            {{ matchProperties?.positionName }}
        </p>
    </div>
</mat-card>
