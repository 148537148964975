import { Applier, mapApplier } from "./applier";
import { Match, mapMatch } from "./match";

export interface MatchApplier {
    applier: Applier;
    matches: Match[];
}

export function mapMatchApplier(jsonData: any): MatchApplier {
    return {
        applier: mapApplier(jsonData),
        matches: jsonData.matches
            ? jsonData.matches.map((match: any) => mapMatch(match))
            : [],
    };
}
