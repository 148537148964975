<button
    class="small-icon-button"
    mat-icon-button
    (click)="clickEvent()"
    aria-label="Example icon button with a vertical three dot icon"
>
    <mat-icon>help_outlined</mat-icon>
</button>

<p-dialog
    #registrationDialog
    [showHeader]="false"
    [(visible)]="explainDialogVisible"
    [style]="{ width: '90%' }"
    [modal]="true"
    [dismissableMask]="true"
    [closeOnEscape]="true"
    [closable]="true"
    (onHide)="onExplainDialogHide($event)"
>
    <!-- src/app/modules/profile-avatar/profile-avatar.component.html -->
    <app-help-initial-content class="ui-dialog" />
</p-dialog>

<p-dialog
    #helpDialog
    [showHeader]="false"
    [(visible)]="helpDialogVisible"
    [style]="{ width: '90%' }"
    [modal]="true"
    [dismissableMask]="true"
    [closeOnEscape]="true"
    [closable]="true"
    (onHide)="onHelpDialogHide($event)"
>
    <!-- src/app/modules/profile-avatar/profile-avatar.component.html -->
    <app-help-content class="ui-dialog" />
</p-dialog>
