import { User, getDummyUser, mapUser } from "./user";

export interface Applier {
    user: User;
    careType: number[];
    shiftHour: number[];
    workingType: number[];
    positionType: number[];
    education: number[];
    continuingEducation: number[];
    isRegistered: () => boolean;
}

export function mapApplier(jsonData: any): Applier {
    const user = mapUser(jsonData.applier.user || {});

    // Define the getter method
    const isRegistered = () => {
        return user.socialAccount !== 3;
    };

    return {
        user,
        careType: jsonData.applier.caretype || [],
        shiftHour: jsonData.applier.shift_hour || [],
        workingType: jsonData.applier.working_type || [],
        positionType: jsonData.applier.position || [],
        education: jsonData.applier.education || [],
        continuingEducation: jsonData.applier.continuing_education || [],
        isRegistered, // Assign the getter method to the isRegistered property
    };
}

export function getDummyApplier(jobEducation: number[]): Applier {
    return {
        user: getDummyUser(),
        careType: [],
        shiftHour: [],
        workingType: [],
        positionType: [],
        education: jobEducation,
        continuingEducation: [],
        isRegistered: () => false,
    };
}
