export interface Company {
    name: string;
    logo?: string | null;
    gallery: string[];
    about?: string | null;
    benefits?: string | null;
    website?: string | null;
    tarif?: string | null;
    culture?: string | null;
    ownership?: string | null;
    phoneNumber?: string | null;
    externalName?: string | null;
    displayName?: string | null;
    overtimeRegulation?: string | null;
    companySize?: string | null;
    daysOfVacation?: string | null;
    companyPresentation?: string | null;
    videoUrl?: string | null;
}

export function mapCompany(companyData: any): Company {
    return {
        name: companyData.name || "",
        logo: companyData.logo || null,
        gallery: companyData.gallery || [],
        about: companyData.about || null,
        benefits: companyData.benefits || null,
        website: companyData.website || null,
        tarif: companyData.tarif || null,
        culture: companyData.culture || null,
        ownership: companyData.ownership || null,
        phoneNumber: companyData.phone_number || null,
        externalName: companyData.external_name || null,
        overtimeRegulation: companyData.overtime_regulation || null,
        companySize: companyData.company_size || null,
        daysOfVacation: companyData.days_of_vacation || null,
        companyPresentation: companyData.company_presentation || null,
        videoUrl: companyData.video_url || null,
        displayName: companyData.external_name || companyData.name || "",
    };
}
