import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule } from '@angular/core';
const GoogleTagManagerConfigService = new InjectionToken('google-tag-manager-config');
// adapted from https://github.com/auth0/auth0-angular#dynamic-configuration
class GoogleTagManagerConfiguration {
  constructor(googleTagManagerConfig) {
    this._googleTagManagerConfig = {
      id: null,
      gtm_auth: '',
      gtm_preview: ''
    };
    if (googleTagManagerConfig) {
      this.set(googleTagManagerConfig);
    }
  }
  set(googleTagManagerConfig) {
    this._googleTagManagerConfig = googleTagManagerConfig;
  }
  get() {
    return this._googleTagManagerConfig;
  }
  static {
    this.ɵfac = function GoogleTagManagerConfiguration_Factory(ɵt) {
      return new (ɵt || GoogleTagManagerConfiguration)(i0.ɵɵinject(GoogleTagManagerConfigService, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoogleTagManagerConfiguration,
      factory: GoogleTagManagerConfiguration.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleTagManagerConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [GoogleTagManagerConfigService]
    }]
  }], null);
})();
class GoogleTagManagerService {
  constructor(googleTagManagerConfiguration, googleTagManagerId, googleTagManagerMode = "noisy", googleTagManagerAuth, googleTagManagerPreview, googleTagManagerResourcePath, googleTagManagerCSPNonce) {
    this.googleTagManagerConfiguration = googleTagManagerConfiguration;
    this.googleTagManagerId = googleTagManagerId;
    this.googleTagManagerMode = googleTagManagerMode;
    this.googleTagManagerAuth = googleTagManagerAuth;
    this.googleTagManagerPreview = googleTagManagerPreview;
    this.googleTagManagerResourcePath = googleTagManagerResourcePath;
    this.googleTagManagerCSPNonce = googleTagManagerCSPNonce;
    this.isLoaded = false;
    this.browserGlobals = {
      windowRef() {
        return window;
      },
      documentRef() {
        return document;
      }
    };
    this.config = this.googleTagManagerConfiguration?.get();
    if (this.config == null) {
      this.config = {
        id: null
      };
    }
    this.config = {
      ...this.config,
      id: googleTagManagerId || this.config.id,
      gtm_auth: googleTagManagerAuth || this.config.gtm_auth,
      gtm_preview: googleTagManagerPreview || this.config.gtm_preview,
      gtm_resource_path: googleTagManagerResourcePath || this.config.gtm_resource_path
    };
    if (this.config.id == null) {
      return;
    }
  }
  checkForId() {
    if (this.googleTagManagerMode !== "silent" && !this.config.id) {
      throw new Error('Google tag manager ID not provided.');
    } else if (!this.config.id) {
      return false;
    }
    return true;
  }
  getDataLayer() {
    this.checkForId();
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }
  pushOnDataLayer(obj) {
    this.checkForId();
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
  addGtmToDom() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      } else if (!this.checkForId()) {
        return resolve(false);
      }
      const doc = this.browserGlobals.documentRef();
      this.pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js');
      gtmScript.addEventListener('load', () => {
        return resolve(this.isLoaded = true);
      });
      gtmScript.addEventListener('error', () => {
        return reject(false);
      });
      if (this.googleTagManagerCSPNonce) {
        gtmScript.setAttribute('nonce', this.googleTagManagerCSPNonce);
      }
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
    });
  }
  pushTag(item) {
    return new Promise((resolve, reject) => {
      if (!this.checkForId()) {
        return resolve();
      }
      if (!this.isLoaded) {
        this.addGtmToDom().then(() => {
          this.pushOnDataLayer(item);
          return resolve();
        }).catch(() => reject());
      } else {
        this.pushOnDataLayer(item);
        return resolve();
      }
    });
  }
  applyGtmQueryParams(url) {
    if (url.indexOf('?') === -1) {
      url += '?';
    }
    return url + Object.keys(this.config).filter(k => this.config[k]).map(k => `${k}=${this.config[k]}`).join('&');
  }
  static {
    this.ɵfac = function GoogleTagManagerService_Factory(ɵt) {
      return new (ɵt || GoogleTagManagerService)(i0.ɵɵinject(GoogleTagManagerConfiguration, 8), i0.ɵɵinject('googleTagManagerId', 8), i0.ɵɵinject('googleTagManagerMode', 8), i0.ɵɵinject('googleTagManagerAuth', 8), i0.ɵɵinject('googleTagManagerPreview', 8), i0.ɵɵinject('googleTagManagerResourcePath', 8), i0.ɵɵinject('googleTagManagerCSPNonce', 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoogleTagManagerService,
      factory: GoogleTagManagerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleTagManagerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: GoogleTagManagerConfiguration,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [GoogleTagManagerConfiguration]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['googleTagManagerId']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['googleTagManagerMode']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['googleTagManagerAuth']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['googleTagManagerPreview']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['googleTagManagerResourcePath']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['googleTagManagerCSPNonce']
    }]
  }], null);
})();
class GoogleTagManagerModule {
  static forRoot(config) {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [{
        provide: GoogleTagManagerConfigService,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function GoogleTagManagerModule_Factory(ɵt) {
      return new (ɵt || GoogleTagManagerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: GoogleTagManagerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleTagManagerModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of angular-google-tag-manager
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GoogleTagManagerConfigService, GoogleTagManagerConfiguration, GoogleTagManagerModule, GoogleTagManagerService };
