import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Match } from "src/app/model";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-match-details-content-map",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./match-details-content-map.component.html",
    styleUrl: "./match-details-content-map.component.scss",
})
export class MatchDetailsContentMapComponent {
    @Input() match!: Match;
    mapImageUrl!: string;
    googleMapsUrl!: string;
    exactPositionKnown!: boolean;

    ngOnInit() {
        this.generateStaticMap();
    }

    generateStaticMap() {
        const apiKey = environment.app.hereMapsApiKey;
        const lat = this.match.job.latitude;
        const lng = this.match.job.longitude;
        const location = `${lat},${lng}`;
        const streetName = this.match.job.facilityLocation.street;
        const houseNumber = this.match.job.facilityLocation.houseNumber;
        const city = this.match.job.city;
        const postcode = this.match.job.postcode;
        this.exactPositionKnown =
            streetName != null &&
            houseNumber != null &&
            streetName.trim() !== "" &&
            houseNumber.trim() !== "";

        const zoom = 12;
        const width = 600;
        const height = 400;

        var extraInfo = `${streetName} ${houseNumber}, ${postcode} ${city}`;

        this.mapImageUrl = `https://image.maps.hereapi.com/mia/v3/base/mc/center:${location};zoom=${zoom}/${width}x${height}/png8?apikey=${apiKey}&style=lite.day&overlay=point:${location};style=circle;width=4000m;color=%23D04D4D50`;

        this.googleMapsUrl = this.getGoogleMapsUrl(
            this.exactPositionKnown ? extraInfo : location
        );
    }
    getGoogleMapsUrl(query: string): string {
        return `https://www.google.com/maps/search/?api=1&query=${query}`;
    }
}
