import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Match, Applier } from "src/app/model";
import { MatCardModule } from "@angular/material/card";
import { MatchAppliedCardStatusComponent } from "./match-applied-card-status/match-applied-card-status.component";
import { MatRippleModule } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatIconModule } from "@angular/material/icon";
import { UserService } from "src/shared/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConstantsService } from "src/shared/services/constants.service";
import { AnalyticsService } from "src/shared/services/analytics.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-match-applied-card",
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatchAppliedCardStatusComponent,
        MatRippleModule,
        MatIconModule,
    ],
    templateUrl: "./match-applied-card.component.html",
    styleUrl: "./match-applied-card.component.scss",
})
export class MatchAppliedCardComponent {
    @Input() match!: Match;
    @Input() applier!: Applier;

    positionName: string = "";

    subscription!: Subscription;

    constructor(
        private userService: UserService,
        private router: Router,
        private constantsService: ConstantsService,
        private analyticsService: AnalyticsService
    ) { }

    ngOnInit() {
        this.subscription = this.constantsService.constants$.subscribe((constants) => {
            if (!constants) {
                return;
            }

            if (
                this.match.job.position.length > 0 &&
                this.match.job.position[0] in constants.positions
            ) {
                this.positionName =
                    constants.positions[this.match.job.position[0]];
            } else {
                this.positionName = "";
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    handleClick() {
        this.analyticsService.trackEvent("match-details-clicked", {
            match_extId: this.match.extId,
            applier_extId: this.userService.getExtId(),
            source: "card",
        });

        this.router.navigate(
            [`${this.userService.getExtId()}/match/${this.match.extId}`],
            { queryParams: { tabId: 1 } }
        );
    }
}
