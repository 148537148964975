<div class="column card-content">
    <p class="name">{{ applier.user.firstName }} {{ applier.user.lastName }}</p>
    <div class="row">
        <mat-icon
            aria-hidden="false"
            fontIcon="person"
            style="min-width: 24px"
        ></mat-icon>
        <p style="font-weight: 300">
            {{ positionName }}
        </p>
    </div>
    <div class="row">
        <mat-icon
            aria-hidden="false"
            fontIcon="house_sharp"
            style="min-width: 24px"
        ></mat-icon>
        <p style="font-weight: 300">
            {{ careTypeName }}
        </p>
    </div>
    <div class="row">
        <mat-icon
            aria-hidden="false"
            fontIcon="access_time_filled"
            style="min-width: 24px"
        ></mat-icon>
        <p style="font-weight: 300">
            {{ shiftName }}
        </p>
    </div>
    <div class="row">
        <mat-icon
            aria-hidden="false"
            fontIcon="work"
            style="min-width: 24px"
        ></mat-icon>
        <p style="font-weight: 300">
            {{ workingTypeName }}
        </p>
    </div>
</div>
