<!-- <button
    pButton
    icon="pi pi-send"
    label="Ich bin interessiert!"
    class="floating-button"
    (click)="confirmationDialog()"
    [disabled]="match.appliedAt !== null || isPreview"
></button> -->

<button
    class="floating-button tertiary-fab"
    mat-fab
    extended
    color="accent"
    (click)="confirmationDialog()"
    [disabled]="match.appliedAt !== null || isPreview"
>
    <mat-icon color="primary">send</mat-icon>
    Ich bin interessiert!
</button>

<p-dialog
    #registrationDialog
    [showHeader]="false"
    [(visible)]="visible"
    [style]="{ width: '90%' }"
    [modal]="true"
    [dismissableMask]="true"
    [closeOnEscape]="true"
    [closable]="true"
>
    <app-match-registration-wrapper
        [match]="match"
        (closeDialog)="visible = false"
    />
</p-dialog>
