import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WorkingHour } from "../../../../../model";
import { MatDividerModule } from "@angular/material/divider";
import { ConstantsService } from "src/shared/services/constants.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-match-details-content-workinghours",
    standalone: true,
    imports: [CommonModule, MatDividerModule],
    templateUrl: "./match-details-content-workinghours.component.html",
    styleUrls: ["./match-details-content-workinghours.component.scss"],
})
export class MatchDetailsContentWorkinghoursComponent {
    @Input() workingHours?: (WorkingHour | null)[];
    @Input() shifts?: (number | null)[];
    @Input() shiftOther?: string | null;

    shiftTypes!: string[];

    shiftHours!: string[];

    shiftCards!: { [key: string]: WorkingTimeWrapper[] };

    shiftCardsOther!: string[];

    subscription!: Subscription;

    constructor(private constantsService: ConstantsService) { }

    ngOnInit() {
        this.subscription = this.constantsService.constants$.subscribe((constants) => {
            if (!constants) {
                return;
            }
            this.shiftHours = (this.shifts ?? [])
                .filter((shift) => shift !== null) // Filter out null values
                .map(
                    (shift) =>
                        constants.shiftHours[
                        shift as keyof typeof constants.shiftHours
                        ]
                );

            var shiftCardsHelper = [];

            for (var hour of this.workingHours ?? []) {
                switch (hour?.shiftType) {
                    case 1:
                        shiftCardsHelper.push({
                            shiftType: constants.workingHours[hour?.shiftType]!,
                            workingHour: hour,
                            imagePath: "assets/images/noon.png",
                        });
                        break;
                    case 7:
                        shiftCardsHelper.push({
                            shiftType: constants.workingHours[hour?.shiftType]!,
                            workingHour: hour,
                            imagePath: "assets/images/weekend.png",
                        });
                        break;
                    case 4:
                        shiftCardsHelper.push({
                            shiftType: constants.workingHours[hour?.shiftType]!,
                            workingHour: hour,
                            imagePath: "assets/images/evening.png",
                        });
                        break;
                    case 2:
                        shiftCardsHelper.push({
                            shiftType: constants.workingHours[hour?.shiftType]!,
                            workingHour: hour,
                            imagePath: "assets/images/night.png",
                        });

                        break;
                    case 3:
                        shiftCardsHelper.push({
                            shiftType: constants.workingHours[hour?.shiftType]!,
                            workingHour: hour,
                            imagePath: "assets/images/morning.png",
                        });
                        break;
                    default:
                        break;
                }
            }
            this.shiftCards = shiftCardsHelper.reduce(
                (groupedShiftCards, shiftCard) => {
                    if (groupedShiftCards[shiftCard.shiftType]) {
                        groupedShiftCards[shiftCard.shiftType].push(shiftCard);
                    } else {
                        groupedShiftCards[shiftCard.shiftType] = [shiftCard];
                    }
                    return groupedShiftCards;
                },
                {} as { [key: string]: WorkingTimeWrapper[] }
            );

            var shiftCardsOtherHelper: string[] = [];
            (this.workingHours ?? [])
                .map((hour) => hour?.shiftType)
                .filter((shift) => shift == 5 || shift == 6)
                .forEach((shiftIndex) => {
                    if (shiftIndex !== undefined) {
                        shiftCardsOtherHelper.push(
                            constants.workingHours[shiftIndex]
                        );
                    }
                });

            this.shiftCardsOther = shiftCardsOtherHelper;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get shiftCardsArray(): { key: string; value: WorkingTimeWrapper[] }[] {
        return Object.entries(this.shiftCards).map(([key, value]) => ({
            key,
            value,
        }));
    }

    get hasShiftCards(): boolean {
        return Object.keys(this.shiftCards).length > 0;
    }
}

type WorkingTimeWrapper = {
    shiftType: string;
    workingHour: WorkingHour;
    imagePath: string;
};
