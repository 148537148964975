import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ExplainDialogService {
    private dialogOpenSource = new BehaviorSubject<boolean>(false);
    dialogOpen$ = this.dialogOpenSource.asObservable();

    openDialog() {
        this.dialogOpenSource.next(true);
    }

    closeDialog() {
        this.dialogOpenSource.next(false);
    }
}
