@if (!isLoading && (!match || !applier)) {
<app-job-notfound />
} @else {

<!-- 
        <div
            class="header-collapsed-content"
            [ngStyle]="{ opacity: titleOpacity }"
        >
            {{ match?.company?.displayName }}
        </div>
    </div> -->

<!-- <div class="header-content" [ngStyle]="{ opacity: contentOpacity }"></div> -->
<div
    class="header-scrolled"
    [ngStyle]="{
        'border-bottom': getOverlayBorderStyle(),
        background: getOverlayBackgroundStyle(),
    }"
>
    <div class="header-collapsed-content" [ngStyle]="{ opacity: titleOpacity }">
        <div class="text-container">
            {{ match?.company?.displayName }}
        </div>
    </div>
</div>

<div
    class="back-button clickable fixed"
    (click)="goBack()"
    *ngIf="!isPreview"
    [ngStyle]="{
        top: getBackButtonTop(),
        left: getBackButtonLeft(),
        background: getBackButtonBackgroundColor(),
    }"
>
    <mat-icon
        [ngStyle]="{ color: getBackButtonForegroundColor() }"
        aria-hidden="false"
        fontIcon="arrow_back"
        class="white-icon"
    ></mat-icon>
</div>

<main class="main">
    <div class="progress-spinner-wrapper" *ngIf="isLoading">
        <mat-spinner diameter="36" />
    </div>
    <div class="main-content responsive-wrapper" *ngIf="!isLoading">
        <div
            style="
                padding-left: 12px;
                padding-right: 12px;
                padding-top: 16px;
                padding-bottom: 16px;
            "
        >
            <div class="column" style="gap: 16px; align-items: center">
                <div class="image-wrapper">
                    <img
                        *ngIf="
                            match?.company?.gallery &&
                            (match?.company?.gallery?.length ?? 0) > 0
                        "
                        class="header-image"
                        src="{{ match!.company.gallery[0] }}"
                        alt="Image"
                    />

                    <div class="image-overlay"></div>
                    <div class="company-icon">
                        <img
                            style="width: 100%; object-fit: cover"
                            src="{{
                                match?.company?.logo ??
                                    'https://res.cloudinary.com/paulwvnjohi/image/upload/v1679492526/placeholder_sk48uq.png'
                            }}"
                        />
                    </div>

                    <div
                        class="column child"
                        style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            gap: 8px;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: end;
                            position: absolute;
                            padding-bottom: 8px;
                            color: white;
                            align-items: center;
                            background: transparent;
                            z-index: 2;
                            width: 100%;
                            padding-left: 12px;
                            padding-right: 12px;
                        "
                    >
                        <p class="header-title">{{ match?.company?.displayName }}</p>
                        <p class="header-subtitle">
                            {{ match?.job?.postcode }} {{ match?.job?.city }}
                        </p>

                        <div class="row" *ngIf="!isLoading">
                            <mat-icon
                                aria-hidden="false"
                                fontIcon="pin_drop_outlined"
                                class="white-icon"
                            ></mat-icon>
                            <p class="header-subtitle">
                                {{ match?.distance?.toFixed(1) }} km
                            </p>
                            <mat-icon
                                aria-hidden="false"
                                fontIcon="commute_outlined"
                                class="white-icon"
                            ></mat-icon>
                            <p class="header-subtitle">
                                {{ ((match?.duration ?? 0) / 60).toFixed(0) }}
                                min
                            </p>
                        </div>
                    </div>
                </div>
                <div class="column" style="gap: 8px; align-items: center">
                    <div class="row-center">
                        <mat-icon
                            aria-hidden="false"
                            style="flex-shrink: 0"
                            fontIcon="person"
                        ></mat-icon>
                        <p style="font-weight: 300; text-align: center">
                            {{ matchProperties?.positionName }}
                        </p>
                        <mat-icon
                            aria-hidden="false"
                            fontIcon="task_alt"
                            style="color: rgb(50, 131, 52); flex-shrink: 0"
                            *ngIf="matchProperties?.positionIntersection"
                        ></mat-icon>
                    </div>
                    <div class="row-center">
                        <mat-icon
                            aria-hidden="false"
                            style="flex-shrink: 0"
                            fontIcon="house_sharp"
                        ></mat-icon>
                        <p style="font-weight: 300; text-align: center">
                            {{ matchProperties?.careTypeName }}
                        </p>
                        <mat-icon
                            aria-hidden="false"
                            fontIcon="task_alt"
                            style="color: rgb(50, 131, 52); flex-shrink: 0"
                            *ngIf="matchProperties?.careTypeIntersection"
                        ></mat-icon>
                    </div>
                    <div class="row-center">
                        <mat-icon
                            aria-hidden="false"
                            style="flex-shrink: 0"
                            fontIcon="access_time_filled"
                        ></mat-icon>
                        <p style="font-weight: 300; text-align: center">
                            {{ matchProperties?.shiftName }}
                        </p>
                        <mat-icon
                            aria-hidden="false"
                            fontIcon="task_alt"
                            style="color: rgb(50, 131, 52); flex-shrink: 0"
                            *ngIf="matchProperties?.shiftIntersection"
                        ></mat-icon>
                    </div>
                    <div class="row-center">
                        <mat-icon
                            aria-hidden="false"
                            style="flex-shrink: 0"
                            fontIcon="work"
                        ></mat-icon>
                        <p style="font-weight: 300; text-align: center">
                            {{ matchProperties?.workingTypeName }}
                        </p>
                        <mat-icon
                            aria-hidden="false"
                            fontIcon="task_alt"
                            style="color: rgb(50, 131, 52); flex-shrink: 0"
                            *ngIf="matchProperties?.workingTypeIntersection"
                        ></mat-icon>
                    </div>
                </div>
            </div>
            <app-match-details-content
                [match]="match"
                [applier]="applier"
                *ngIf="match && applier"
            />
        </div>
    </div>
    @if (match) {
    <app-match-details-apply-button
        class="fixed"
        [match]="match"
        [isPreview]="isPreview ?? false"
    />
    }
</main>

}
