import { NgModule } from "@angular/core";
import { HammerModule, BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import routeConfig from "./routes"; // import your route configuration
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { environment } from "src/environments/environment";
import posthog from "posthog-js";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        GoogleTagManagerModule.forRoot({
            id: environment.app.googleTagManagerId,
        }),
        RouterModule.forRoot(routeConfig, {
            scrollPositionRestoration: "enabled",
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
