<div class="column wrapper">
    <div class="row">
        <mat-icon
            aria-hidden="false"
            fontIcon="arrow_downward"
            class="sized-icon"
        ></mat-icon>
        <p class="title">Top Vorschläge</p>
    </div>
    <p>Diese Jobs passen optimal zu deinem Profil!</p>
</div>
