import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { Applier } from "src/app/model";
import { MatchService } from "src/shared/services/match.service";
import { NavigationService } from "src/shared/services/navigation.service";
import { UserService } from "src/shared/services/user.service";

@Component({
    selector: "app-applicant-registration-finished",
    standalone: true,
    imports: [CommonModule, ButtonModule, MatIconModule],
    templateUrl: "./applicant-registration-finished.component.html",
    styleUrl: "./applicant-registration-finished.component.scss",
})
export class ApplicantRegistrationFinishedComponent {
    @Input() applier!: Applier;

    @Output() closeDialog = new EventEmitter<void>();

    constructor(
        private route: ActivatedRoute,
        private matchService: MatchService,
        private meta: Meta,
        private router: Router,
        private navigationService: NavigationService,
        private userService: UserService
    ) {
        this.meta.updateTag({
            name: "theme-color",
            content: "#e4e4f2",
        });
        var params = this.route.snapshot.paramMap;

        var matchApplier = this.matchService.matchApplierValue;

        if (matchApplier) {
            this.applier = matchApplier.applier!;
        }
    }

    ngOnInit() {
        setTimeout(this.goBack.bind(this), 3000);
    }

    goBack() {
        this.closeDialog.emit();
    }
}
