import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-match-card-fit-position",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./match-card-fit-position.component.html",
    styleUrl: "./match-card-fit-position.component.scss",
})
export class MatchCardFitPositionComponent {
    @Input() fitIndex!: number;
}
