import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExplainDialogService } from "src/shared/services/explain-dialog.service";
import { ButtonModule } from "primeng/button";

@Component({
    selector: "app-help-initial-content",
    standalone: true,
    imports: [CommonModule, ButtonModule],
    templateUrl: "./help-initial-content.component.html",
    styleUrl: "./help-initial-content.component.scss",
})
export class HelpInitialContentComponent {
    constructor(private explainDialogService: ExplainDialogService) {}

    closeDialog() {
        this.explainDialogService.closeDialog();
    }
}
