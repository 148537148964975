import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { MatIconModule } from "@angular/material/icon";
import { NavigationService } from "src/shared/services/navigation.service";
import { UserService } from "src/shared/services/user.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-job-notfound",
    standalone: true,
    imports: [CommonModule, ButtonModule, MatIconModule],
    templateUrl: "./job-notfound.component.html",
    styleUrl: "./job-notfound.component.scss",
})
export class JobNotfoundComponent {
    @Input() inPreview: boolean = false;

    constructor(
        private navigationService: NavigationService,
        private userService: UserService,
        private router: Router
    ) {}

    goBack() {
        const previousUrl = this.navigationService.getPreviousUrl();

        // previousUrl includes /match/ when user was redirected to different extId by backend
        // therefore window.history.back() would redirect to same not found job
        if (previousUrl && !previousUrl.includes('/match/')) {
            window.history.back();
        } else {
            this.router.navigate([`${this.userService.getExtId()}`], {
                queryParamsHandling: "preserve",
            });
        }
    }
}
