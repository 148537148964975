<div class="filter-wrapper">
    <p class="header-text" style="padding-bottom: 8px">Sortieren:</p>
    <mat-button-toggle-group
        name="sort"
        (change)="onSelectionChangeSort($event)"
    >
        <mat-button-toggle
            id="sort-button-filter"
            [value]="'fit'"
            [checked]="isSelectedSort('fit')"
            >Empfehlung</mat-button-toggle
        >
        <mat-button-toggle
            id="sort-button-distance"
            [value]="'distance'"
            [checked]="isSelectedSort('distance')"
            >Distanz</mat-button-toggle
        >
    </mat-button-toggle-group>

    <p class="header-text" style="padding-top: 12px; padding-bottom: 12px">
        Filtern:
    </p>

    <mat-accordion>
        <mat-expansion-panel id="parent-filter-caretype">
            <mat-expansion-panel-header>
                <mat-panel-title> Versorgungsart </mat-panel-title>
            </mat-expansion-panel-header>
            @for (label of objectKeys(baseCareTypes); track label; let i =
            $index) {
            <span>
                <mat-divider [inset]="false" *ngIf="i != 0"></mat-divider>
                <mat-checkbox
                    color="primary"
                    id="parent-checkbox-caretype"
                    class="example-margin checkbox-parent"
                    [checked]="
                        getParentState('careTypes', label) == 'allSelected'
                    "
                    [indeterminate]="
                        getParentState('careTypes', label) == 'indeterminate'
                    "
                    (change)="updateParent($event, 'careTypes', label)"
                >
                    {{ label }}
                </mat-checkbox>
            </span>
            <span>
                <ul>
                    @for (val of baseCareTypes[label]; track val; let i =
                    $index) {
                    <span style="padding-left: 24px">
                        <mat-checkbox
                            color="primary"
                            id="child-checkbox-caretype"
                            [checked]="isSelectedCareType(val.id)"
                            (change)="
                                updateChildFilter(
                                    $event,
                                    'careTypes',
                                    label,
                                    val.label
                                )
                            "
                        >
                            {{ val.label }}
                        </mat-checkbox>
                    </span>
                    }
                </ul>
            </span>
            }
        </mat-expansion-panel>

        <mat-expansion-panel id="parent-filter-position">
            <mat-expansion-panel-header>
                <mat-panel-title> Position </mat-panel-title>
            </mat-expansion-panel-header>
            @for (label of objectKeys(basePositions); track label; let i =
            $index) {
            <span>
                <mat-divider [inset]="false" *ngIf="i != 0"></mat-divider>
                <mat-checkbox
                    color="primary"
                    id="parent-checkbox-position"
                    class="example-margin checkbox-parent"
                    [checked]="
                        getParentState('positions', label) == 'allSelected'
                    "
                    [indeterminate]="
                        getParentState('positions', label) == 'indeterminate'
                    "
                    (change)="updateParent($event, 'positions', label)"
                    style="font-size: 24px"
                >
                    {{ label }}
                </mat-checkbox>
            </span>
            <span>
                <ul>
                    @for (val of basePositions[label]; track val; let i =
                    $index) {
                    <span style="padding-left: 24px">
                        <mat-checkbox
                            color="primary"
                            id="child-checkbox-position"
                            [checked]="isSelectedChild('positions', val.id)"
                            (change)="
                                updateChildFilter(
                                    $event,
                                    'positions',
                                    label,
                                    val.label
                                )
                            "
                        >
                            {{ val.label }}
                        </mat-checkbox>
                    </span>
                    }
                </ul>
            </span>
            }
        </mat-expansion-panel>

        <mat-expansion-panel id="parent-filter-distance">
            <mat-expansion-panel-header>
                <mat-panel-title> Maximale Distanz </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="column">
                <mat-slider
                    id="distance-slider"
                    [min]="0"
                    [max]="getInitialMaxDistance()"
                    discrete
                    step="5"
                    showTickMarks
                    style="width: 90%"
                >
                    <input
                        [value]="this.currentFilterState?.maxDistance ?? 50"
                        (dragEnd)="onDistanceChange($event)"
                        matSliderThumb
                    />
                </mat-slider>
                <div
                    class="row"
                    style="justify-content: space-between; padding-right: 12px"
                >
                    <p>0 km</p>
                    <p>{{ getInitialMaxDistance() }} km</p>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="row" style="justify-content: center; margin-top: 16px">
        <button
            mat-button
            color="primary"
            (click)="resetFilters()"
            color="primary"
            [disabled]="areFiltersChanged()"
        >
            Zurücksetzen
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="closeSheet()"
            color="primary"
        >
            Zeige {{ filteredCount() }} / {{ totalCount() }} Jobs
        </button>
    </div>
</div>
