import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { Applier, Match } from "../../../../model";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatchProperties } from "src/shared/util/utilFunctions";
import { MatchService } from "src/shared/services/match.service";
import { AnalyticsService } from "src/shared/services/analytics.service";
import { UserService } from "src/shared/services/user.service";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";

@Component({
    selector: "app-match-card-content",
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatDividerModule,
        MatCardModule,
        MatRippleModule,
    ],
    templateUrl: "./match-card-content.component.html",
    styleUrls: ["./match-card-content.component.scss"],
})
export class MatchCardContentComponent {
    @Input() match!: Match;
    @Input() applier!: Applier;
    @Input() clickable!: boolean;
    @Output() handleClick = new EventEmitter<string>();

    private observer: IntersectionObserver | null = null;

    matchProperties?: MatchProperties | null;

    constructor(
        private matchService: MatchService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private el: ElementRef
    ) {}

    ngOnInit() {
        this.matchProperties = this.match.matchProperties;
    }

    onClick(extId: string) {
        if (this.clickable && this.handleClick) {
            this.handleClick.emit(extId);
        }
    }

    ngAfterViewInit() {
        this.observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting === true) {
                    var listPosition = this.matchService.getMatchListPosition(
                        this.match.extId
                    );
                    if (listPosition != undefined) {
                        // listPosition is 0-based, but we want to start at 1
                        listPosition += 1;
                    }
                    this.analyticsService.trackEvent("match-card-view", {
                        match_extId: this.match.extId,
                        listPosition: listPosition,
                        applier_extId: this.userService.getExtId(),
                    });
                }
            },
            {
                threshold: 1,
            }
        );

        this.observer.observe(this.el.nativeElement as HTMLElement);
    }

    ngOnDestroy() {
        this.observer?.disconnect();
    }
}
