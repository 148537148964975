import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Match } from "src/app/model";
import { MatchService } from "src/shared/services/match.service";
import { MenuItem } from "primeng/api";

import { StepsModule } from "primeng/steps";
import { RegistrationDialogComponent } from "../../registration-dialog/registration-dialog.component";
import { MatchConfirmationDialogComponent } from "../match-confirmation-dialog/match-confirmation-dialog.component";
import { UserService } from "src/shared/services/user.service";

@Component({
    selector: "app-match-registration-wrapper",
    standalone: true,
    imports: [
        CommonModule,
        StepsModule,
        RegistrationDialogComponent,
        MatchConfirmationDialogComponent,
    ],
    templateUrl: "./match-registration-wrapper.component.html",
    styleUrl: "./match-registration-wrapper.component.scss",
})
export class MatchRegistrationWrapperComponent {
    @Input() match!: Match;

    @Output() closeDialog = new EventEmitter<void>();
    needsRegistration: boolean = false;
    steps: MenuItem[] = [{ label: "Registrierung" }, { label: "Abschicken" }];

    activeIndex: number = 0;

    constructor(
        private matchService: MatchService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.needsRegistration =
            this.matchService.matchApplierValue?.applier.user.socialAccount ==
            3;
    }

    close() {
        this.closeDialog.emit();
    }

    async handleUserRegistration(user: any) {
        this.activeIndex = 1;
    }
}
