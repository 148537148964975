import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { HomeComponent } from "../home.component";
import { ListFilterService } from "src/shared/services/list-filter.service";
import { FilterSortCriteria, getAllIds, getAllSelectedIds, GroupedFilterType } from "src/app/model/homeListFilter";
import { MatSliderDragEvent, MatSliderModule } from "@angular/material/slider";
import { MatChipsModule, MatChipListboxChange } from "@angular/material/chips";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatchListService } from "src/shared/services/match-list.service";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule, MatButtonToggleChange } from '@angular/material/button-toggle';
import { ConstantsService } from "src/shared/services/constants.service";
import { MatCheckboxModule, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDividerModule } from "@angular/material/divider";



@Component({
    selector: "app-home-list-filter",
    standalone: true,
    imports: [
        CommonModule,
        MatChipsModule,
        MatSliderModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatDividerModule
    ],
    templateUrl: "./home-list-filter.component.html",
    styleUrl: "./home-list-filter.component.scss",
})
export class HomeListFilterComponent {
    currentFilterState: FilterSortCriteria | null = null;
    baseCareTypes: GroupedFilterType = {};
    basePositions: GroupedFilterType = {};
    baseShiftTypes: Set<number> = new Set<number>();
    constructor(
        private bottomSheetRef: MatBottomSheetRef<HomeComponent>,
        private filterService: ListFilterService,
        private matchListService: MatchListService,
        private constantsService: ConstantsService
    ) { }

    ngAfterViewInit() {
        this.currentFilterState = structuredClone(this.filterService.filterSubjectValue);
        this.baseCareTypes = structuredClone(this.filterService.filterSubjectValue.careTypes);
        this.basePositions = structuredClone(this.filterService.initialFilterSortCriteria.positions);
        this.baseShiftTypes = structuredClone(this.filterService.initialFilterSortCriteria.shiftTypes);
    }

    objectKeys = Object.keys;

    getCareTypeFilters() {
        let all = getAllIds(this.filterService.filterSubjectValue.careTypes);
        let allStrings = all.map(num => num.toString());
        var list = [...allStrings];
        return list;
    }

    isSelectedCareType(option: number): boolean {
        let all = getAllSelectedIds(this.currentFilterState?.careTypes ?? {});
        return all.includes(option);
    }

    isSelectedSort(option: string): boolean {
        return this.currentFilterState?.sort === option;
    }

    onSelectionChangeSort(event: MatButtonToggleChange): void {
        const newValue = event.value;
        if (newValue === null || newValue === undefined) {
            // If the user tries to deselect the last chip, revert to the previous selection
            if (this.currentFilterState) {
                event.source.value = this.currentFilterState.sort;
            }
            return;
        }
        if (this.currentFilterState) {
            this.currentFilterState.sort = newValue;
            this.filterService.updateFilters(this.currentFilterState);
        }
    }

    onSelectionChangeCareType(event: MatChipListboxChange, label: string): void {
        const selectedValues = event.value;
        if (this.currentFilterState) {
            var newObject = this.currentFilterState;

            newObject.careTypes[label].forEach(item => {
                item.selected = selectedValues.includes(item.id);
            });

            this.currentFilterState = newObject;
            this.filterService.updateFilters(newObject);
        }
    }

    onDistanceChange(event: MatSliderDragEvent) {
        const newValue = event.value;
        if (this.currentFilterState) {
            this.currentFilterState.maxDistance = newValue;
            this.filterService.updateFilters(this.currentFilterState);
        }
    }

    getInitialMaxDistance = () =>
        this.filterService.initialFilterSortCriteria.maxDistance;

    resetFilters() {
        this.filterService.resetFilters();
        this.currentFilterState = this.filterService.filterSubjectValue;
    }

    areFiltersChanged = () => this.filterService.filtersChanged;

    closeSheet = () => this.bottomSheetRef.dismiss();

    totalCount = () => this.matchListService.totalCount;

    filteredCount = () => this.matchListService.filteredCount;

    getAllCareTypeIds(baseFilters: GroupedFilterType) {
        return getAllIds(baseFilters)
    }

    isSelectedChild(filterType: keyof FilterSortCriteria, option: number): boolean {
        if (!this.currentFilterState) {
            return false;
        }
        const filterGroup = this.currentFilterState[filterType] as GroupedFilterType;
        let all = getAllSelectedIds(filterGroup);
        return all.includes(option);
    }


    updateParent(event: MatCheckboxChange, filterType: keyof FilterSortCriteria, label: string) {
        const newValue = event.checked;
        if (this.currentFilterState) {
            var newObject = this.currentFilterState;
            var filterGroup = newObject[filterType] as GroupedFilterType;

            filterGroup[label].forEach(item => {
                item.selected = newValue;
            });

            this.currentFilterState = newObject;
            this.filterService.updateFilters(newObject);
        }
    }



    getParentState(filterType: keyof FilterSortCriteria, label: string) {
        let ret: "allSelected" | "noneSelected" | "indeterminate" = "indeterminate";
        if (this.currentFilterState) {
            const filterGroup = this.currentFilterState[filterType] as GroupedFilterType;
            if (filterGroup[label].every(item => item.selected)) {
                ret = "allSelected"
            } else if (filterGroup[label].every(item => !item.selected)) {
                ret = "noneSelected"
            } else {
                ret = "indeterminate"
            }
        }
        return ret;
    }

    updateChildFilter(event: MatCheckboxChange, filterType: keyof FilterSortCriteria, parentLabel: string, ownLabel: string) {
        const newValue = event.checked;
        if (this.currentFilterState) {
            var newObject = this.currentFilterState;

            var filterGroup = newObject[filterType] as GroupedFilterType;
            filterGroup[parentLabel].find(item => item.label === ownLabel)!.selected = newValue;

            this.currentFilterState = newObject;
            this.filterService.updateFilters(newObject);
        }
    }
}
